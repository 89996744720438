import React from "react";
import { useSelector } from "react-redux";

const PerformedBy = () => {
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );


  return (
    <>
      <div className="row col-lg-12 justify-content-center" data-testid = "performed_by">PERFORMED BY</div>
      <div className="form-row card-border">
        <div className="form-group col-md-4 px-4">
          <label htmlFor="pb_employee_email">Employee Email</label>
          <input
            type="text"
            className="form-control"
            id="pb_employee_email"
            placeholder="Employee Email"
            autoComplete="off"
            value={currentUser?.employee_email}
            disabled
          />
        </div>
        <div className="form-group col-md-4 px-4">
          
          <label htmlFor="pb_employee_name">Employee Name</label>
          <input
            type="text"
            className="form-control"
            id="pb_employee_name"
            placeholder="Emaployee Name"
            autoComplete="off"
            value={currentUser.employee_name}
            disabled
          />
        </div>

        <div className="form-group col-md-4 px-4">
          <label htmlFor="pb_employee_designation">Empolyee Designation</label>
          <input
            type="text"
            className="form-control"
            id="pb_employee_designation"
            placeholder="Empolyee Designation"
            autoComplete="off"
            value={currentUser.employee_designation}
            disabled
          />
        </div>
      </div>
    </>
  );
};

export default PerformedBy;
