import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SearchPanel from "../SearchPanel";
import { getEmployeesOnsearch } from "../../api/EmployeeService";
import { useFormContext } from "../../context/FormContext";
import { ConsoleError } from "../../utils/ErrorUtils";
import { PrintDisable } from "../../utils/PrintDisable";
import { SearchSelectedListTable } from "../SearchSelectedListTable";
import ConfigData from "./../../config";
import { useDebounce } from "../../utils/useDebounce";

const AddAuthorizer = (props) => {
  const { state, handleChange } = useFormContext();
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeOptionList, setEmployeeOptionList] = useState([]);
  const [selectedAuthorizerList, setSelectedAuthorizerList] = useState(
    state["authorizer_payload"] ? state["authorizer_payload"] : []
  );
  const [searchString, setSearchString] = useState("");
  const [showOptions, setShowOptions] = useState(true);
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  PrintDisable(currentUser?.print_access_flag);

  const debouncedSearchQuery = useDebounce(searchString, 500);

  const formErrors = props.errors;

  const getEmployeesBySearch = () => {
    const approvalAccessFlag = true;
    const authorizerAcessFlag = null;
    const auditAccessFlag = null;
    getEmployeesOnsearch(
      approvalAccessFlag,
      authorizerAcessFlag,
      auditAccessFlag,
      debouncedSearchQuery,
      currentUser?.jwtToken
    )
      .then((response) => {
        response = response?.data;
        setEmployeeList(response);
        setEmployeeOptionList(
          response
            .filter(
              (employee) => employee.employee_id !== currentUser?.employee_id
            )
            .filter(
              (row) =>
                !selectedAuthorizerList.some(
                  (authorizer) => authorizer.employee_id === row.employee_id
                )
            )
            .map(function (emp) {
              return (
                (emp.employee_name ? emp.employee_name : "") +
                (emp.employee_email ? " - " + emp.employee_email : "") +
                (emp.employee_org_id ? " - " + emp.employee_org_id : "")
              );
            })
        );
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };
  useEffect(() => {
    if (debouncedSearchQuery.length > 0) {
      getEmployeesBySearch();
    }
    //eslint-disable-next-line
  }, [debouncedSearchQuery]);

  const onClickDeleteAuthorizer = (item, index) => {
    const newSelectedAuthorizerList = [...selectedAuthorizerList];
    newSelectedAuthorizerList.splice(index, 1);
    setSelectedAuthorizerList(newSelectedAuthorizerList);
    handleChange("authorizer_list", null, item.employee_id, "DELETE");
    handleChange(
      "authorizer_payload",
      null,
      item.employee_id,
      "DELETE_AUTHORIZER_PAYLOAD"
    );
    handleChange("authorizer_name", null, item.employee_name, "DELETE");
  };

  return (
    <>
      <div className="row justify-content-center">ADD AUTHORIZER</div>
      <div className="card-border m-2">
        <div className="form-row px-3 pt-2">
          <div className="form-group col-md-12 col-xs-12  col-lg-12 px-4">
            <div className="form-group col-md-6 col-xs-12  col-lg-6 px-4">
              <SearchPanel
                label="Authorizer Assigned"
                placeholder="Search by Employee Name or Email"
                options={employeeOptionList}
                showOptions={showOptions}
                setOptionList={setEmployeeOptionList}
                setSearchString={setSearchString}
                setShowOptions={setShowOptions}
                onFocusSearch={() => {
                  setSearchString("");
                  setShowOptions(false);
                }}
                handleChange={(e) => {
                  setEmployeeOptionList([])
                  const value = e.target.value;
                  setSearchString(value);
                  if (value.length > 0) {
                    setShowOptions(true);
                  } else {
                    setShowOptions(false);
                  }
                }}
                searchString={searchString}
                onSelectValue={(selectedAuthorizer) => {
                  setShowOptions(false);
                  const authorizer = employeeList.filter(
                    (emp) =>
                      emp.employee_email === selectedAuthorizer.split(" - ")[1]
                  )[0];
                  setSelectedAuthorizerList([
                    authorizer,
                    ...selectedAuthorizerList,
                  ]);
                  handleChange(
                    "authorizer_list",
                    null,
                    authorizer.employee_id,
                    "ADD_ADDENDUM_A_B"
                  );
                  handleChange(
                    "authorizer_payload",
                    null,
                    authorizer,
                    "ADD_ADDENDUM_A_B"
                  );
                  handleChange(
                    "authorizer_name",
                    null,
                    authorizer.employee_name,
                    "ADD_ADDENDUM_A_B"
                  );
                  if (
                    formErrors["authoriser"] !== undefined &&
                    formErrors["authoriser"].length > 0
                  ) {
                    delete formErrors["authoriser"];
                  }
                }}
              />
              {
                <div className="error-message-validation">
                  {formErrors.authoriser}
                </div>
              }
            </div>
            {selectedAuthorizerList.length > 0 && (
              <SearchSelectedListTable
                label={"AUTHORIZERS"}
                headers={ConfigData.viewSelectedAuthorizerDetails}
                recordsList={selectedAuthorizerList}
                responseKeys={ConfigData.viewAuthorizerListResponeKeys}
                onClickDeleteItem={(authorizer, idx) =>
                  onClickDeleteAuthorizer(authorizer, idx)
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AddAuthorizer;
