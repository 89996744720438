import { MDBCard, MDBCardBody } from "mdbreact";
import { useCallback, useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  getStorageLocationRecords,
  getWareHouseStorageUnitPagination,
  getWareHouseStorageUnitRecords,
  getSuggestions,
  AddUnits,
  updateUnits,
  deleteWareHouseUnit,
  getLocationUnits,
  updateBranchVault,
  getWareHouseUnits,
  searchWareHouseUnits,
  deleteStorageLocation,
} from "../api/StorageLocationServices";
import DeleteStorage from "../assets/Delete.png";
import Delete from "../assets/deleteWarehouse.png";
import EditStorage from "../assets/EditStorage.png";
import Edit from "../assets/editWarehouse.png";
import { CustomAlert } from "../components/customAlert";
import CustomHeader from "../components/CustomHeader";
import CustomModal from "../components/CustomModal";
import Pagination from "../components/Pagination";
import "../style/StorageLocation.css";
import Search from "../components/Search";
import ConfigData from "../config";
import WarehouseModal from "./WarehouseModal";
import BranchModal from "./BranchModal";
import VaultModal from "./VaultModal";
import WarehouseUnitsModal from "./ViewWarehouseUnits";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import { ConsoleError } from "../utils/ErrorUtils";
import { ERROR_MESSAGE } from "../constants/MessagesConstants";
import useFetchNotifications from "../utils/NotificationUtils";
import { loaderDisplay } from "../utils/loader";

const ViewStorageLocation = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState(false);
  //eslint-disable-next-line
  const [show, setShow] = useState(false);
  //eslint-disable-next-line
  const [currentEventData, setCurrentEventData] = useState({
    EventType: "Alert",
    EventData: "",
  });
  //eslint-disable-next-line
  const [openDelete, setOpenDelete] = useState(false);
  //eslint-disable-next-line
  const [deleteRecord] = useState(null);
  //eslint-disable-next-line
  const [fetch, setFetch] = useState(false);
  //eslint-disable-next-line
  const [showVault, setShowVault] = useState(false);
  //eslint-disable-next-line
  const [openWarehouseModal, setOpenWarehouseModal] = useState(0);
  const [saveLoader, setsaveLoader] = useState(false);
  const [pages, setPages] = useState();
  const [warehousePages, setWarehousePages] = useState();
  const [rowCount, setRowCount] = useState(10);
  const initial = 0;
  const [wareHouseInitial, setWareHouseInitial] = useState(0);
  const [wareHouseEnd, setWareHouseEnd] = useState(3);
  const [displayStorageLocations, setDisplayStorageLocations] = useState([]);
  const [filteredData, setFilteredData] = useState();
  const [noRecordsFound, setNoRecordsFound] = useState();
  //eslint-disable-next-line
  const [saveLoaderWarehouse, setSaveLoaderWarehouse] = useState(false);
  const [recordData, setRecordData] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState("");
  const [warehouseId, setWarehouseId] = useState({});
  const [dropDownType, setDropDownType] = useState("");
  const [unitNumber, setUnitNumber] = useState("");
  const [newUnitNumber, setNewUnitNumber] = useState("");
  const [aisleNumber, setAisleNumber] = useState("");
  const [newAisleNumber, setNewAisleNumber] = useState("");
  const [bayNumber, setBayNumber] = useState("");
  const [newBayNumber, setNewBayNumber] = useState("");
  const [shelfNumber, setShelfNumber] = useState("");
  const [newShelfNumber, setNewShelfNumber] = useState("");
  const [toteNumber, setToteNumber] = useState("");
  const [newToteNumber, setNewToteNumber] = useState("");
  const [unitErrorMessage, setUnitErrorMessage] = useState("");
  const [newUnitErrorMessage, setNewUnitErrorMessage] = useState("");
  const [aisleErrorMessage, setAisleErrorMessage] = useState("");
  const [bayErrorMessage, setBayErrorMessage] = useState("");
  const [shelfErrorMessage, setShelfErrorMessage] = useState("");
  const [toteErrorMessage, setToteErrorMessage] = useState("");
  const [newAisleErrorMessage, setNewAisleErrorMessage] = useState("");
  const [newBayErrorMessage, setNewBayErrorMessage] = useState("");
  const [newShelfErrorMessage, setNewShelfErrorMessage] = useState("");
  const [newToteErrorMessage, setNewToteErrorMessage] = useState("");
  const [displayUnits, setDisplayUnits] = useState([]);
  const [displayUnitsDup, setDisplayUnitsDup] = useState([]);
  const [unitsDeleted, setUnitsDeleted] = useState([]);
  const [displaySpinner, setDisplaySpinner] = useState(true);
  const [locationId, setLocationId] = useState("");
  const [currentPage, setCurrentPage] = useState();
  const [searchValue, setSearchValue] = useState("");

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  useFetchNotifications(currentUser?.employee_id, currentUser?.jwtToken);

  //eslint-disable-next-line
  const setWarehouseRowsPage = useCallback((e) => {
    setRowCount(e);
    getWareHouseStorageUnitRecords(
      recordData.LocationId,
      "warehouse",
      currentUser?.employee_id,
      currentUser?.jwtToken
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response?.data.results.status === 200) {
          let count = response?.data.count;
          let c = Math.ceil(count / e);
          c = c === 0 ? 1 : c;
          setWarehousePages([...Array(c)].map((_, i) => i + 1));
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  });

  //setting pages number at bottom when we click on next button
  //eslint-disable-next-line
  const onNextClickWareHouse = useCallback(() => {
    setWareHouseInitial(wareHouseInitial + 3);
    setWareHouseEnd(wareHouseEnd + 3);
  });

  //setting pages number at bottom when we click on previous button
  //eslint-disable-next-line
  const onPreviousWareHouse = useCallback(() => {
    setWareHouseInitial(wareHouseInitial - 3);
    setWareHouseEnd(wareHouseEnd - 3);
  });
  //eslint-disable-next-line
  const onWareHousePageClick = useCallback((item) => {
    setDisplaySpinner(true);
    setSaveLoaderWarehouse(true);
    getWareHouseStorageUnitPagination(
      recordData.storage_location_id,
      "warehouse",
      item,
      currentUser?.employee_id,
      currentUser?.jwtToken
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          setSaveLoaderWarehouse(false);
          setDisplayUnits(response?.data.results.data);
        }
        setDisplaySpinner(false);
      })
      .catch((error) => {
        setSaveLoaderWarehouse(false);
        setDisplaySpinner(false);
        ConsoleError(error);
      });
  });

  const handleApiResponse = (response, onSuccess) => {
    if (response.status === 401) {
      cognitoSignOut()
        .then(() => navigate("/"))
        .catch(ConsoleError);
    } else if (response.status === 200) {
      onSuccess(response);
    }
  };
  
  const handleApiError = (error, loaderSetter) => {
    loaderSetter(false);
    ConsoleError(error);
  };
  
  const fetchStorageLocations = async (page, rowsPerPage, searchValue, onSuccess, loaderSetter) => {
    loaderSetter(true);
    getStorageLocationRecords(searchValue, page, rowsPerPage, currentUser?.jwtToken)
      .then((response) => {
        handleApiResponse(response, (res) => {
          loaderSetter(false);
          onSuccess(res);
        });
      })
      .catch((error) => handleApiError(error, loaderSetter));
  };
  
  const setRowsPage = (e) => {
    setRowCount(e);
    fetchStorageLocations(1, e, searchValue, (response) => {
      const pages = response.data?.pagination?.pages;
      setPages([...Array(pages)].map((_, i) => i + 1));
      const data = response?.data?.storage_locations;
      setDisplayStorageLocations(data);
      setCurrentPage(1);
    }, setsaveLoader);
  };
  
  const onPageClick = (item) => {
    if (currentPage === item) return;
    fetchStorageLocations(item, rowCount, searchValue, (response) => {
      const data = response?.data?.storage_locations;
      setDisplayStorageLocations(data);
      setCurrentPage(item);
    }, setsaveLoader);
  };
  
  const onNextClick = () => {
    fetchStorageLocations(currentPage + 1, rowCount, searchValue, (response) => {
      const data = response?.data?.storage_locations;
      setDisplayStorageLocations(data);
      setCurrentPage(currentPage + 1);
    }, setsaveLoader);
  };
  
  const onPreviousClick = () => {
    fetchStorageLocations(currentPage - 1, rowCount, searchValue, (response) => {
      const data = response?.data?.storage_locations;
      setDisplayStorageLocations(data);
      setCurrentPage(currentPage - 1);
    }, setsaveLoader);
  };
  
  const getLocations = () => {
    fetchStorageLocations(1, rowCount, searchValue, (response) => {
      const count = response?.data?.pagination?.total_records;
      if (response?.data.status === 204 || count < 1) {
        setNoRecordsFound("No storage location records available");
      }
      const pages = response.data?.pagination?.pages;
      setPages([...Array(pages)].map((_, i) => i + 1));
  
      const data = response?.data?.storage_locations;
      setDisplayStorageLocations(data);
      setCurrentPage(1);
    }, setsaveLoader);
  };
  
  const handleSearchData = (data) => {
    const {searchValue } = data
    if (searchValue.trim().length === 0) {
      setSearchValue("")
      fetchStorageLocations(1, rowCount, "", (response) => {
        const count = response?.data?.pagination?.total_records;
        if (response?.data.status === 204 || count < 1) {
          setNoRecordsFound("No storage location records available");
        }
    
        const pages = response.data?.pagination?.pages;
        setPages([...Array(pages)].map((_, i) => i + 1));
    
        const data = response?.data?.storage_locations;
        setDisplayStorageLocations(data);
        setCurrentPage(1);
      }, setsaveLoader);
      return
    }
    if (!data?.storage_locations.length) {
      setNoRecordsFound("No storage location records available");
      setDisplayStorageLocations([]);
      setPages([]);
      setCurrentPage(1);
      setSearchValue(searchValue);
    } else {
      setNoRecordsFound("");
      setSearchValue(searchValue);
      setDisplayStorageLocations(data?.storage_locations);
  
      // Assume the data is paginated; adjust pages accordingly.
      const pages = data?.pagination?.pages;
      setPages([...Array(pages)].map((_, i) => i + 1))
      setCurrentPage(1);
    }
  };
  
  

  const openpopup = async (rowdata) => {
    if (rowdata.storage_type === "Warehouse") {
      setLocationId(rowdata.warehouse_id);
      setDropDownType("Add Tote");
      setWarehouseId({
        LocationId: rowdata.warehouse_id,
        UnitId: "",
        AisleId: "",
        BayId: "",
        ShelfId: "",
        ToteId: "",
      });
    } else {
      getLocationUnits(
        rowdata.storage_location_id,
        rowdata.storage_location_type,
        currentUser?.employee_id,
        currentUser?.jwtToken
      ).then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (
          response?.data !== undefined &&
          response?.data.status === 200
        ) {
          if (response?.data.data.length > 0) {
            setDisplayUnits(response?.data.data);
            setDisplayUnitsDup(JSON.parse(JSON.stringify(response?.data.data)));
            setDropDownType(rowdata.storage_location_type);
          } else {
            setDisplayUnits([
              {
                unit_id: "",
                unit_name: "",
                unit_type: "",
              },
            ]);
            setDisplayUnitsDup([
              {
                unit_id: "",
                unit_name: "",
                unit_type: "",
              },
            ]);
          }
        } else {
          setDropDownType(rowdata.storage_location_type);
        }
      });
    }
    setRecordData(rowdata);
  };
  //eslint-disable-next-line
  const showUnits = useCallback(() => {
    setDisplaySpinner(true);
    setDropDownType("WarehouseUnits");
    getWareHouseUnits(
      recordData.warehouse_id,
      "Warehouse",
      currentUser?.employee_id,
      currentUser?.jwtToken
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (
          response.status === 200 &&
          response?.data.results &&
          response?.data.results.status === 200
        ) {
          let count = response?.data.count;
          let c = Math.ceil(count / rowCount);
          c = c === 0 ? 1 : c;
          setWarehousePages([...Array(c)].map((_, i) => i + 1));
          setDisplayUnits(response?.data.results.data);
        }
        setDisplaySpinner(false);
      })
      .catch((error) => {
        setDisplaySpinner(false);
        ConsoleError(error);
      });
  });

  const onClickDelete = (item, index) => {
    CustomAlert(
      "Warning",
      "Are you sure want to delete",
      "warning",
      "Yes",
      true,
      "No",
      document.getElementById("swal-div")
    ).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "Processing, please wait",
          html: "loading...",
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        let storageId =
          item?.storage_type === "Branch"
            ? item?.branch_id
            : item?.storage_type === "Vault"
            ? item?.vault_id
            : item?.warehouse_id;
        deleteStorageLocation(
          storageId,
          item?.storage_type,
          currentUser?.employee_id,
          currentUser?.jwtToken
        )
          .then((response) => {
            Swal.close();
            if (response.status === 401) {
              cognitoSignOut()
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  ConsoleError(error);
                });
            } else if (response.status === 204) {
              if (response?.status === 204) {
                getLocations();
                displayAlert(
                  "Storage Location deleted successfully",
                  "success",
                  "Deleted"
                );
              } else if (response?.status === 409) {
                displayAlert(response?.message, "warning", "Failed");
              } else if (response?.status === 400) {
                displayAlert(response?.error, "fail", "Failed");
              } else {
                displayAlert(
                  "Storage Location deletion failed",
                  "fail",
                  "Failed"
                );
              }
            } else {
              displayAlert(
                "Storage Location deletion failed",
                "fail",
                "Failed"
              );
            }
          })
          .catch((error) => {
            ConsoleError(error);
            Swal.close();
            displayAlert(ERROR_MESSAGE, "fail", "Failed");
          });
      }
    });
  };

  // const showFailAlert = (response) => {
  //   let title = "Failed";
  //   let text = response;
  //   let type = "error";
  //   let confirmButtonText = "OK";
  //   let showCancelButton = false;
  //   let target = document.getElementById("swal-div");
  //   CustomAlert(title, text, type, confirmButtonText, showCancelButton, target);
  // };
  //eslint-disable-next-line
  const dropdownSearch = useCallback((labelName, placeholder, type) => {
    return (
      <div className="col-lg-12 form-group pt-3">
        <div className="align-form">
          <label className="col-lg-3 col-sm-12 pl-5 col-form-label input-label">
            {labelName}
          </label>

          <div className=" col-lg-7 col-sm-12 ">
            <div className="form-control-plaintext">
              <input
                onBlur={() => {
                  setDisplayDropdown("");
                  setSuggestions([]);
                }}
                value={
                  type === "Aisle"
                    ? aisleNumber
                    : type === "New Aisle"
                    ? newAisleNumber
                    : type === "Bay"
                    ? bayNumber
                    : type === "New Bay"
                    ? newBayNumber
                    : type === "Shelf"
                    ? shelfNumber
                    : type === "New Shelf"
                    ? newShelfNumber
                    : type === "Tote"
                    ? toteNumber
                    : type === "Unit"
                    ? unitNumber
                    : type === "New Unit"
                    ? newUnitNumber
                    : type === "New Tote" && newToteNumber
                }
                type="text"
                maxLength={
                  type === "Aisle" || type === "New Aisle"
                    ? ConfigData.aisleNameLength
                    : type === "Bay" || type === "New Bay"
                    ? ConfigData.bayNameLength
                    : type === "Shelf" || type === "New Shelf"
                    ? ConfigData.shelfNameLength
                    : type === "Unit" || type === "New Unit"
                    ? ConfigData.unitNameLength
                    : type === "Tote" ||
                      (type === "New Tote" && ConfigData.toteNameLength)
                }
                placeholder={placeholder}
                style={{ width: "100%" }}
                onChange={(e) => {
                  onChangeValue(type, e.target.value);
                }}
                className="form-control-plaintext2"
                required
              />
            </div>
            {type === "Aisle" && aisleErrorMessage.length > 0 ? (
              <small className="text-danger">{aisleErrorMessage}</small>
            ) : type === "Bay" && bayErrorMessage.length > 0 ? (
              <small className="text-danger">{bayErrorMessage}</small>
            ) : type === "Shelf" && shelfErrorMessage.length > 0 ? (
              <small className="text-danger">{shelfErrorMessage}</small>
            ) : type === "Tote" && toteErrorMessage.length > 0 ? (
              <small className="text-danger">{toteErrorMessage}</small>
            ) : type === "New Aisle" && newAisleErrorMessage.length > 0 ? (
              <small className="text-danger">{newAisleErrorMessage}</small>
            ) : type === "New Bay" && newBayErrorMessage.length > 0 ? (
              <small className="text-danger">{newBayErrorMessage}</small>
            ) : type === "New Shelf" && newShelfErrorMessage.length > 0 ? (
              <small className="text-danger">{newShelfErrorMessage}</small>
            ) : type === "Unit" && unitErrorMessage.length > 0 ? (
              <small className="text-danger">{unitErrorMessage}</small>
            ) : type === "New Unit" && newUnitErrorMessage.length > 0 ? (
              <small className="text-danger">{newUnitErrorMessage}</small>
            ) : (
              type === "New Tote" &&
              newToteErrorMessage.length > 0 && (
                <small className="text-danger">{newToteErrorMessage}</small>
              )
            )}

            {displayDropdown === type && (
              <div className="AddSugestionPostion">
                <div
                  className="input3 scroll"
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    border: "1px solid #c4c4c4",
                    overflow: "scroll",
                    maxHeight: "230px",
                    width: "100%",
                  }}
                >
                  {suggestions.length > 0 ? (
                    suggestions.map((suggestion_item, key) => {
                      return (
                        <button
                          type="button"
                          className="list-group-item list-group-item-action"
                          onMouseDown={() => {
                            onClickDropdown(type, suggestion_item);
                          }}
                        >
                          {type === "Unit"
                            ? suggestion_item.unit_name
                            : type === "Aisle"
                            ? suggestion_item.aisle_number
                            : type === "Bay"
                            ? suggestion_item.bay_number
                            : type === "Shelf"
                            ? suggestion_item.shelf_number
                            : type === "Tote"
                            ? suggestion_item.tote_number
                            : null}
                        </button>
                      );
                    })
                  ) : (
                    <p> {type} number not exist </p>
                  )}
                </div>
              </div>
            )}
          </div>
          {displayAddButton(type) && (
            <div className="center-imageplus btn-sm p-3">
              <label
                onClick={() => {
                  clearData(type, "Add");
                }}
                className="plusIcon1"
              >
                {" "}
                +
              </label>
            </div>
          )}
          {displayDeleteButton(type) && (
            <div
              className="center-imageplus btn-sm p-3"
              onClick={() => {
                clearData(type, "Delete");
              }}
            >
              <img src={Delete} alt="Remove" />
            </div>
          )}
          {displayEditButton(type) && (
            <div
              className="center-imageplus btn-sm p-3"
              onClick={() => {
                clearData(type, "Edit");
              }}
            >
              <img src={Edit} alt="Edit" />
            </div>
          )}
        </div>
      </div>
    );
  });

  const displaySuggestions = (parentId, unitType, searchText) => {
    getSuggestions(parentId, unitType, searchText, currentUser?.jwtToken).then(
      (response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          if (response?.data !== undefined && response?.status === 200) {
            if (unitType === "Unit") {
              setSuggestions(response?.data?.units);
            } else if (unitType === "Aisle") {
              setSuggestions(response?.data?.aisles);
            } else if (unitType === "Bay") {
              setSuggestions(response?.data?.bays);
            } else if (unitType === "Shelf") {
              // setSuggestions(response?.data?.shelfs);
              setSuggestions(response?.data?.shelves);
            } else if (unitType === "Tote") {
              setSuggestions(response?.data?.totes);
            }
            setDisplayDropdown(unitType);
          } else {
            setSuggestions([]);
            setDisplayDropdown(unitType);
          }
        }
      }
    );
  };

  const onClickDropdown = (type, item) => {
    if (type === "Unit") {
      let update_warehouse = warehouseId;
      update_warehouse["UnitId"] = item.unit_id;
      setWarehouseId(update_warehouse);
      setUnitNumber(item.unit_name);
    }
    if (type === "Aisle") {
      let update_warehouse = warehouseId;
      update_warehouse["AisleId"] = item.aisle_id;
      setWarehouseId(update_warehouse);
      setAisleNumber(item.aisle_number);
    } else if (type === "Bay") {
      let update_warehouse = warehouseId;
      update_warehouse["BayId"] = item.bay_id;
      setWarehouseId(update_warehouse);
      setBayNumber(item.bay_number);
    } else if (type === "Shelf") {
      let update_warehouse = warehouseId;
      update_warehouse["ShelfId"] = item.shelf_id;
      setWarehouseId(update_warehouse);
      setShelfNumber(item.shelf_number);
    } else if (
      (type === "Tote" && dropDownType === "Edit Tote") ||
      dropDownType === "Delete Tote"
    ) {
      let update_warehouse = warehouseId;
      update_warehouse["ToteId"] = item.tote_id;
      setWarehouseId(update_warehouse);
      setToteNumber(item.tote_number);
    }
  };
  //eslint-disable-next-line
  const saveLocation = useCallback((type) => {
    let payload = {};
    if (type === "Unit") {
      payload["item_type"] = type;
      payload["parent_id"] = warehouseId.LocationId;
      payload["item_value"] = unitNumber;
      payload["performed_by"] = currentUser?.employee_id;
    } else if (type === "Aisle") {
      payload["item_type"] = type;
      payload["parent_id"] = warehouseId.UnitId;
      payload["item_value"] = aisleNumber;
      payload["performed_by"] = currentUser?.employee_id;
    } else if (type === "Bay") {
      payload["item_type"] = type;
      payload["parent_id"] = warehouseId.AisleId;
      payload["item_value"] = bayNumber;
      payload["performed_by"] = currentUser?.employee_id;
    } else if (type === "Shelf") {
      payload["item_type"] = type;
      payload["parent_id"] = warehouseId.BayId;
      payload["item_value"] = shelfNumber;
      payload["performed_by"] = currentUser?.employee_id;
    } else if (type === "Tote") {
      payload["item_type"] = type;
      payload["parent_id"] = warehouseId.ShelfId;
      payload["item_value"] = toteNumber;
      payload["performed_by"] = currentUser?.employee_id;
    }
    Swal.fire({
      title: "Processing, please wait",
      html: "loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    setWarehouseId({});
    setDropDownType("");
    AddUnits(type, payload, currentUser?.employee_id, currentUser?.jwtToken)
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response?.data !== undefined && response?.status === 201) {
          Swal.close();
          clearData(dropDownType.split(" ")[1]);
          displayAlert(type + " saved successfully", "success", "Saved");
        } else if (response?.data !== undefined && response?.status === 409) {
          displayAlert(
            response?.data.message + " in selected " + type,
            "warning",
            "Failed"
          );
        } else {
          displayAlert(ERROR_MESSAGE, "warning", "Failed");
        }
      })
      .catch((error) => {
        Swal.close();
        ConsoleError(error);
        displayAlert("failed to save " + type, "fail", "Failed");
      });
  });

  //eslint-disable-next-line
  const editLocation = useCallback((type) => {
    let payload = {};
    if (type === "Aisle") {
      payload["item_type"] = type;
      // payload["storage_location_id"] = warehouseId.LocationId;
      payload["item_id"] = warehouseId.AisleId;
      payload["item_value"] = newAisleNumber;
      payload["performed_by"] = currentUser?.employee_id;
    } else if (type === "Bay") {
      payload["item_type"] = type;
      // payload["aisle_id"] = warehouseId.AisleId;
      payload["item_id"] = warehouseId.BayId;
      payload["item_value"] = newBayNumber;
      payload["performed_by"] = currentUser?.employee_id;
    } else if (type === "Shelf") {
      payload["item_type"] = type;
      // payload["bay_id"] = warehouseId.BayId;
      payload["item_id"] = warehouseId.ShelfId;
      payload["item_value"] = newShelfNumber;
      payload["performed_by"] = currentUser?.employee_id;
    } else if (type === "Tote") {
      payload["item_type"] = type;
      // payload["shelf_id"] = warehouseId.ShelfId;
      payload["item_id"] = warehouseId.ToteId;
      payload["item_value"] = newToteNumber;
      payload["performed_by"] = currentUser?.employee_id;
    } else if (type === "Unit") {
      payload["item_type"] = type;
      payload["item_id"] = warehouseId.UnitId;
      payload["item_value"] = newUnitNumber;
      payload["performed_by"] = currentUser?.employee_id;
    }
    Swal.fire({
      title: "Processing, please wait",
      html: "loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    setWarehouseId({});
    setDropDownType("");
    updateUnits(type, payload, currentUser?.employee_id, currentUser?.jwtToken)
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response?.data !== undefined && response?.status === 200) {
          Swal.close();
          clearData(dropDownType.split(" ")[1]);
          displayAlert(type + " updated successfully", "success", "Saved");
        } else if (
          response?.data !== undefined &&
          response?.data.status === 409
        ) {
          displayAlert(response?.data.message, "warning", "Failed");
        } else {
          displayAlert(ERROR_MESSAGE, "warning", "Failed");
        }
      })
      .catch((error) => {
        Swal.close();
        ConsoleError(error);
        displayAlert("failed to save " + type, "fail", "Failed");
      });
  });

  //eslint-disable-next-line
  const deleteLocation = useCallback((type) => {
    Swal.fire({
      title: "Processing, please wait",
      html: "loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    let id =
      dropDownType === "Delete Unit"
        ? warehouseId.UnitId
        : dropDownType === "Delete Tote"
        ? warehouseId.ToteId
        : dropDownType === "Delete Aisle"
        ? warehouseId.AisleId
        : dropDownType === "Delete Bay"
        ? warehouseId.BayId
        : dropDownType === "Delete Shelf" && warehouseId.ShelfId;

    setWarehouseId({});
    setDropDownType("");
    deleteWareHouseUnit(
      type,
      id,
      currentUser?.employee_id,
      currentUser?.jwtToken
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response?.data !== undefined && response?.status === 200) {
          Swal.close();
          clearData(dropDownType.split(" ")[1]);
          displayAlert(type + " deleted successfully", "success", "Deleted");
        } else if (
          response?.data !== undefined &&
          response?.data.status === 409
        ) {
          displayAlert(response?.data.message, "warning", "Failed");
        } else {
          displayAlert(ERROR_MESSAGE, "warning", "Failed");
        }
      })
      .catch((error) => {
        Swal.close();
        ConsoleError(error);
        displayAlert("fail", "failed to delete " + type, "Failed");
      });
  });

  const displayAlert = (text, type, title) => {
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(title, text, type, confirmButtonText, showCancelButton, target);
  };
  //eslint-disable-next-line
  const clearData = useCallback((type, actionType) => {
    if (type === "Branch") {
      setWarehouseId({});
      setDropDownType("");
    } else {
      setUnitNumber("");
      setUnitErrorMessage("");
      setAisleNumber("");
      setAisleErrorMessage("");
      if (type === "Bay" || type === "Shelf" || type === "Tote") {
        setBayNumber("");
        setBayErrorMessage("");
        if (type === "Shelf" || type === "Tote") {
          setShelfNumber("");
          setShelfErrorMessage("");
          if (type === "Tote") {
            setToteNumber("");
            setToteErrorMessage("");
          }
        }
      }

      if (dropDownType === "Edit Aisle") {
        setNewAisleNumber("");
        setNewAisleErrorMessage("");
      } else if (dropDownType === "Edit Bay") {
        setNewBayNumber("");
        setNewBayErrorMessage("");
      } else if (dropDownType === "Edit Shelf") {
        setNewShelfNumber("");
        setNewShelfErrorMessage("");
      } else if (dropDownType === "Edit Tote") {
        setNewToteNumber("");
        setNewToteErrorMessage("");
      } else if (dropDownType === "Edit Unit") {
        setNewUnitNumber("");
      }

      if (actionType === "CANCEL") {
        setWarehouseId({});
        setDropDownType("");
      } else if (
        actionType === "Add" ||
        actionType === "Delete" ||
        actionType === "Edit"
      ) {
        setWarehouseId({
          LocationId: locationId,
          AisleId: "",
          BayId: "",
          ShelfId: "",
          ToteId: "",
        });
        setDropDownType(actionType + " " + type);
      }
    }
  });

  const displayAddButton = (type) => {
    if (type === "Unit") {
      if (dropDownType !== "Add Unit") {
        return true;
      } else {
        return false;
      }
    } else if (type === "New Unit") {
      return false;
    }
    if (type === "Tote") {
      if (dropDownType !== "Add Tote") {
        return true;
      } else {
        return false;
      }
    } else if (type === "New Tote") {
      return false;
    } else if (type === "Aisle") {
      if (dropDownType !== "Add Aisle") {
        return true;
      } else {
        return false;
      }
    } else if (type === "New Aisle") {
      return false;
    } else if (type === "Bay") {
      if (dropDownType !== "Add Bay") {
        return true;
      } else {
        return false;
      }
    } else if (type === "New Bay") {
      return false;
    } else if (type === "Shelf") {
      if (dropDownType !== "Add Shelf") {
        return true;
      } else {
        return false;
      }
    } else if (type === "New Shelf") {
      return false;
    } else {
      return true;
    }
  };

  const displayEditButton = (type) => {
    if (type === "Unit") {
      if (dropDownType !== "Edit Unit") {
        return true;
      } else {
        return false;
      }
    } else if (type === "New Unit") {
      return false;
    }
    if (type === "Tote") {
      if (dropDownType !== "Edit Tote") {
        return true;
      } else {
        return false;
      }
    } else if (type === "New Tote") {
      return false;
    } else if (type === "Aisle") {
      if (dropDownType !== "Edit Aisle") {
        return true;
      } else {
        return false;
      }
    } else if (type === "New Aisle") {
      return false;
    } else if (type === "Bay") {
      if (dropDownType !== "Edit Bay") {
        return true;
      } else {
        return false;
      }
    } else if (type === "New Bay") {
      return false;
    } else if (type === "Shelf") {
      if (dropDownType !== "Edit Shelf") {
        return true;
      } else {
        return false;
      }
    } else if (type === "New Shelf") {
      return false;
    } else {
      return true;
    }
  };

  const displayDeleteButton = (type) => {
    if (type === "Unit") {
      if (dropDownType !== "Delete Unit") {
        return true;
      } else {
        return false;
      }
    } else if (type === "New Unit") {
      return false;
    }
    if (type === "Tote") {
      if (dropDownType !== "Delete Tote") {
        return true;
      } else {
        return false;
      }
    } else if (type === "New Tote") {
      return false;
    } else if (type === "Aisle") {
      if (dropDownType !== "Delete Aisle") {
        return true;
      } else {
        return false;
      }
    } else if (type === "New Aisle") {
      return false;
    } else if (type === "Bay") {
      if (dropDownType !== "Delete Bay") {
        return true;
      } else {
        return false;
      }
    } else if (type === "New Bay") {
      return false;
    } else if (type === "Shelf") {
      if (dropDownType !== "Delete Shelf") {
        return true;
      } else {
        return false;
      }
    } else if (type === "New Shelf") {
      return false;
    } else {
      return true;
    }
  };

  const onChangeValue = (type, value) => {
    if (type === "Unit") {
      setUnitNumber(value);
      setAisleNumber("");
      setBayNumber("");
      setShelfNumber("");
      setToteNumber("");
      setWarehouseId({
        LocationId: warehouseId.LocationId,
        UnitId: value,
        // UnitId:warehouseId.UnitId.length > 0 ? warehouseId.UnitId : warehouseId.UnitId,
        AisleId: "",
        // AisleId: warehouseId.AisleId.length > 0 ? "" : warehouseId.AisleId,
        // BayId: warehouseId.BayId.length > 0 ? "" : warehouseId.BayId,
        BayId: "",
        // ShelfId: warehouseId.ShelfId.length > 0 ? "" : warehouseId.ShelfId,
        ShelfId: "",
        ToteId: "",
        // ToteId: warehouseId.ToteId.length > 0 ? "" : warehouseId.ToteId,
      });
      if (unitErrorMessage.length > 0) {
        setUnitErrorMessage("");
      }
    } else if (type === "New Unit") {
      setNewUnitNumber(value);
      if (newUnitErrorMessage.length > 0) {
        setNewUnitErrorMessage("");
      }
    }
    if (type === "Aisle") {
      setAisleNumber(value);
      setBayNumber("");
      setShelfNumber("");
      setToteNumber("");
      setWarehouseId({
        LocationId: warehouseId.LocationId,
        UnitId: warehouseId.UnitId,
        AisleId: value,
        // AisleId: warehouseId.AisleId.length > 0 ? "" : aisleNumber,
        // BayId: warehouseId.BayId.length > 0 ? "" : warehouseId.BayId,
        BayId: "",
        // ShelfId: warehouseId.ShelfId.length > 0 ? "" : warehouseId.ShelfId,
        ShelfId: "",
        // ToteId: warehouseId.ToteId.length > 0 ? "" : warehouseId.ToteId,
        ToteId: "",
      });
      warehouseId.AisleId = value;
      if (aisleErrorMessage.length > 0) {
        setAisleErrorMessage("");
      }
    } else if (type === "New Aisle") {
      setNewAisleNumber(value);
      if (newAisleErrorMessage.length > 0) {
        setNewAisleErrorMessage("");
      }
    } else if (type === "Bay") {
      setBayNumber(value);
      setShelfNumber("");
      setToteNumber("");
      setWarehouseId({
        LocationId: warehouseId.LocationId,
        UnitId: warehouseId.UnitId,
        AisleId: warehouseId.AisleId,
        BayId: value,
        // BayId: warehouseId.BayId.length > 0 ? "" : warehouseId.BayId,
        // ShelfId: warehouseId.ShelfId.length > 0 ? "" : warehouseId.ShelfId,
        ShelfId: "",
        // ToteId: warehouseId.ToteId.length > 0 ? "" : warehouseId.ToteId,
        ToteId: "",
      });
      if (bayErrorMessage.length > 0) {
        setBayErrorMessage("");
      }
    } else if (type === "New Bay") {
      setNewBayNumber(value);
      if (newBayErrorMessage.length > 0) {
        setNewBayErrorMessage("");
      }
    } else if (type === "Shelf") {
      setShelfNumber(value);
      setToteNumber("");
      setWarehouseId({
        LocationId: warehouseId.LocationId,
        UnitId: warehouseId.UnitId,
        AisleId: warehouseId.AisleId,
        BayId: warehouseId.BayId,
        ShelfId: value,
        // ShelfId: warehouseId.ShelfId.length > 0 ? "" : warehouseId.ShelfId,
        // ToteId: warehouseId.ToteId.length > 0 ? "" : warehouseId.ToteId,
        ToteId: "",
      });
      if (shelfErrorMessage.length > 0) {
        setShelfErrorMessage("");
      }
    } else if (type === "New Shelf") {
      if (newShelfErrorMessage.length > 0) {
        setNewShelfErrorMessage("");
      }
      setNewShelfNumber(value);
    } else if (type === "Tote") {
      setWarehouseId({
        LocationId: warehouseId.LocationId,
        UnitId: warehouseId.UnitId,
        AisleId: warehouseId.AisleId,
        BayId: warehouseId.BayId,
        ShelfId: warehouseId.ShelfId,
        ToteId: value,
        // ToteId: warehouseId.ToteId.length > 0 ? "" : warehouseId.ToteId,
      });
      if (toteErrorMessage.length > 0) {
        setToteErrorMessage("");
      }
      setToteNumber(value);
    } else {
      if (newToteErrorMessage.length > 0) {
        setNewToteErrorMessage("");
      }
      setNewToteNumber(value);
    }

    if (displayAddButton(type)) {
      displaySuggestions(
        type === "Unit"
          ? warehouseId.LocationId
          : type === "Aisle"
          ? warehouseId.UnitId
          : type === "Bay"
          ? warehouseId.AisleId
          : type === "Shelf"
          ? warehouseId.BayId
          : type === "Tote"
          ? warehouseId.ShelfId
          : undefined,
        type,
        value
      );
    }
  };
  //eslint-disable-next-line
  const onClickSubmit = useCallback((type, buttonType) => {
    let isFormValid = true;
    if (unitNumber === "" || (unitNumber === undefined && dropDownType)) {
      setUnitErrorMessage("Unit Id is required");
      isFormValid = false;
    } else if (
      unitNumber !== "" &&
      unitNumber !== undefined &&
      (warehouseId.UnitId === undefined || warehouseId.UnitId === "")
    ) {
      setUnitErrorMessage("Please select Unit Id from the dropdown");
      isFormValid = false;
    }

    if (
      dropDownType === "Add Aisle" ||
      dropDownType === "Edit Aisle" ||
      dropDownType === "Delete Aisle" ||
      dropDownType === "Add Bay" ||
      dropDownType === "Edit Bay" ||
      dropDownType === "Delete Bay" ||
      dropDownType === "Add Shelf" ||
      dropDownType === "Edit Shelf" ||
      dropDownType === "Delete Shelf" ||
      dropDownType === "Add Tote" ||
      dropDownType === "Edit Tote" ||
      dropDownType === "Delete Tote"
    ) {
      if (aisleNumber === "" || aisleNumber === undefined) {
        setAisleErrorMessage("Aisle Id is required");
        isFormValid = false;
      } else if (
        aisleNumber !== "" &&
        aisleNumber !== undefined &&
        dropDownType !== "Add Aisle" &&
        (warehouseId.AisleId === undefined || warehouseId.AisleId === "")
      ) {
        setAisleErrorMessage("Please select Aisle Id from the dropdown");
        isFormValid = false;
      }

      if (
        dropDownType === "Add Bay" ||
        dropDownType === "Edit Bay" ||
        dropDownType === "Delete Bay" ||
        dropDownType === "Add Shelf" ||
        dropDownType === "Edit Shelf" ||
        dropDownType === "Delete Shelf" ||
        dropDownType === "Add Tote" ||
        dropDownType === "Edit Tote" ||
        dropDownType === "Delete Tote"
      ) {
        if (bayNumber === "" || bayNumber === undefined) {
          setBayErrorMessage("Bay Id is required");
          isFormValid = false;
        } else if (
          bayNumber !== "" &&
          bayNumber !== undefined &&
          dropDownType !== "Add Bay" &&
          (warehouseId.BayId === undefined || warehouseId.BayId === "")
        ) {
          setBayErrorMessage("Please select Bay Id from the dropdown");
          isFormValid = false;
        }

        if (
          dropDownType === "Add Shelf" ||
          dropDownType === "Edit Shelf" ||
          dropDownType === "Delete Shelf" ||
          dropDownType === "Add Tote" ||
          dropDownType === "Edit Tote" ||
          dropDownType === "Delete Tote"
        ) {
          if (shelfNumber === "" || shelfNumber === undefined) {
            setShelfErrorMessage("Shelf Id is required");
            isFormValid = false;
          } else if (
            shelfNumber !== "" &&
            shelfNumber !== undefined &&
            dropDownType !== "Add Shelf" &&
            (warehouseId.ShelfId === undefined || warehouseId.ShelfId === "")
          ) {
            setShelfErrorMessage("Please select Shelf Id from the dropdown");
            isFormValid = false;
          }
        }

        if (
          dropDownType === "Add Tote" ||
          dropDownType === "Edit Tote" ||
          dropDownType === "Delete Tote"
        ) {
          if (toteNumber === "" || toteNumber === undefined) {
            setToteErrorMessage("Tote Id is required");
            isFormValid = false;
          } else if (
            toteNumber !== "" &&
            toteNumber !== undefined &&
            dropDownType !== "Add Tote" &&
            (warehouseId.ToteId === undefined || warehouseId.ToteId === "")
          ) {
            setToteErrorMessage("Please select Tote Id from the dropdown");
            isFormValid = false;
          }
        }
      }
    }

    if (
      (dropDownType === "Edit Aisle" && newAisleNumber === "") ||
      newAisleNumber === undefined
    ) {
      setNewAisleErrorMessage("Aisle Id is required");
      isFormValid = false;
    } else if (
      (dropDownType === "Edit Bay" && newBayNumber === "") ||
      newBayNumber === undefined
    ) {
      setNewToteErrorMessage("Bay Id is required");
      isFormValid = false;
    } else if (
      (dropDownType === "Edit Shelf" && newShelfNumber === "") ||
      newShelfNumber === undefined
    ) {
      setNewToteErrorMessage("Shelf Id is required");
      isFormValid = false;
    } else if (
      (dropDownType === "Edit Tote" && newToteNumber === "") ||
      newToteNumber === undefined
    ) {
      setNewToteErrorMessage("Tote Id is required");
      isFormValid = false;
    } else if (
      (dropDownType === "Edit Unit" && newUnitNumber === "") ||
      newUnitNumber === undefined
    ) {
      setNewUnitErrorMessage("Unit Id is required");
      isFormValid = false;
    }

    if (isFormValid) {
      if (buttonType === "Add") {
        saveLocation(type);
      } else if (buttonType === "Edit") {
        editLocation(type);
      } else if (buttonType === "Delete") {
        deleteLocation(type);
      }
    }
  });

  //eslint-disable-next-line
  const onClickAdd = useCallback(() => {
    let addItem = [
      {
        unit_id: "",
        unit_name: "",
        unit_type: "",
      },
    ];
    setDisplayUnits([...displayUnits, ...addItem]);
  });
  //eslint-disable-next-line
  const onChangeUnits = useCallback((type, value, index) => {
    let unitsDup = [...displayUnits];
    let obj = unitsDup[index];
    obj[type] = value;
    if (obj["error_message"] !== undefined && obj["error_message"].length > 0) {
      obj["error_message"] = "";
    }
    unitsDup[index] = obj;
    setDisplayUnits([...unitsDup]);
  });
  //eslint-disable-next-line
  const removeUnits = useCallback((item) => {
    let remove_item = displayUnits.filter(
      (unit_item) => unit_item.unit_id !== item.unit_id,
      setUnitsDeleted([...unitsDeleted, item])
    );
    setDisplayUnits(remove_item);
  });
  //eslint-disable-next-line
  const onClickUpdate = useCallback((type) => {
    let errorMessage = false;
    let payload = {};
    let new_data = [];
    let update_data = [];

    //checking whether unit_name is empty or not
    //eslint-disable-next-line
    displayUnits.map((unit_item) => {
      if (unit_item.unit_name === "" || unit_item.unit_name === undefined) {
        unit_item["error_message"] = "Unit Name is required";
        errorMessage = true;
      } else {
        unit_item["error_message"] = "";
      }
    });

    //checking duplicate unit_name
    // let valueArr = displayUnits.map(function (item) {
    //   return item.unit_name;
    // });
    // let isDuplicate = valueArr.some(function (item, idx) {
    //   if (valueArr.indexOf(item) !== idx && item !== "") {
    //     errorMessage = true;
    //     displayUnits[idx]["error_message"] = "Duplicate Unit Name exists";
    //   }
    // });

    //if there are no error messages data stores in DB
    if (!errorMessage) {
      //eslint-disable-next-line
      displayUnits.map((unit_item) => {
        if (unit_item.storage_location_id === undefined) {
          new_data.push(unit_item);
        }
      });
      //eslint-disable-next-line
      displayUnitsDup.map((unitdup_item) => {
        if (unitdup_item.storage_location_id !== undefined) {
          let units = displayUnits.filter(
            (unit_item) =>
              unitdup_item.unit_id === unit_item.unit_id &&
              (unitdup_item.unit_name !== unit_item.unit_name ||
                unitdup_item.unit_type !== unit_item.unit_type)
          );
          if (units.length > 0) {
            update_data = update_data.concat(units);
          }
        }
      });

      payload["storage_location_id"] = recordData.storage_location_id;
      payload["insert_data"] = new_data;
      payload["update_data"] = update_data;
      payload["delete_data"] = unitsDeleted;

      Swal.fire({
        title: "Processing, please wait",
        html: "loading...",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      updateBranchVault(
        "Branch",
        payload,
        currentUser?.employee_id,
        currentUser?.jwtToken
      )
        .then((response) => {
          if (response.status === 401) {
            cognitoSignOut()
              .then(() => {
                navigate("/");
              })
              .catch((error) => {
                ConsoleError(error);
              });
          } else if (
            (response.status === 200 &&
              response?.data["delete data"].status === 200) ||
            response?.data["insert data"].status === 201 ||
            response?.data["update data"].status === 201
          ) {
            Swal.close();
            clearData(type, "CANCEL");
            displayAlert(type + " updated successfully", "success", "Saved");
          } else {
            Swal.close();
            displayAlert(response?.data.message, "warning", "Failed");
          }
        })
        .catch((error) => {
          Swal.close();
          ConsoleError(error);
          displayAlert("fail", "failed to update " + type, "Failed");
        });
    } else {
      setDisplayUnits([...displayUnits]);
    }
  });
  //eslint-disable-next-line
  const displayModals = useCallback((type, data) => {
    if (
      data.bay_id !== null &&
      data.shelf_id !== null &&
      data.tote_id !== null
    ) {
      setBayNumber(data.bay_number);
      if (data.shelf_id !== null && data.tote_id !== null) {
        setShelfNumber(data.shelf_number);
        if (data.tote_id !== null) {
          if (type !== "Add") {
            setToteNumber(data.tote_number);
          }
          setDropDownType(type + " Tote");
        } else {
          setDropDownType(type + " Tote");
        }
      } else {
        setDropDownType(type + " Shelf");
      }
    } else {
      setDropDownType(type + " Bay");
    }
    setAisleNumber(data.aisle_number);
    setWarehouseId({
      LocationId: warehouseId.LocationId,
      AisleId: data.aisle_id !== null ? data.aisle_id : "",
      BayId: data.bay_id !== null ? data.bay_id : "",
      ShelfId: data.shelf_id !== null ? data.shelf_id : "",
      ToteId: data.tote_id !== null ? data.tote_id : "",
    });
  });
  //eslint-disable-next-line
  const warehouseUnitsSuggestions = useCallback((searchText, itemType) => {
    setDisplaySpinner(true);
    if (searchText.length > 0) {
      searchWareHouseUnits(
        searchText,
        itemType,
        recordData.storage_location_id,
        1,
        10,
        currentUser?.jwtToken
      )
        .then((response) => {
          if (response.status === 401) {
            cognitoSignOut()
              .then(() => {
                navigate("/");
              })
              .catch((error) => {
                ConsoleError(error);
              });
          } else if (searchText.length > 0) {
            if (
              response.status === 200 &&
              response?.data &&
              response?.data.results !== undefined &&
              response?.data.results.status === 200
            ) {
              setDisplayUnits(response?.data.results.data);
            } else if (
              response.status === 200 &&
              response?.data !== undefined &&
              response?.data.status === 204
            ) {
              setDisplayUnits([]);
            }
            setDisplaySpinner(false);
          }
        })
        .catch((error) => {
          ConsoleError(error);
          setDisplaySpinner(false);
        });
    } else {
      setDisplaySpinner(false);
      showUnits();
    }
  });

  useEffect(() => {
    getLocations();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDisplayStorageLocations(filteredData?.filterData);
    if (filteredData?.filterData?.length < 1) {
      setNoRecordsFound("No storage locations records available");
    }
    //eslint-disable-next-line
  }, [filteredData]);

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 overflow-hidden body">
        <div className="row flex p-4">
          <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden pt-5">
            <div className="row pt-2 mb-1 pb-5">
              <div className="col-lg-6 col-md-10">
                <Search
                  filterData={(filteredData) => handleSearchData(filteredData)}
                  screen="ViewStorageLocation"
                  pageSize={rowCount}
                  getLocations={getLocations}
                />
              </div>
              <div className="col-lg-1 col-md-6 col-sm-6 pl-0 pr-0 "></div>
              <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 "></div>
              <div className="col-lg-3 col-md-6 col-sm-6 pl-0 pr-0 ">
                {currentUser?.storage_management_save_record_access_flag && (
                  <Link to="/createstoragelocation">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm col-lg-11 col-md-11 col-sm-11 m-0 float-end"
                      id="checkin-records-button"
                    >
                      NEW STORAGE LOCATION
                    </button>
                  </Link>
                )}
              </div>
            </div>

            <MDBCard className="card overflow-hidden h-md-100 table-box">
              <CustomHeader label={"STORAGE LOCATION RECORDS"} />
              <MDBCardBody>
                <div className="table-responsive">
                  <table className="table safeChiefTable">
                    <thead className="headerArea">
                      <tr>
                        {Object.entries(ConfigData.viewLocationsDetails).map(
                          ([key, value], index) => {
                            return (
                              <th
                                scope="col"
                                className={
                                  index === 0 ? "tableHeading1" : "tableHeading"
                                }
                              >
                                {" "}
                                {value}{" "}
                              </th>
                            );
                          }
                        )}
                        {currentUser?.storage_management_save_record_access_flag && (
                          <th scope="col" className="tableHeading">
                            ACTIONS
                          </th>
                        )}
                      </tr>
                    </thead>

                    <tbody id="cs-scroll">
                      {saveLoader ?
                    (<tr>
                      <td colSpan="6" className="text-center py-5">
                      {loaderDisplay()}
                      </td>
                     </tr>
                    ) 
                       : (
                        <>
                          {displayStorageLocations &&
                            displayStorageLocations.map((item, idx) => {
                              return (
                                <tr className="table-row">
                                  <td className="tableDetails1">
                                    {" "}
                                    {item?.name}{" "}
                                  </td>
                                  <td className="tableDetails">
                                    {" "}
                                    {item?.address?.city}{" "}
                                  </td>
                                  <td className="tableDetails">
                                    {" "}
                                    {item?.address?.state}{" "}
                                  </td>
                                  <td className="tableDetails">
                                    {" "}
                                    {item?.storage_type}{" "}
                                  </td>
                                  <td className="tableDetails">
                                    {" "}
                                    {item?.authorized_by?.employee_email}{" "}
                                  </td>
                                  {currentUser?.storage_management_save_record_access_flag && (
                                    <td
                                      id="action-col"
                                      className="tableDetails"
                                    >
                                      {item?.storage_type === "Warehouse" && (
                                        <button
                                          id="unit-btn"
                                          className="btn-sm btn-primary"
                                          onClick={() => {
                                            openpopup(item);
                                          }}
                                        >
                                          ADD UNIT
                                        </button>
                                      )}
                                      <Link
                                        to="/createstoragelocation"
                                        state={item}
                                      >
                                        {" "}
                                        <img
                                          src={EditStorage}
                                          alt="Edit"
                                          className="edit-icon"
                                        />
                                      </Link>
                                      <img
                                        className="delete-icon"
                                        src={DeleteStorage}
                                        alt="Remove"
                                        onClick={() => onClickDelete(item, idx)}
                                      />
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          {displayStorageLocations?.length < 1 && (
                            <div className="text-center py-5 alignself-center">
                              {noRecordsFound}
                            </div>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </MDBCardBody>
              <Pagination
                pages={pages}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
                onPageClick={onPageClick}
                initial={initial}
                setRowsPerPage={setRowsPage}
                currentPage={currentPage}
              />
            </MDBCard>
          </div>
        </div>
      </div>

      {dropDownType !== "Branch" &&
        dropDownType !== "Vault" &&
        dropDownType !== "WarehouseUnits" &&
        dropDownType !== "" && (
          <WarehouseModal
            dropDownType={dropDownType}
            dropdownSearch={dropdownSearch}
            clearData={clearData}
            saveLocation={saveLocation}
            editLocation={editLocation}
            deleteLocation={deleteLocation}
            showUnits={showUnits}
            locationDetails={recordData}
            onClickSubmit={onClickSubmit}
          />
        )}

      {dropDownType === "Branch" && (
        <BranchModal
          dropDownType={dropDownType}
          displayUnits={displayUnits}
          onClickAdd={onClickAdd}
          onChangeUnits={onChangeUnits}
          removeUnits={removeUnits}
          clearData={clearData}
          onClickUpdate={onClickUpdate}
        />
      )}

      {dropDownType === "Vault" && (
        <VaultModal
          dropDownType={dropDownType}
          displayUnits={displayUnits}
          onClickAdd={onClickAdd}
          onChangeUnits={onChangeUnits}
          removeUnits={removeUnits}
          clearData={clearData}
          onClickUpdate={onClickUpdate}
        />
      )}

      {dropDownType === "WarehouseUnits" && (
        <WarehouseUnitsModal
          dropDownType={dropDownType}
          displayUnits={displayUnits}
          clearData={clearData}
          warehousePages={warehousePages}
          onNextClickWareHouse={onNextClickWareHouse}
          onPreviousWareHouse={onPreviousWareHouse}
          onWareHousePageClick={onWareHousePageClick}
          wareHouseInitial={wareHouseInitial}
          wareHouseEnd={wareHouseEnd}
          setWarehouseRowsPage={setWarehouseRowsPage}
          displayModals={displayModals}
          warehouseUnitsSuggestions={warehouseUnitsSuggestions}
          displaySpinner={displaySpinner}
        />
      )}

      <CustomModal
        currentEventData={currentEventData}
        show={message}
        onHide={() => setMessage(false)}
      />
    </>
  );
};
export default ViewStorageLocation;
