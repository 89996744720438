import { MDBCard, MDBCardBody } from "mdbreact";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
//eslint-disable-next-line
import { getHistory, getPaginationHistory } from "../api/History";
import CustomHeader from "../components/CustomHeader";
import HistoryTimeline from "../components/HistoryTimeline";
import "../style/checkinHistory.css";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AUDIT_HISTORY_LIMIT } from "../constants/AuditConstants";
import { ConsoleError } from "../utils/ErrorUtils";
import { DISPLAY_DATE_FORMAT } from "../constants/Constants";
import dayjs from "dayjs";
import { prepareOwnerData } from "../utils/AuditUtils";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

function AuditHistory(props) {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const [clickid, setClickId] = useState();
  const [selectedEvent, setSelectedEvent] = useState(0);
  const [auditData, setAuditData] = useState();
  const [bagId, setbagId] = useState();
  const [actionDate, setActionDate] = useState();
  const [actionType, setActipnType] = useState();
  const [notes, setNotes] = useState();
  const [loader, setLoader] = useState(false);
  //eslint-disable-next-line
  const [timeLineloader, settimeLineLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const event = "Audit";
  //eslint-disable-next-line
  const [nextPageNo, setNextPageNo] = useState();
  const [documents, setDocuments] = useState([]);
  //eslint-disable-next-line
  const [perviousPageNo, setPerviousPageNo] = useState();
  const [ownerData, setOwnerData] = useState({});
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const tebId = state?.tamper_evident_bag?.tamper_evident_bag_id
    ? state?.tamper_evident_bag?.tamper_evident_bag_id
    : state?.information?.tamper_evident_bag?.tamper_evident_bag_id
    ? state?.information.tamper_evident_bag?.tamper_evident_bag_id
    : null;
    useEffect(() => {
      const handleAuditData = async () => {
        setLoader(true); // Start loading
  
        if (auditData) {
          
          // Find the index of the event matching state?.audit_id
          const currentEventIndex = auditData.findIndex(
            (e) => e.audit_id === state?.audit_id
          );
  
          // Check if a valid event was found
          if (currentEventIndex >= 0) {
            await onClick(auditData[currentEventIndex], currentEventIndex);
          } else {
            console.warn("No matching audit event found for the given audit_id.");
          }
        }
  
        setLoader(false); // Stop loading
      };
  
      handleAuditData();
    }, [auditData, state?.audit_id]);
  
  const getTimelineData = async () => {
    setLoader(true);
    getHistory("audit", tebId, 1, AUDIT_HISTORY_LIMIT, currentUser?.jwtToken)
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          if (isNaN(response?.data.next)) {
            setNextPageNo(
              response?.data.next ? response?.data.next.slice(-1) : null
            );
          }
          if (isNaN(response?.data.previous)) {
            setPerviousPageNo(
              response?.data.previous ? response?.data.previous.slice(-1) : null
            );
          }

          setLoader(false);
          // setAuditData(response?.data ? response?.data?.records : null);
          // setOwnerData(response?.data?.records[0]);
          setAuditData(
            response?.data ? response?.data : null
          );

          setOwnerData(response?.data?.[0]);
          setbagId(
            response?.data[0].tamper_evident_bag?.tamper_evident_bag_number
          );

          setActionDate(response?.data[0]?.audit_date);
          setActipnType(response?.data[0]?.action?.action);
          setNotes(response?.data[0].notes);

          setDocuments(response?.data[0].documents);
          // props?.eventData({ data: "data" });
        }
      })

      .catch((error) => {
        ConsoleError(error);
      });
  };
  const download = (link, name) => {
    saveAs(link, name);
  };
  dayjs.extend(utc);
  dayjs.extend(tz);
  const localTimezone = dayjs.tz.guess();

  const onPageClick = async (operation) => {
    //TO-DO
    // let pageNo;
    // if (operation === "plus") {
    //   pageNo = nextPageNo;
    // } else {
    //   if (perviousPageNo === undefined) {
    //     getTimelineData();
    //   }
    //   pageNo = perviousPageNo;
    // }
    // settimeLineLoader(true);
    // getPaginationHistory(
    //   tebId,
    //   event,
    //   pageNo,
    //   currentUser?.employee_id,
    //   currentUser?.jwtToken
    // )
    //   .then((response) => {
    //     if (response.status === 401) {
    //       cognitoSignOut()
    //         .then(() => {
    //           navigate("/");
    //         })
    //         .catch((error) => {
    //           ConsoleError(error)
    //         });
    //     } else if (response.status === 200) {
    //       settimeLineLoader(false);
    //       setLoader(false);
    //       setAuditData(
    //         response?.data ? response?.data : null
    //       );
    //       setbagId(response?.data[0].tamperevidentbag_number);
    //       setActionDate(response?.data[0].audit_date);
    //       setActipnType(response?.data[0].action_type);
    //       setNotes(response?.data[0].notes);
    //       props?.eventData({ data: "data" });
    //     }
    //   })
    //   .catch((error) => {
    //     settimeLineLoader(false);
    //     ConsoleError(error)
    //   });
  };

  const onClick = (item, index) => {
    setSelectedEvent(index);
    setbagId(item?.tamper_evident_bag?.tamper_evident_bag_number);
    setActionDate(item?.audit_date);
    setActipnType(item?.action.action);
    setNotes(item?.notes);
    setDocuments(item?.documents);
    setClickId(index);
  };
  //eslint-disable-next-line
  const modal = (e) => {
    e.preventDefault();

    setShowModal(true);
  };
  useEffect(() => {
    getTimelineData();
    //eslint-disable-next-line
  }, [tebId]);

  function prepareData() {
    let box = auditData[0]?.tamper_evident_bag?.safe_deposit_box?.owner;
    let auditDetails = {
      actionDate: dayjs(actionDate).format("YYYY-MM-DD"),
      notes: notes,
      teb: auditData[0]?.tamper_evident_bag?.tamper_evident_bag_number,
      audit_id: auditData[0]?.audit_id,
    };

    let owner = prepareOwnerData(box);
    let item = { auditDetails: auditDetails, owner: owner };
    item.owner.address_id =
      ownerData?.tamper_evident_bag?.safe_deposit_box?.owner?.address?.address_id;
    item.owner.owner_id =
      ownerData?.tamper_evident_bag?.safe_deposit_box?.owner?.owner_id;
    item.owner.owner_fields_id =
      ownerData?.tamper_evident_bag?.safe_deposit_box?.owner?.owner_fields?.owner_fields_id;

    navigate("/createAudit", { state: item });
  }

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 ">
      <div className="row flex pt-4 pl-4 pr-4 pb-0 ">
        <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden">
          <div className="row pt-1 pb-2 mt-5">
            <div className="d-flex justify-content-end">
              <div className="p-0">
                {currentUser?.audit_save_record_access_flag && (
                  <Link to="/createAudit">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      id="bulk-checkin-button"
                    >
                      NEW AUDIT{" "}
                    </button>
                  </Link>
                )}
              </div>
              <div className="p-0">
                <Link to="/auditView">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    id="checkin-records-button"
                  >
                    AUDIT RECORDS{" "}
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <MDBCard className="card  p-0 m-0 ">
            <CustomHeader label={"AUDIT HISTORY"} />
            {loader ? (
              <div className="text-center py-5 mt-5 alignself-center">
                <Spinner animation="border" />
              </div>
            ) : (
              <MDBCardBody id="scroll-bar">
                <div className="row text-center ">
                  {timeLineloader ? (
                    <div className="text-center py-5 mt-5 alignself-center">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <div className="col-lg-12 col-md-12 pt-4 pb-4 ">
                      <HistoryTimeline
                        historyData={auditData}
                        onClick={onClick}
                        clickid={clickid}
                        selectedEvent={selectedEvent}
                        event={event}
                        onPageClick={onPageClick}
                      />
                    </div>
                  )}
                  <div className="event-div">
                    <div className="d border border-5 event-border h-100 ml-5 mr-5 mb-5">
                      <div className="row col-lg-12 justify-content-center pt-3 pb-3 row">
                        <span className="col-lg-10 ms-5">
                          {showModal ? "HISTORY DETAILS" : "EVENT DETAILS"}
                        </span>
                        {selectedEvent === 0 && (
                          <button
                            className="col-lg-1 btn btn-primary btn-sm"
                            style={{ marginRight: "-20px", marginTop: "-10px" }}
                            onClick={prepareData}
                          >
                            Edit
                          </button>
                        )}
                      </div>{" "}
                      <div className="row col-lg-12 col-md-12 col-sm-12 ml-2 pb-3 bs-scroll">
                        <div
                          className={
                            showModal
                              ? "col-lg-6 col-md-12 col-sm-12 "
                              : "col-lg-12 col-md-12 col-sm-12  "
                          }
                        >
                          <form>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                {t("labels.teb")}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {" "}
                                  {bagId ? bagId : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Action Date{" "}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {actionDate
                                    ? dayjs(actionDate)
                                        .tz(localTimezone)
                                        .format(DISPLAY_DATE_FORMAT)
                                    : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Action Type{" "}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {actionType ? actionType : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Audit Notes{" "}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {notes ? notes : null}
                                </div>
                              </div>
                            </div>
                            {documents?.length > 0 && (
                              <div className="form-group row">
                                <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                  Documents{" "}
                                </label>
                                <div className="col-sm-8 p-0">
                                  {documents?.filter(
                                    (document) => document?.type === "DOCUMENT"
                                  ).length > 0 && (
                                    <div className="row ">
                                      <div className="col-8 fw-bold d-flex pl-3">
                                        <span className="float-start">
                                          Uploaded Document Name
                                        </span>
                                      </div>
                                      <div className="col-4 fw-bold">
                                        Description
                                      </div>
                                    </div>
                                  )}
                                  {documents
                                    ?.filter(
                                      (document) =>
                                        document?.type === "DOCUMENT"
                                    )
                                    .map((filteredDocument) => (
                                      <div className="row">
                                        <div className="col-8 d-flex">
                                          <span
                                            className="cursor-pointer float-start"
                                            onClick={() =>
                                              download(
                                                filteredDocument?.url,
                                                filteredDocument?.name
                                              )
                                            }
                                          >
                                            {filteredDocument?.name}
                                            <i className="fa fa-download pl-2" />
                                          </span>
                                        </div>
                                        <div className="col-4 ">
                                          {filteredDocument?.description}
                                        </div>
                                      </div>
                                    ))}
                                  {documents?.filter(
                                    (document) => document?.type === "PICTURE"
                                  ).length > 0 && (
                                    <div className="row pt-3">
                                      <div className="col-8 fw-bold d-flex pl-3">
                                        <span className="float-start">
                                          Added Picture Name
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {documents
                                    ?.filter(
                                      (document) => document?.type === "PICTURE"
                                    )
                                    .map((filteredPicture) => (
                                      <div className="row">
                                        <div className="col-8 d-flex">
                                          <span
                                            className="cursor-pointer float-start"
                                            onClick={() =>
                                              download(
                                                filteredPicture?.url,
                                                filteredPicture?.name
                                              )
                                            }
                                          >
                                            {filteredPicture?.name}
                                            <i className="fa fa-download pl-2" />
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MDBCardBody>
            )}
          </MDBCard>
        </div>
      </div>
    </div>
  );
}

export default AuditHistory;
