import React, { useState } from "react";

const NotificationBadge = ({ count }) => {
  const [isActive, setIsActive] = useState(false);

  const handleBellClick = () => {
    setIsActive(!isActive); // Toggle active state on click
  };

  return (
    <div className="notification-badge" onClick={handleBellClick}>
      <div className={`notification-bell-container ${isActive ? 'active' : ''}`}>
        <div className="notification-bell"></div>
      </div>
      {count > 0 && (
        <div className="notification-badge-count">
          {count}
        </div>
      )}
    </div>
  );
};

export default NotificationBadge;
