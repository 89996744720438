import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  saveStorageLocation,
  updateStorageLocation,
} from "../api/StorageLocationServices";
import SearchPanel from "../components/SearchPanel";
import { CustomAlert } from "../components/customAlert";
import CustomHeader from "../components/CustomHeader";
import "../style/StorageLocation.css";
import { formatPhoneNumber } from "../components/common/Utils";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import { ConsoleError } from "../utils/ErrorUtils";
import { getEmployeesOnsearch } from "../api/EmployeeService";
import { ERROR_MESSAGE } from "../constants/MessagesConstants";
import { useDebounce } from "../utils/useDebounce";

function CreateStorageLocation(props) {
  let navigate = useNavigate();
  let location = useLocation();
  const initialValues = {
    name: "",
    identifier: "",
    street1: "",
    city: "",
    street2: "",
    zipcode: "",
    state: "",
    storage_location_type: "",
    phone_number: "",
    authorizer_name: "",
    authorizer_phone: "",
    authorizer_email: "",
    branch_did: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const [employeeOptionList, setEmployeeOptionList] = useState([]);

  const [employeeList, setEmployeeList] = useState([]);

  const [authorizerShowOptions, setAuthorizerShowOptions] = useState(true);
  const [authorizerSearchString, setAuthorizerSearchString] = useState("");
  const [authorizerId, setAuthorizerId] = useState("");
  const [isAuthorizerAssigned, setIsAuthorizerAssigned] = useState(false);
  const [authorizerEmail, setAuthorizerEmail] = useState("");
  const [isBranch, setIsBranch] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone_number" || name === "authorizer_phone") {
      const formattedPhoneNumber = formatPhoneNumber(e.target.value);
      setFormValues({ ...formValues, [name]: formattedPhoneNumber });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    if (formErrors[name] !== undefined && formErrors[name].length > 0) {
      delete formErrors[name];
    }

    if (name === "storage_location_type" && value === "Branch") {
      setIsBranch(true);
    }

    if (name === "storage_location_type" && value !== "Branch") {
      setIsBranch(false);
    }
  };

  const debouncedEmployeeSearchQuery = useDebounce(authorizerSearchString, 500);

  const getEmployeesBySearch = () => {
    const approvalAccessFlag = true;
    const authorizerAcessFlag = null;
    const auditAccessFlag = null;
    getEmployeesOnsearch(
      approvalAccessFlag,
      authorizerAcessFlag,
      auditAccessFlag,
      debouncedEmployeeSearchQuery,
      currentUser?.jwtToken
    )
      .then((response) => {
        response = response?.data;
        setEmployeeList(response);
        setEmployeeOptionList(
          response.map(function (emp) {
            return (
              (emp.employee_name ? emp.employee_name : "") +
              (emp.employee_email ? " - " + emp.employee_email : "") +
              (emp.employee_org_id ? " - " + emp.employee_org_id : "")
            );
          })
        );
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };

  useEffect(() => {
    if (!isAuthorizerAssigned && debouncedEmployeeSearchQuery.length > 0) {
      getEmployeesBySearch();
    }
    //eslint-disable-next-line
  }, [debouncedEmployeeSearchQuery]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let newformValues = { ...formValues };
    let data = validate(newformValues);
    setFormErrors(data);
    if (location.state !== null) {
      if (Object.keys(data).length === 0) {
        updateStorageLocationData();
      }
    } else if (location.state === null) {
      if (Object.keys(validate(formValues)).length === 0) {
        setIsSubmit(true);
        Swal.fire({
          title: "Processing, please wait",
          html: "loading...",
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        let newName = formValues.name.replace(/\s+/g, " ");
        let storageLocData = {
          name: newName.trim(),
          address_line_1: formValues.street1,
          address_line_2: formValues.street2,
          city: formValues.city,
          state: formValues.state,
          zipcode: formValues.zipcode,
          storage_location_type: formValues.storage_location_type,
          phone_number: formValues.phone_number,
          identifier: parseInt(formValues.identifier),
          authorizer_id: authorizerId,
          branch_did: formValues.branch_did,
        };

        saveStorageLocation(storageLocData, currentUser?.jwtToken)
          .then((response) => {
            Swal.fire({
              title: "Processing, please wait",
              html: "loading...",
              allowOutsideClick: false,
              onBeforeOpen: () => {
                Swal.showLoading();
              },
            });
            if (response.status === 401) {
              cognitoSignOut()
                .then(() => {
                  navigate("/");
                })
                .catch((err) => {
                  console.error(err);
                });
            } else if (response?.status === 201) {
              Swal.close();
              showSuccessAlert("Storage Location saved successfully");
            } else if (response?.status === 400) {
              Swal.close();
              showFailAlert(
                "Create Storage Location failed due to invalid data"
              );
            } else if (response?.status === 409) {
              Swal.close();
              showFailAlert("Storage Location Name already exists");
            } else {
              Swal.close();
              showFailAlert("Create Storage Location failed");
            }
          })
          .catch((error) => {
            Swal.close();
            showFailAlert(ERROR_MESSAGE);
            ConsoleError(error);
          });
      }
    }
  };

  const updateStorageLocationData = () => {
    Swal.fire({
      title: "Processing, please wait",
      html: "loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    setFormValues(formValues);
    //compare previous value with the changed value and then updating the data
    let obj = { ...location.state };
    let update_data = {};
    Object.keys(obj).forEach((key) => {
      // if ( changed value === previous value sending it to modified data)
      if (
        key !== "created_time" &&
        key !== "updated_time" &&
        key !== "storage_location_id"
      ) {
        update_data[key] = formValues[key];
      } else {
        delete update_data[key];
      }
    });
    // if (update_data.toString.length === 0) {
    //   //  modifiedData["storage_location_id"]
    // }
    // TO-DO Add a check if any changes done by user
    let newName = formValues.name.replace(/\s+/g, " ").trim();
    const data = {
      name: newName ? newName : location.state.name,
      identifier: parseInt(
        formValues.identifier
          ? formValues.identifier
          : location.state.identifier
      ),
      address_line_1: formValues.street1
        ? formValues.street1
        : location.state.street1,
      address_line_2: formValues.street2
        ? formValues.street2
        : location.state.street2,
      city: formValues.city ? formValues.city : location.state.city,
      state: formValues.state ? formValues.state : location.state.state,
      zipcode: formValues.zipcode ? formValues.zipcode : location.state.zipcode,
      phone_number: formValues.phone_number
        ? formValues.phone_number
        : location.state.phone_number,
      branch_did:
        formValues.storage_location_type === "Branch"
          ? formValues.branch_did
          : "",
      authorizer_id: authorizerId
        ? authorizerId
        : location.state.authorized_by.employee_id,
    };
    if (formValues.storage_location_type === "Vault") {
      data.vault_id = location.state.vault_id;
    }
    if (formValues.storage_location_type === "Branch") {
      data.branch_id = location.state.branch_id;
    }
    if (formValues.storage_location_type === "Warehouse") {
      data.warehouse_id = location.state.warehouse_id;
    }
    updateStorageLocation(data, currentUser?.jwtToken)
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((err) => {
              console.error(err);
            });
        } else if (response?.status === 201 || response?.status === 200) {
          Swal.close();
          showSuccessAlert1("Storage Location updated successfully");
        } else if (response?.status === 400) {
          Swal.close();
          showFailAlert("Storage Location update failed due to invalid data");
        } else if (response?.status === 409) {
          Swal.close();
          showFailAlert("Storage Location Name already exists");
        } else {
          Swal.close();
          showFailAlert("Storage Location update failed");
        }
      })
      .catch((error) => {
        Swal.close();
        showFailAlert(ERROR_MESSAGE);
        ConsoleError(error);
      });
  };
  const showSuccessAlert = (response) => {
    let title = "Saved";
    let text = response;
    let type = "success";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      target
    ).then((result) => {
      if (result.value) {
        navigate("/storagelocation");
      }
    });
  };

  const showSuccessAlert1 = (response) => {
    let title = "Updated";
    let text = response;
    let type = "success";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      target
    ).then((result) => {
      if (result.value) {
        navigate("/storagelocation");
      }
    });
  };

  const showFailAlert = (response) => {
    let title = "Failed";
    let text = response;
    let type = "error";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(title, text, type, confirmButtonText, showCancelButton, target);
  };

  const validate = (values) => {
    const errors = {};
    //eslint-disable-next-line
    const regexPersonName = /^[a-zA-Z ]*$/;
    const regexCity = /^[a-zA-Z ]*$/;
    const regexState = /^[a-zA-Z ]*$/;
    const regexZipCode = /^[0-9]{5}(?:-[0-9]{4})?$/;
    const regexStoragePhoneNo = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    //const regexPersonPhoneNo = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    const regexIdentifier = /^[1-9]\d{15}$/; // Ensure first digit is not 0 and total length is 16 digits
    //eslint-disable-next-line
    const regexPersonEmail =
      //eslint-disable-next-line
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const regexWhiteSpaces = /.*\S.*/;

    if (!values.street1 || !regexWhiteSpaces.test(values.street1)) {
      errors.street1 = "Address Line 1 is required";
    }

    if (!values.street2 || !regexWhiteSpaces.test(values.street2)) {
      errors.street2 = "Address Line 2 is required";
    }

    if (!values.storage_location_type) {
      errors.storage_location_type = "Storage Location type is required";
    }

    if (!values.name || !regexWhiteSpaces.test(values.name)) {
      errors.name = "Storage Name is required";
    }

    if (isBranch && !values.branch_did) {
      errors.branch_did = "Branch DID is required";
    }

    if (!isAuthorizerAssigned) {
      errors.authorizer_name = "Person Name is required";
    }

    // if (isAuthorizerAssigned !== "") {
    //   if (
    //     !regexPersonName.test(values.authorizer_name) ||
    //     !regexWhiteSpaces.test(values.authorizer_name)
    //   ) {
    //     errors.authorizer_name =
    //       "Please provide valid person name, only alphabets with spaces";
    //   }
    // }
    if (values.phone_number !== "") {
      if (!regexStoragePhoneNo.test(values.phone_number)) {
        errors.phone_number =
          "Please provide valid phone number in '(123) 456-7890' format";
      }
    }
    if (values.phone_number == "") {
      errors.phone_number = "Phone number is required";
    }

    // if (values.authorizer_phone !== "") {
    //   if (!regexPersonPhoneNo.test(values.authorizer_phone)) {
    //     errors.authorizer_phone =
    //       "Please provide valid phone number in '(123) 456-7890' format";
    //   }
    // }
    if (!authorizerEmail) {
      errors.authorizer_email = "Email is required";
    }

    // if (values.authorizer_email !== "") {
    //   if (!regexPersonEmail.test(values.authorizer_email)) {
    //     errors.authorizer_email =
    //       "Please provide valid email in 'abc@xyz.com' format";
    //   }
    // }

    if (!values.city) {
      errors.city = "City is required";
    }

    if (values.city !== "") {
      if (!regexCity.test(values.city) || !regexWhiteSpaces.test(values.city)) {
        errors.city = "Please provide valid city, only alphabets with spaces";
      }
    }

    if (!values.state) {
      errors.state = "State is required";
    }

    if (values.state !== "") {
      if (
        !regexState.test(values.state) ||
        !regexWhiteSpaces.test(values.state)
      ) {
        errors.state = "Please provide valid state, only alphabets with spaces";
      }
    }

    if (!values.zipcode) {
      errors.zipcode = "Zip Code is required";
    }

    if (values.zipcode !== "") {
      if (!regexZipCode.test(values.zipcode)) {
        errors.zipcode =
          "Please provide valid zip code in '12345-1234' or '12345' format";
      }
    }

    if (!values.identifier) {
      errors.identifier = "Identifier is required";
    }

    if (values.identifier !== "") {
      if (!regexIdentifier.test(values.identifier)) {
        errors.identifier =
          "Invalid identifier: must be 16 digits and not start with 0.";
      }
    }

    return errors;
  };

  useEffect(() => {
    if (location.state !== null) {
      const tempFormValues = {
        name: location.state.name,
        identifier: location.state.identifier,
        street1: location.state.address.street_1,
        city: location.state.address.city,
        street2: location.state.address.street_2,
        zipcode: location.state.address.zipcode,
        state: location.state.address.state,
        storage_location_type: location.state.storage_type,
        phone_number: location.state.address.phone_number_1,
        branch_did: location.state.branch_did,
      };
      if (location.state.storage_type === "Branch") {
        setIsBranch(true);
      } else {
        setIsBranch(false);
      }
      setAuthorizerSearchString(location.state.authorized_by.employee_name);
      setAuthorizerEmail(location.state.authorized_by.employee_email);
      setAuthorizerShowOptions(false);
      setFormValues(tempFormValues);
      setIsAuthorizerAssigned(true);
    }

    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 h-lg-100 p-0 body">
        <div className="row flex pl-4 pt-4 pr-4 pb-0">
          <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden">
            <div className="row pt-1 pb-2 mt-5">
              <div className="d-flex justify-content-end">
                <div className="p-0">
                  <Link to="/storagelocation">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      id="checkin-records-button"
                    >
                      STORAGE LOCATIONS
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <MDBCard className="card p-0 m-0">
              <CustomHeader
                label={
                  location.state === null
                    ? "Add storage location "
                    : "Edit storage location"
                }
              />
              <MDBCardBody id="cs-scroll">
                <form onSubmit={handleSubmit} className="card formHeight">
                  <div className="row mt-4 card-border divMainSL">
                    <div className="row col-lg-12 justify-content-center pt-2 divPaddingHeader">
                      STORAGE LOCATION DETAILS
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                        <label htmlFor="exampleFormControlTextarea1">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Name"
                          value={formValues.name || ""}
                          onChange={handleChange}
                          name="name"
                          autoComplete="off"
                        />
                        <div className="error-message-validation">
                          {formErrors.name}
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                        <label htmlFor="inputPassword4"> Identifier</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Identifier"
                          value={formValues.identifier || ""}
                          onChange={handleChange}
                          name="identifier"
                          autoComplete="off"
                          maxLength="16"
                        />
                        <div className="error-message-validation">
                          {formErrors.identifier}
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                        <label>Storage Location Type</label>
                        <select
                          className="form-control"
                          name="storage_location_type"
                          defaultValue=""
                          value={formValues.storage_location_type}
                          onChange={handleChange}
                          disabled={location.state === null ? false : true}
                        >
                          <option value="" default disabled>
                            Select...
                          </option>
                          <option value="Warehouse">Warehouse</option>
                          <option value="Branch">Branch</option>
                          <option value="Vault">Vault</option>
                        </select>
                        <div className="error-message-validation">
                          {formErrors.storage_location_type}
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                        <label htmlFor="exampleFormControlTextarea1">
                          Address Line 1
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Street Address"
                          value={formValues.street1 || ""}
                          onChange={handleChange}
                          name="street1"
                          autoComplete="off"
                        />
                        <div className="error-message-validation">
                          {formErrors.street1}
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                        <label htmlFor="inputPassword4"> Address Line 2</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Address Line 2"
                          value={formValues.street2 || ""}
                          onChange={handleChange}
                          name="street2"
                          autoComplete="off"
                        />
                        <div className="error-message-validation">
                          {formErrors.street2}
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                        <label htmlFor="inputPassword4">City</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter City"
                          value={formValues.city || ""}
                          onChange={handleChange}
                          name="city"
                          autoComplete="off"
                        />
                        <div className="error-message-validation">
                          {formErrors.city}
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                        <label htmlFor="inputPassword4">State</label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          placeholder="Enter State"
                          value={formValues.state || ""}
                          onChange={handleChange}
                          name="state"
                        />
                        <div className="error-message-validation">
                          {formErrors.state}
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                        <label htmlFor="inputPassword4">Zip Code</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Zip Code"
                          value={formValues.zipcode || ""}
                          onChange={handleChange}
                          name="zipcode"
                          autoComplete="off"
                          maxLength="10"
                        />
                        <div className="error-message-validation">
                          {formErrors.zipcode}
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                        <label htmlFor="exampleFormControlTextarea1">
                          Phone Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Phone Number"
                          value={formValues.phone_number || ""}
                          onChange={handleChange}
                          name="phone_number"
                          autoComplete="off"
                          maxLength="14"
                        />
                        <div className="error-message-validation">
                          {formErrors.phone_number}
                        </div>
                      </div>
                    </div>
                    {isBranch && (
                      <div className="form-row">
                        <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                          <label htmlFor="branch_did">Branch DID</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Branch DID"
                            value={formValues.branch_did || ""}
                            onChange={handleChange}
                            name="branch_did"
                            autoComplete="off"
                          />
                          <div className="error-message-validation">
                            {formErrors.branch_did}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row mt-4 card-border divMainSL">
                    <div className="row col-lg-12 justify-content-center pt-2 divPaddingHeader">
                      STORAGE LOCATION AUTHORISED PERSON DETAILS
                    </div>
                    <div className="form-row mt-2">
                      <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                        <SearchPanel
                          label="Employee Name"
                          placeholder="Search by Employee Name or Email"
                          setOptionList={setEmployeeOptionList}
                          setShowOptions={setAuthorizerShowOptions}
                          options={employeeOptionList}
                          showOptions={authorizerShowOptions}
                          onFocusSearch={(e) => {
                            setAuthorizerSearchString("");
                            setAuthorizerEmail("");
                            setEmployeeOptionList([]);
                            if (location.state === null) {
                              setAuthorizerShowOptions(true);
                            } else {
                              setAuthorizerShowOptions(false);
                            }
                          }}
                          handleChange={(str) => {
                            setEmployeeOptionList([]);
                            if (isAuthorizerAssigned) {
                              setAuthorizerSearchString(
                                str.target.value.charAt(str.length - 1)
                              );
                              setIsAuthorizerAssigned(false);
                              setAuthorizerShowOptions(true);
                            } else {
                              setAuthorizerSearchString(str.target.value);
                            }
                          }}
                          searchString={authorizerSearchString}
                          onSelectValue={(selectedAuthorizer) => {
                            setIsAuthorizerAssigned(true);
                            setAuthorizerShowOptions(false);
                            const authorizer = employeeList.filter(
                              (emp) =>
                                emp.employee_email ===
                                selectedAuthorizer.split(" - ")[1]
                            )[0];
                            setAuthorizerSearchString(authorizer.employee_name);
                            setAuthorizerEmail(authorizer.employee_email);
                            setAuthorizerId(authorizer.employee_id);
                          }}
                        />
                        <div className="error-message-validation">
                          {formErrors.authorizer_name}
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                        <label htmlFor="exampleFormControlTextarea1">
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email"
                          disabled
                          value={authorizerEmail || ""}
                          onChange={handleChange}
                          name="authorizer_email"
                          autoComplete="off"
                        />
                        <div className="error-message-validation">
                          {formErrors.authorizer_email}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fieldArea7 pt-2">
                    <Link to="/storagelocation">
                      <button
                        className="btn  btn-sm"
                        id="cancleBtn"
                        type="reset"
                        value="Reset"
                        style={{ color: "#fff" }}
                      >
                        CANCEL
                      </button>
                    </Link>
                    <button
                      onClick={handleSubmit}
                      className="btn  btn-sm btn-primary"
                    >
                      {location.state === null ? "SUBMIT" : "UPDATE"}
                    </button>
                  </div>
                </form>
              </MDBCardBody>
            </MDBCard>
          </div>
        </div>
      </div>
    </>
  );
}
export default CreateStorageLocation;
