import React, { useState, useCallback } from "react";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import "../style/signIn.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import CustomHeader from "../components/CustomHeader";
import { useSelector } from "react-redux";
import Swal from "sweetalert2"; // Import SweetAlert2
import { validatePassword } from "../utils/PasswordValidation";

const poolData = {
  UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
  ClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

const userPool = new CognitoUserPool(poolData);

const ChangePassword = () => {
  const navigate = useNavigate();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  // Validate fields in real-time
  const validateField = useCallback(
    (field, value) => {
      let error = "";
      switch (field) {
        case "newPassword":
          error = validatePassword(value);
          break;
        case "confirmPassword":
          error =
            value !== formData.newPassword ? "Passwords do not match." : "";
          break;
        default:
          break;
      }
      setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
    },
    [formData.newPassword]
  );

  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      validateField(name, value);
    },
    [validateField]
  );

  const isFormValid = useCallback(() => {
    const { oldPassword, newPassword, confirmPassword } = formData;
    const validationErrors = {};

    if (!oldPassword)
      validationErrors.oldPassword = "Old password is required.";
    if (!newPassword)
      validationErrors.newPassword = "New password is required.";
    if (!confirmPassword)
      validationErrors.confirmPassword = "Please confirm your new password.";

    if (newPassword) {
      const passwordError = validatePassword(newPassword);
      if (passwordError) validationErrors.newPassword = passwordError;
    }

    if (confirmPassword !== newPassword) {
      validationErrors.confirmPassword = "Passwords do not match.";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  }, [formData]);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (!isFormValid()) return;

    setIsLoading(true);
    const { oldPassword, newPassword } = formData;
    const currentUserInstance = userPool.getCurrentUser();

    if (currentUserInstance) {
      currentUserInstance.getSession((err, session) => {
        if (err) {
          setIsLoading(false);
          if (err.message.includes("Token expired")) {
            Swal.fire({
              title: "Session Expired",
              text: "Your session has expired. Please log in to continue.",
              icon: "warning",
              confirmButtonText: "Log In",
            }).then(() => navigate("/login"));
          } else {
            Swal.fire({
              title: "Error!",
              text: "An error occurred while retrieving the session.",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
          return;
        }

        currentUserInstance.changePassword(oldPassword, newPassword, (err) => {
          setIsLoading(false);
          if (err) {
            Swal.fire({
              title: "Failed",
              text: "Failed to change password. Please try again.",
              icon: "error",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              title: "Success!",
              text: "Password successfully changed.",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => navigateBasedonRole());
          }
        });
      });
    } else {
      setIsLoading(false);
      Swal.fire({
        title: "Error!",
        text: "User is not logged in. Please log in and try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const navigateBasedonRole = useCallback(() => {
    if (currentUser?.role === "safechief_admin") {
      navigate("/all-tenants");
    } else {
      navigate("/home");
    }
  }, [currentUser, navigate]);

  const togglePasswordVisibility = useCallback((field) => {
    setIsShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  }, []);

  const resetForm = useCallback(() => {
    setFormData({ oldPassword: "", newPassword: "", confirmPassword: "" });
    setErrors({});
    navigateBasedonRole();
  }, []);

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-auto p-0 body">
      <div className="row flex pl-4 pt-4 pr-4 pb-0">
        <div className="col-lg-12 pb-3 pl-0 pr-0">
          <div className="row pt-1 pb-2 mt-5"></div>
          <MDBCard className="card p-0 m-0">
            <CustomHeader label={"CHANGE PASSWORD"} />
            <MDBCardBody id="cs-scroll">
              {isLoading && (
                <div className="loader-container">
                  <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              <form onSubmit={handlePasswordChange} className="card formHeight">
                <div className="row mt-4 card-border divMainSL">
                  {[
                    { label: "Old Password", name: "oldPassword" },
                    { label: "New Password", name: "newPassword" },
                    { label: "Confirm Password", name: "confirmPassword" },
                  ].map(({ label, name }) => (
                    <div
                      key={name}
                      className="form-group col-md-6 col-xs-4 col-lg-4 px-4"
                    >
                      <label htmlFor={name}>{label}*</label>
                      <div
                        className="position-relative"
                        style={{ marginBottom: "1.5rem" }}
                      >
                        <input
                          type={isShowPassword[name] ? "text" : "password"}
                          className="form-control"
                          placeholder={`Enter ${label}`}
                          name={name}
                          value={formData[name]}
                          onChange={handleInputChange}
                          autoComplete="off"
                          style={{ paddingRight: "2.5rem" }} // Space for the eye icon
                        />
                        <i
                          className={`fa fa-eye${
                            isShowPassword[name] ? "" : "-slash"
                          }`}
                          aria-hidden="true"
                          style={{
                            position: "absolute",
                            right: "1rem",
                            top: "30%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                          onClick={() => togglePasswordVisibility(name)}
                        ></i>
                        <small
                          className="text-danger"
                          style={{
                            display: "block",
                            minHeight: "1rem", // Reserve space for error message
                            marginTop: "0.25rem",
                          }}
                        >
                          {errors[name] || ""}
                        </small>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="fieldArea7 pt-2">
                  <button
                    className="btn btn-sm"
                    id="cancleBtn"
                    type="reset"
                    style={{ color: "#fff" }}
                    onClick={resetForm}
                  >
                    CANCEL
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary">
                    SUBMIT
                  </button>
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
