import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomHeader from "../components/CustomHeader";
import { loader } from "../utils/loader";
import { validatePassword } from "../utils/PasswordValidation";
import { CustomAlert } from "../components/customAlert";

import "../style/signIn.css";

const ErrorMessage = ({ message }) => (
  <small
    style={{
      display: "block",
      height: "20px",
      fontSize: "12px",
      paddingTop: "5px",
    }}
    className="text-danger"
  >
    {message || ""}
  </small>
);

const PasswordChangeModal = ({
  isOpen,
  onClose,
  onChangePassword,
  setName,
}) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: {
      newPassword: "",
      confirmPassword: "",
    },
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  const navigate = useNavigate();

  const validateField = (name, value) => {
    const validationErrors = { ...errors };

    if (name === "firstName") {
      if (!value?.trim()) {
        validationErrors.firstName = "First name is required.";
      } else if (value?.length < 2) {
        validationErrors.firstName = "First name must be at least 2 characters.";
      } else if (value?.length > 15) {
        validationErrors.firstName = "First name must not exceed 15 characters.";
      } else {
        delete validationErrors.firstName;
      }
    }

    if (name === "lastName") {
      if (!value?.trim()) {
        validationErrors.lastName = "Last name is required.";
      } else if (value?.length < 2) {
        validationErrors.lastName = "Last name must be at least 2 characters.";
      } else if (value?.length > 15) {
        validationErrors.lastName = "Last name must not exceed 15 characters.";
      } else {
        delete validationErrors.lastName;
      }
    }

    if (name === "newPassword") {
      const passwordValidation = validatePassword(value);
      if (!value?.trim()) {
        validationErrors.newPassword = "New password is required.";
      } else if (passwordValidation?.length > 0) {
        validationErrors.newPassword = passwordValidation;
      } else {
        delete validationErrors.newPassword;
      }
    }

    if (name === "confirmPassword") {
      if (!value?.trim()) {
        validationErrors.confirmPassword = "Confirm password is required.";
      } else if (value !== formData?.password?.newPassword) {
        validationErrors.confirmPassword = "Passwords do not match.";
      } else {
        delete validationErrors.confirmPassword;
      }
    }

    setErrors(validationErrors);
  };

  // Validate the entire form
  const validateForm = () => {
    // Array of fields to validate
    const fieldsToValidate = ["firstName", "lastName", "newPassword", "confirmPassword"];

    // Validate all fields and aggregate errors
    fieldsToValidate.forEach((field) =>
      validateField(field, formData?.[field] || formData?.password?.[field])
    );

    // Return true if no errors, false otherwise
    return Object.keys(errors)?.length === 0;
  };

  // Handle input changes for text fields
  const handleInputChange = (e) => {
    const { name, value } = e?.target || {};
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validateField(name, value); // Validate the field in real-time
  };

  // Handle input changes for password fields
  const handlePasswordChange = (e) => {
    const { name, value } = e?.target || {};
    setFormData((prevData) => ({
      ...prevData,
      password: { ...prevData?.password, [name]: value },
    }));
    validateField(name, value); // Validate the field in real-time
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    setLoading(true);

    try {
      // Validate the entire form
      const isValid = validateForm();
      if (!isValid) {
        setLoading(false);
        return; // Stop submission if there are validation errors
      }

      // Proceed with submission
      setName(`${formData?.firstName} ${formData?.lastName}`);
      await onChangePassword(formData?.password?.newPassword);

      CustomAlert(
        "Password Update",
        "Your password has been successfully updated.",
        "success",
        "OK",
        false,
        "",
        document?.getElementById("swal-div"),
      );

      onClose();
      navigate("/");
    } catch (error) {
      console?.error("Error updating password:", error);
      CustomAlert(
        "Error",
        "Failed to update your password. Please try again.",
        "error",
        "OK",
        false,
        "",
        document?.getElementById("swal-div"),
      );
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = (field) => {
    setIsShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState?.[field],
    }));
  };

  const resetForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      password: {
        newPassword: "",
        confirmPassword: "",
      },
    });
    setErrors({});
  };

  if (!isOpen) return null;

  return (
    <div className="password_change_modal">
      <div className="password_change_modal-content">
        <h2 className="password_change_h2">Welcome to SafeChief</h2>
        <p className="password_change_p">
          To make your account secure, please create a new password to replace
          the temporary password you were given in the email invitation.
        </p>
        <form
          onSubmit={handleSubmit}
          className="password_change_form_container"
        >
          <div className="password_change_form_group pb-2">
            <label htmlFor="firstName">First Name*</label>
            <input
              type="text"
              className="form-control"
              name="firstName"
              value={formData?.firstName}
              onChange={handleInputChange}
              placeholder="Enter your first name"
              style={{ height: "40px" }}
            />
            <ErrorMessage message={errors?.firstName} />
          </div>

          <div className="password_change_form_group pb-2">
            <label htmlFor="lastName">Last Name*</label>
            <input
              type="text"
              className="form-control"
              name="lastName"
              value={formData?.lastName}
              onChange={handleInputChange}
              placeholder="Enter your last name"
              style={{ height: "40px" }}
            />
            <ErrorMessage message={errors?.lastName} />
          </div>

          <div className="password_change_form_group pb-2">
            <label htmlFor="newPassword">New Password*</label>
            <div className="password_input_wrapper">
              <input
                type={isShowPassword?.newPassword ? "text" : "password"}
                className="form-control"
                name="newPassword"
                value={formData?.password?.newPassword}
                onChange={handlePasswordChange}
                placeholder="Enter new password"
                style={{ height: "40px" }}
              />
              <i
                className={`fa fa-eye${
                  isShowPassword?.newPassword ? "" : "-slash"
                }`}
                onClick={() => togglePasswordVisibility("newPassword")}
              ></i>
            </div>
            <ErrorMessage message={errors?.newPassword} />
          </div>

          <div className="password_change_form_group pb-4">
            <label htmlFor="confirmPassword">Confirm Password*</label>
            <div className="password_input_wrapper">
              <input
                type={isShowPassword?.confirmPassword ? "text" : "password"}
                className="form-control"
                name="confirmPassword"
                value={formData?.password?.confirmPassword}
                onChange={handlePasswordChange}
                placeholder="Confirm new password"
                style={{ height: "40px" }}
              />
              <i
                className={`fa fa-eye${
                  isShowPassword?.confirmPassword ? "" : "-slash"
                }`}
                onClick={() => togglePasswordVisibility("confirmPassword")}
              ></i>
            </div>
            <ErrorMessage message={errors?.confirmPassword} />
          </div>

          {loading ? (
            loader()
          ) : (
            <div className="btn-group">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  onClose();
                  resetForm();
                }}
              >
                Cancel
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default PasswordChangeModal;
