import React, { useEffect } from "react";
import "../style/header.css";

function SearchPanel({
  label,
  placeholder,
  options,
  showOptions,
  setOptionList,
  setSearchString,
  setShowOptions,
  onFocusSearch,
  handleChange,
  searchString,
  onSelectValue,
  ...props
}) {
  const handleFocusSearch = () => {
    setOptionList([]); // Clear options
    onFocusSearch && onFocusSearch(); // Call external onFocusSearch handler if provided
  };

  const handleBlur = () => {
    const handleClick = (event) => {
      const clickedElement = event.target;
      if (
        clickedElement.id === "suggestions-result-panel" ||
        clickedElement.id === "suggestions-result-item"
      ) {
        return;
      }
      setShowOptions(false);
      setOptionList([]);

      // Remove the event listener after handling
      document.removeEventListener("click", handleClick);
    };

    // Adding event listener to close the options when clicked outside
    document.addEventListener("click", handleClick);
  };

  useEffect(() => {
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleBlur);
    };
  }, []);

  return (
    <>
      <label htmlFor="search-input-bar">{label}</label>
      <input
        id="search-input-bar"
        className="form-control pr-0 pl-5"
        placeholder={placeholder}
        autoComplete="off"
        data-testid="search_panel"
        value={searchString}
        onChange={handleChange}
        onFocus={handleFocusSearch}
        onBlur={handleBlur}
      />
      <i className="fa fa-search input-search-icon pl-3" aria-hidden="true"></i>
      {showOptions && options && options.length > 0 && (
        <div
          className="list-group search-result-panel"
          style={{ position: "absolute" }}
          data-testid="search-result-panel"
        >
          {options.map((item, key) => (
            <div
              className="search-result-panel-row"
              id="suggestions-result-panel"
              key={key}
              onClick={() => {
                onSelectValue(item); // Select value when clicked
              }}
            >
              <p
                className="pr-3 pl-2 py-2 mb-0"
                id="suggestions-result-item"
                data-testid="search-results"
              >
                {item}
              </p>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default SearchPanel;
