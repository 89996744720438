import { Auth } from "aws-amplify";
import { awsconfig } from "../aws-exports";
import { MDBCard } from "mdbreact";
import React, { useState ,useEffect} from "react";
import { createSafechiefAdmin, createUser } from "../api/CognitoServices";
import logo from "../assets/logo.png";
import "../style/signIn.css";
import { useDispatch } from "react-redux";
import { login } from "../redux/user";
import { useNavigate } from "react-router-dom";
import PasswordChangeModal from "./PasswordChangeModal";
import { loader } from "../utils/loader";
import CustomHeader from "../components/CustomHeader";

Auth.configure(awsconfig);

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [user, setUser] = useState(null);
  const [name, setName] = useState("");
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOTPClicked, setIsOTPClicked] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const dispatch = useDispatch();
  const [jwtToken, setJwtToken] = useState(null);
  let navigate = useNavigate();
  const [isShowPassword, setIsShowPassword] = useState({
      password: false,
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    try {
      if (emailRegex.test(email)) {
        setErrors("");
        setLoading(true);
        const loggedInUser = await Auth.signIn(email, password);
        setUser(loggedInUser);

        if (loggedInUser.challengeName === "NEW_PASSWORD_REQUIRED") {
          setModalOpen(true);
          setLoading(false);
          return;
        }

        setIsOTPClicked(true);
        setResendTimer(30);
      } else {
        setErrors("Please enter a valid email address");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000); 
    }
    return () => clearTimeout(timer); 
  }, [resendTimer]);

  const handleOtpVerification = async () => {
    try {
      setLoading(true);
      const verifiedUser = await Auth.confirmSignIn(user, otp, "SMS_MFA");
      const attributes = await Auth.currentAuthenticatedUser();
      const token = verifiedUser.signInUserSession.idToken.jwtToken;
      const userData = await processUserDetails(
        verifiedUser,
        attributes,
        token
      );
      dispatch(login(userData));
      setLoading(false);
      navigateToDashboard(userData.role);
    } catch (error) {
      setLoading(false);
      console.error("Error verifying OTP:", error);
      setErrors("Invalid OTP. Please try again.");
    }
  };

  const togglePasswordVisibility = (field) => {
    setIsShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState?.[field],
    }));
  };

  const processUserDetails = async (loggedInUser, attributes, token) => {
    const {
      signInUserSession: {
        idToken: { jwtToken, payload },
      },
      attributes: { email, "custom:tenant_id": tenantId },
    } = attributes;

    const userId = payload.sub;

    let designation;
    const userDetails = { employee_email: email };
    let userName;

    if (tenantId) {
      designation = await createUser(userDetails, token);
      userName =
        designation?.data?.employee_name +
        (designation?.data?.employee_lastname
          ? " " + designation?.data?.employee_lastname
          : "");
    } else {
      designation = await createSafechiefAdmin(
        { employee_email: email },
        token
      );
      userName = designation?.data?.safechief_user_name;
    }

    return {
      jwtToken,
      employee_id: designation?.data?.safechief_user_id || userId,
      employee_name: userName,
      employee_email: email,
      employee_designation:
        designation?.data?.employee_designation_configuration
          ?.authorization_type ||
        designation?.data?.employee_designation_configuration
          ?.employee_designation,
      employee_org_id: designation?.data?.safechief_user_name || userName,
      role:
        designation?.data?.employee_designation_configuration
          ?.authorization_type ||
        designation?.data?.employee_designation_configuration
          ?.employee_designation,
      ...designation?.data?.employee_designation_configuration,
    };
  };

  const navigateToDashboard = (role) => {
    if (role === "safechief_admin") {
      navigate("/all-tenants");
    } else {
      navigate("/home");
    }
  };

  const handleError = (error) => {
    if (error?.response?.status === 500) {
      setErrors("Oops! Something went wrong. Please try again later.");
    } else {
      if (
        (error.message || JSON.stringify(error)) ===
        "Incorrect username or password."
      ) {
        setErrors("Incorrect email or password.");
      } else {
        setErrors(error.message || JSON.stringify(error));
      }
    }
    dispatch(login(null));
  };

  const handlePasswordChange = async (newPassword) => {
    try {
      await Auth.completeNewPassword(user, newPassword, {
        name: name,
      });
      const token = user?.signInUserSession?.idToken?.jwtToken;
      setJwtToken(token);
    } catch (error) {
      setErrors(error);
    } finally {
      setModalOpen(false);
    }
  };
 
  return (
    <>
      <div className="d-md-flex h-md-100 align-items-center justify-content-center pl-5 pr-5">
        <MDBCard className="card card-body d-md-flex align-items-center signin-card p-5 m-5">
          <div className="row wrapper col-lg-12 text-white p-5 m-5">
            <div className="col-lg-6 align-items-center text-center justify-content-center appLogoDiv py-3">
              <img id="appLogo" src={logo} alt="logo" />
              <h1 className="pt-3">SAFECHIEF</h1>
            </div>
            {isOTPClicked ? (
              <div className="col-lg-6 text-center p-0">
                <div className="pt-5 pb-2 h1" id="welcometext">
                  Welcome to SafeChief
                </div>
                <h5 className="pb-3">
                  Please enter the OTP sent to your registered mobile number.
                </h5>
                <form className="text-left login-form">
                  <div
                    className="error-container"
                    style={{ visibility: errors ? "visible" : "hidden" }}
                  >
                    <span className="error-icon">⚠️</span>
                    <span className="error-text">{errors}</span>
                  </div>

                  <div className="form-group align-items-center justify-content-center">
                    <label htmlFor="password" className="login-label">
                      Enter OTP
                    </label>
                    <input
                      type="text"
                      className="form-control login-input"
                      id="email"
                      autofill="none"
                      value={otp}
                      placeholder="Enter OTP"
                      onChange={(e) => {
                        setOtp(e.target.value);
                        setErrors("");
                      }}
                      required
                      disabled={loading}
                    />
                    <p
                      className="forgot-password"
                      onClick={() => {
                        setIsOTPClicked(false);
                        setErrors("");
                      }}
                    >
                      Back to Login
                    </p>
                    <p className="forgot-password">
                      To resend OTP again please wait for : {resendTimer > 0 ? `(${resendTimer}s)` : ""}
                    </p>
                  </div>
                  {loading ? (
                    loader()
                  ) : (
                    <div className="otp-buttons">
                      <button
                        type="button"
                        onClick={handleSubmit}
                        className="btn btn-primary"
                        disabled={resendTimer > 0}
                      >
                        Resend 
                      </button>
                      <button
                        type="submit"
                        onClick={handleOtpVerification}
                        className="btn btn-primary"
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </form>
                <PasswordChangeModal
                  isOpen={modalOpen}
                  onClose={() => setModalOpen(false)}
                  onChangePassword={handlePasswordChange}
                  name={name}
                  setName={setName}
                />
              </div>
            ) : (
              <div className="col-lg-6 text-center p-0">
                <div className="pt-5 pb-2 h1" id="welcometext">
                  Welcome to SafeChief
                </div>
                <h5 className="pb-3">
                  Please enter your credentials to continue.
                </h5>
                <form onSubmit={handleSubmit} className="text-left login-form">
                  <div
                    className="error-container"
                    style={{ visibility: errors ? "visible" : "hidden" }}
                  >
                    <span className="error-icon">⚠️</span>
                    <span className="error-text">{errors}</span>
                  </div>
                  <div className="form-group align-items-center justify-content-center">
                    <label htmlFor="email" className="login-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control login-input"
                      id="email"
                      value={email}
                      placeholder="Enter your email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      disabled={loading}
                    />
                  </div>
                  <div className="form-group align-items-center justify-content-center">
                    <label htmlFor="password" className="login-label">
                      Password
                    </label>
                    <div className="password_input_wrapper">
                    <input
                      type={isShowPassword?.password ? "text" : "password"}
                      // type="password"
                      className="form-control login-input"
                      id="password"
                      value={password}
                      placeholder="Enter your password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setErrors("");
                      }}
                      required
                      disabled={loading}
                      style={{ height: "35px",lineHeight: "1.5"}}
                    />
                    <i
                      className={`fa fa-eye${
                        isShowPassword?.password ? "" : "-slash"
                      }`}
                      style={{ fontSize: "16px" }} 
                      onClick={() => togglePasswordVisibility("password")}
              ></i>
                    </div>
                    <p
                      className="forgot-password"
                      onClick={() => navigate("/forgot-password")}
                    >
                      Forgot Password
                    </p>
                  </div>
                  {loading ? (
                    loader()
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary login-button"
                    >
                      Sign In
                    </button>
                  )}
                </form>
                <PasswordChangeModal
                  isOpen={modalOpen}
                  onClose={() => setModalOpen(false)}
                  onChangePassword={handlePasswordChange}
                  name={name}
                  setName={setName}
                />
              </div>
            )}
          </div>
        </MDBCard>
      </div>
    </>
  );
}

export default SignIn;
