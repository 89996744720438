import React, { useEffect, useState } from "react";
import { MDBCard, MDBCardBody } from "mdbreact";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomHeader from "../components/CustomHeader";
import { useSelector } from "react-redux";
import { getApprovalDetails } from "../api/ApprovalsService";
import { CustomAlert } from "../components/customAlert";
import Swal from "sweetalert2";
import "../style/approvaldetails.css";
import CheckInApproval from "./CheckInApproval";
import ForceopenApproval from "./ForceopenApproval";
import ShipContentApproval from "./ShipContentApproval";
import ApprovedModal from "./ApprovedModal";
import { UPDATE_APPROVAL_PAYLOAD } from "../constants/ApprovalConstants";
import { postApproveStatus } from "../api/ApprovalsService";
import { cognitoSignOut } from "../api/CognitoServices";
import useFetchNotifications from "../utils/NotificationUtils";
import { ConsoleError } from '../utils/ErrorUtils';
import { ERROR_MESSAGE } from '../constants/MessagesConstants';
import CheckOutApproval from './CheckoutApproval';
import { Spinner } from 'react-bootstrap';
import PageNotFound from './PageNotFound';
import {PrintDisable}  from "../utils/PrintDisable"

function ApprovalDetails(props) {
  const [status, setStatus] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  PrintDisable(currentUser?.print_access_flag)
  const [approvalData, setApprovalData] = useState()
  const [actionType, setActionType] = useState()
  const [loader, setLoader] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalData, setModalData] = useState();
  const [elementIndex, setElementIndex] = useState();
  const [comment, setComment] = useState("");

  useEffect(() => {
    setLoader(true);
    const getCurrentApprovalData = (token) => {
      setLoader(true);
      getApprovalDetails(state?.approval_id, token).then((response) => {
        if (response.status === 200) {
          setApprovalData(response?.data ? response?.data : null);
          setActionType(
            response?.data?.action?.action
              ? response?.data?.action?.action
              : response?.data?.action
          );
          setStatus(response?.data?.status?.status);
          setComment(response?.data?.comment);
          setLoader(false);
        }
      });
    };
    getCurrentApprovalData(currentUser?.jwtToken);
  }, [currentUser?.jwtToken, state]);

  const activityType = () => {
    switch (actionType) {
      case "Check In":
        return (
          <CheckInApproval
            data={approvalData}
            action={actionType}
            token={currentUser?.jwtToken}
          />
        );
      case "Force Open":
        return <ForceopenApproval data={approvalData} action={actionType} />;
      case "Ship Content":
        return <ShipContentApproval data={approvalData} action={actionType} />;
      case "Check Out":
        return <CheckOutApproval data={approvalData} action={actionType} />;
      default:
        return <PageNotFound />;
    }
  };

  function handleCloseModal() {
    setIsModelOpen(false);
  }

  const notificationResponse = useFetchNotifications(currentUser?.employee_id, currentUser?.jwtToken);

  function onSubmit(data, comments) {
    Swal.fire({
      title: "Processing, please wait",
      html: "loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    setIsModelOpen(false);
    let payload = UPDATE_APPROVAL_PAYLOAD;
    payload.approved_by = currentUser?.employee_id;
    payload.comment = comments;
    payload.approval_id = data?.data?.approval_id;
    if (data.dynData === "APPROVED") {
      payload.status = "Authorizer Approved";
    } else if (data.dynData === "DECLINED") {
      payload.status = "Authorizer Declined";
    }
    setComment(comments);
    postApproveStatus(payload, currentUser?.jwtToken)
      .then((response) => {
        if (response.status === 401) {
          Swal.close();
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 201 || response.status === 200) {
          Swal.close();
          setStatus(payload.status);
          let status = "Approval Status";
          let title = status;
          let text =
            "Record" +
            " " +
            status +
            " " +
            "successfully, you can check the status in" +
            " home" +
            " " +
            "screen";
          let type = "success";
          let confirmButtonText = "OK";
          let showCancelButton = false;
          notificationResponse.refetch();
          CustomAlert(
            title,
            text,
            type,
            confirmButtonText,
            showCancelButton
          ).then((result) => {});
        } else if (response.status === 500) {
          Swal.close();

          let title = ERROR_MESSAGE;
          let text = "Please try after some time";
          let type = "error";
          let confirmButtonText = "OK";
          let showCancelButton = false;
          CustomAlert(
            title,
            text,
            type,
            confirmButtonText,
            showCancelButton
          ).then((result) => {});
        }
      })
      .catch((error) => {
        Swal.close();
        console.error(error);
      });
  }

  function openApproval(e, index) {
    setIsModelOpen(true);
    setModalTitle("APPROVED");
    setModalData(e);
    setElementIndex(index);
  }
  function openDecline(e, index) {
    setIsModelOpen(true);
    setModalTitle("DECLINED");
    setModalData(e);
    setElementIndex(index);
  }

  return (
    <div className="col-lg-12 col-md-12 col-sm-12  p-0 ">
      <div className="row flex pt-4 pl-4 pr-4 pb-0 ">
        <div className="col-lg-12 pb-3 pl-0 pr-0 ">
          <div className="row pt-1 pb-2">
            <div className="d-flex justify-content-end">
              <div className="p-0">
                <Link to="/viewAllApprovals">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    id="approval-button"
                  >
                    Approvals
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <MDBCard className="card p-0 m-0  ">
            <CustomHeader label={"Approval Details"} />
            <MDBCardBody id="scroll-bar">
              {loader ? (
                <div className="text-center py-5 alignself-center">
                  <Spinner animation="border" />
                </div>
              ) : (
                <>
                  <div
                    className="d-flex py-2"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                  {(status === "Authorizer Approved" || status === "APPROVED") ? (
                      <div className="tableDetails">APPROVED</div>
                    ) : (status === "Authorizer Declined" || status === "DECLINED") ? (
                      <div className="tableDetails">DECLINED</div>
                    ) : (!currentUser?.approval_save_record_access_flag) ? (
                  <div
                    className="tableDetails mr-5"
                    style={{
                      color: "#FFA500",
                      fontWeight: "bold",
                      backgroundColor: "#FFF8E1", 
                      padding: "5px 10px",
                      borderRadius: "5px", 
                      textAlign: "center"  
                    }}
                  >
                    PENDING
                  </div>
                    ) : (
                      <div className="tableDetails" style={{ marginRight: "40px" }}>
                        <button
                          className="btn btn-sm"
                          id="decline-btn"
                          type="button"
                          onClick={() => openDecline(approvalData, 0)}
                        >
                          DECLINE
                        </button>
                        <button
                          className="btn btn-sm"
                          id="approve-btn"
                          type="button"
                          onClick={() => openApproval(approvalData, 0)}
                        >
                          APPROVE
                        </button>
                      </div>
                    )}

                  </div>
                  <div className="row text-center mb-5">
                    <div className="event-div">
                      <div className="d border border-5 event-border h-100 ml-5 mr-5">
                        <div className="row col-lg-12 justify-content-center pt-3 pb-3">
                          {"Event Details"}
                        </div>{" "}
                        <div className="row col-lg-12 col-md-12 col-sm-12 ml-2 pb-3 bs-scroll">
                          <div className={"col-lg-12 col-md-12 col-sm-12 "}>
                            <form className="mt-3">
                              <div className="form-group row">
                                <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                  Approval Status
                                </label>
                                <div className="col-sm-8 p-0">
                                  <div className="float-start">{status}</div>
                                </div>
                              </div>
                              {comment && (
                                <div className="form-group row">
                                  <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                    Authorizer Comments
                                  </label>
                                  <div className="col-sm-8 p-0">
                                    <div className="float-start">{comment}</div>
                                  </div>
                                </div>
                              )}
                              <div className="form-group row">
                                <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                  Tamper Evident Bag#
                                </label>
                                <div className="col-sm-8 p-0">
                                  <div className="float-start">
                                    {
                                      approvalData?.tamper_evident_bag
                                        ?.tamper_evident_bag_number
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                  Activity Type{" "}
                                </label>
                                <div className="col-sm-8 p-0">
                                  <div className="float-start">
                                    {actionType}
                                  </div>
                                </div>
                              </div>
                              {activityType()}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </MDBCardBody>
          </MDBCard>
          <ApprovedModal
            dynData={modalTitle}
            data={modalData}
            IsModalOpened={isModelOpen}
            index={elementIndex}
            onCloseModal={handleCloseModal}
            commentData={comment}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </div>
  );
}

export default ApprovalDetails;
