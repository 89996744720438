import React, { useState, useRef, useEffect } from "react";
import "../style/header.css";
import { SearchService } from "../api/SearchServices";
import { cognitoSignOut } from "../api/CognitoServices";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getRecentData } from "../api/HomeServices";
import { ConsoleError } from "../utils/ErrorUtils";
import { getShipmentData } from "../api/ShipmetServices";
import { getViewApprovalsData } from "../api/ApprovalsService";
import { getViewEnquiryData } from "../api/CreateEnquiryService";
import { getViewAuditData } from "../api/ViewAuditService";
import { getStorageLocationRecords } from "../api/StorageLocationServices";
import { getViewCheckOutData } from "../api/CheckOutService";

function Search(props) {
  let navigate = useNavigate();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const [displayBoxDropdown, setDisplayBoxDropdown] = useState(false);
  const [tamperBag, setTamperBag] = useState();
  const [options, setOptions] = useState([]);
  const [searchButton, setSearchButton] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [tebValue, setTebValue] = useState("");
  const [branchValue, setBranchValue] = useState("");
  const [safedepositValue, setSafeDepositValue] = useState("");
  const [customerName, setCustomerName] = useState("");

  let filterData;
  const inputRef = useRef();
  const homeScreenSearch =
    tebValue.trim().length === 0 &&
    safedepositValue.trim().length === 0 &&
    branchValue.trim().length === 0 &&
    customerName.trim().length === 0;
  useEffect(() => {
    if (homeScreenSearch && props?.screen === "Home") {
      props.getRecentActivities();
    }
  }, [tebValue, safedepositValue, branchValue, customerName]);

  const selectTamperBag = () => {
    setTamperBag(searchValue);
    if (searchValue) {
      if (props.screen === "Approvals") {
        getViewApprovalsData(
          props.approvalType,
          props.pageSize,
          currentUser?.employee_id,
          currentUser?.jwtToken,
          1,
          searchValue
        )
          .then((response) => {
            if (response.status === 401) {
              cognitoSignOut()
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  ConsoleError(error);
                });
            } else if (response?.status === 200) {
              filterData = response?.data?.approvals;
              props.filterData && props.filterData({ filterData });
            }
          })
          .catch((error) => {
            ConsoleError(error);
            props.filterData && props.filterData({});
          });
      } else if (props.screen === "ViewStorageLocation") {
        getStorageLocationRecords(
          searchValue,
          1,
          props.pageSize,
          currentUser?.jwtToken
        )
          .then((response) => {
            if (response.status === 401) {
              cognitoSignOut()
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  ConsoleError(error);
                });
            } else if (response?.status === 200) {
              filterData = response?.data;
              props.filterData && props.filterData({...filterData ,searchValue});
            }
          })
          .catch((error) => {
            ConsoleError(error);
            props.filterData && props.filterData({});
          });
      } else if (props.screen === "Home") {
        getRecentData(
          currentUser?.employee_id,
          1,
          props.pageSize,
          currentUser?.jwtToken,
          safedepositValue.trim(),
          branchValue.trim(),
          tebValue.trim(),
          customerName.split(" ").join("")
        )
          .then((response) => {
            if (response.status === 401) {
              cognitoSignOut()
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  ConsoleError(error);
                });
            } else if (response?.status === 200) {
              filterData = response?.data;
              props.filterData && props.filterData({ filterData });
            }
          })
          .catch((error) => {
            ConsoleError(error);
            props.filterData && props.filterData({});
          });
      } else if (props.screen === "Shipment") {
        getShipmentData(
          searchValue,
          1,
          props.pageSize,
          currentUser?.employee_id,
          currentUser?.jwtToken
        )
          .then((response) => {
            if (response.status === 401) {
              cognitoSignOut()
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  ConsoleError(error);
                });
            } else if (response.status === 200) {
              filterData = response?.data;
              props.filterData && props.filterData( {...filterData,searchValue} );
            }
          })
          .catch((error) => {
            ConsoleError(error);
            props.filterData && props.filterData({});
          });
      } else if (props.screen === "Enquiries") {
        getViewEnquiryData(
          searchValue,
          1,
          props.pageSize,
          currentUser?.employee_id,
          currentUser?.jwtToken
        )
          .then((response) => {
            if (response.status === 401) {
              cognitoSignOut()
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  ConsoleError(error);
                });
            } else if (response.status === 200) {
              filterData = response?.data;
              props.filterData && props.filterData({ ...filterData,searchValue });
            }
          })
          .catch((error) => {
            ConsoleError(error);
            props.filterData && props.filterData({});
          });
      } else if (props.screen === "Audit") {
        getViewAuditData(
          searchValue,
          1,
          props.pageSize,
          currentUser?.employee_id,
          currentUser?.jwtToken
        )
          .then((response) => {
            if (response.status === 401) {
              cognitoSignOut()
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  ConsoleError(error);
                });
            } else if (response.status === 200) {
              filterData = response?.data;
              props.filterData && props.filterData( {...filterData,searchValue} );
            }
          })
          .catch((error) => {
            ConsoleError(error);
            props.filterData && props.filterData({});
          });
      } else if (props.screen === "Checkin") {
        SearchService(
          "check-in",
          1,
          props.pageSize,
          searchValue,
          currentUser?.jwtToken
        )
          .then((response) => {
            if (response.status === 401) {
              cognitoSignOut()
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  ConsoleError(error);
                });
            } else if (response.status === 200) {
              filterData = response?.data
              props.filterData && props.filterData( {...filterData,searchValue} );
            }
          })
          .catch((error) => {
            ConsoleError(error);
            props.filterData && props.filterData({});
          });
      } else if (props.screen === "Checkout") {
        props.setSearchValue(searchValue)
        getViewCheckOutData(
          searchValue,
          1,
          props.pageSize,
          currentUser?.employee_id,
          currentUser?.jwtToken
        )
          .then((response) => {
            if (response.status === 401) {
              cognitoSignOut()
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  ConsoleError(error);
                });
            } else if (response.status === 200) {
              filterData = response?.data;
              props.filterData && props.filterData( {...filterData,searchValue} );
            }
          })
          .catch((error) => {
            ConsoleError(error);
            props.filterData && props.filterData({});
          });
      }
    }
  };
  const onSearch = (event) => {
    setDisplayBoxDropdown(true);
    if (searchValue === "") {
      props.filterData && props.filterData();
      setOptions([]);
      setDisplayBoxDropdown(false);
    } else {
      selectTamperBag();
    }
  };

  const onInputChange = (event) => {
    if (event.target.value.trim().length > 0) {
      setSearchButton(false);
      setSearchValue(event.target.value);
    } else {
      setSearchButton(true);
      props.filterData && props.filterData( {...filterData,searchValue:""} ); 
      if (
        event.target.value.trim().length === 0 &&
        props?.screen === "ViewStorageLocation"
      ) {
        props.getLocations();
      }
      if (props?.screen === "Enquiries") {
        props?.getEnquiry();
      }
    }
  };
  const onApprovalInputChange = (event) => {
    if (event.target.value.trim().length > 0) {
      setSearchButton(false);
      props?.setSearchValueApproval(event.target.value);
    } else {
      props?.setSearchValueApproval("");
      setSearchButton(true);
    }
  };

  const onProductInputChange = (event) => {
    if (event.target.value.trim().length > 0) {
      setSearchButton(false);
      props?.setSearchValueProduct(event.target.value);
    } else {
      props?.setSearchValueProduct("");
      setSearchButton(true);
    }
  };
  const onPreOpenInputChange = (event) => {
    if (event.target.value.trim().length > 0) {
      setSearchButton(false);
      props?.setSearchValuePreOpen(event.target.value);
    } else {
      props?.setSearchValuePreOpen("");
      setSearchButton(true);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between" >
        {props.screen === "Home" ? (
          <div className="d-flex justify-content-between" style={{width:"90%"}}>
            <div className=" pr-0 p-0" style={{width:"24%"}} >
              {" "}
              <input
                
                id="search-bar"
                className="form-control"
                placeholder="Tamper Bag#"
                autoComplete="off"
                ref={inputRef}
                onChange={(event) => {
                  onInputChange(event);
                  setTebValue(event.target.value);
                }}
                value={tebValue}
              />
              <i
                className="fa fa-search input-search-icon pl-3"
                aria-hidden="true"
              ></i>
              <i
                id="barcode"
                className="fa fa-barcode input-scanner-icon float-right pr-3 h2 "
                aria-hidden="true"
                title="Scan Tamper Evident Bag"
              ></i>
            </div>
            <div className=" pr-0  p-0" style={{width:"24%"}}>
              <input
                
                id="search-bar"
                className="form-control"
                placeholder="Safe Deposit Box Number"
                autoComplete="off"
                ref={inputRef}
                onChange={(event) => {
                  onInputChange(event);
                  setSafeDepositValue(event.target.value);
                }}
              />
              <i
                className="fa fa-search input-search-icon pl-3"
                aria-hidden="true"
              ></i>
            </div>
            <div className=" pr-0  p-0" style={{width:"24%"}}>
              <input
                
                id="search-bar"
                className="form-control"
                placeholder="Branch"
                autoComplete="off"
                ref={inputRef}
                onChange={(event) => {
                  onInputChange(event);
                  setBranchValue(event.target.value);
                }}
              />
              <i
                className="fa fa-search input-search-icon pl-3"
                aria-hidden="true"
              ></i>
            </div>
            <div className=" pr-0  p-0" style={{width:"24%"}}>
              <input
              
                id="search-bar"
                className="form-control"
                placeholder="Customer Name"
                autoComplete="off"
                ref={inputRef}
                onChange={(event) => {
                  onInputChange(event);
                  setCustomerName(event.target.value);
                }}
              />
              <i
                className="fa fa-search input-search-icon pl-3"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        ) : props?.screen === "Approvals" ? (
          <div className="col-md-8 col-sm-12">
            <input
              id="search-bar"
              className="form-control pr-0"
              placeholder={"Search by Tamper Bag #"}
              autoComplete="off"
              ref={inputRef}
              onChange={(e) => onApprovalInputChange(e)}
            />
            <i
              className="fa fa-search input-search-icon pl-3"
              aria-hidden="true"
            ></i>

            {props.screen !== "ViewStorageLocation" && (
              <i
                className="fa fa-barcode float-right pr-3 input-scanner-icon h2"
                aria-hidden="true"
                title="Scan Tamper Evident Bag"
              ></i>
            )}
          </div>
        ) : props?.screen === "Product" ? (
          <div className="col-md-8 col-sm-12">
            <input
              id="search-bar"
              className="form-control pr-0"
              placeholder={"Search by Item Name"}
              autoComplete="off"
              ref={inputRef}
              onChange={(e) => onProductInputChange(e)}
            />
            <i
              className="fa fa-search input-search-icon pl-3"
              aria-hidden="true"
            ></i>

            {props.screen !== "ViewStorageLocation" &&
              props.screen !== "Product" && (
                <i
                  className="fa fa-barcode float-right pr-3 input-scanner-icon h2"
                  aria-hidden="true"
                  title="Scan Tamper Evident Bag"
                ></i>
              )}
          </div>
        ) : props?.screen === "PreOpen" ? (
          <div className="col-md-8 col-sm-12">
            <input
              id="search-bar"
              className="form-control pr-0"
              placeholder={"Search by Item Name"}
              autoComplete="off"
              ref={inputRef}
              onChange={(e) => onPreOpenInputChange(e)}
            />
            <i
              className="fa fa-search input-search-icon pl-3"
              aria-hidden="true"
            ></i>

            {props.screen !== "ViewStorageLocation" &&
              props.screen !== "Product" && (
                <i
                  className="fa fa-barcode float-right pr-3 input-scanner-icon h2"
                  aria-hidden="true"
                  title="Scan Tamper Evident Bag"
                ></i>
              )}
          </div>
        ) : (
          <div className="col-md-8 col-sm-12">
            <input
              id="search-bar"
              className="form-control pr-0"
              placeholder={(() => {
                if (props.screen === "Home") {
                  return "Search by Tamper Bag # or Owner's Name # or Branch";
                } else if (props.screen === "ViewStorageLocation") {
                  return "Search by Storage Location Name";
                } else {
                  return "Search by Tamper Bag #";
                }
              })()}
              autoComplete="off"
              ref={inputRef}
              onChange={onInputChange}
            />

            {props.screen !== "ViewStorageLocation" && (
              <i
                className="fa fa-barcode float-right pr-3 input-scanner-icon h2"
                aria-hidden="true"
                title="Scan Tamper Evident Bag"
              ></i>
            )}
          </div>
        )}

        {props.screen === "Home" ? (
          <div className=" searchButton">
            <button
              type="file"
              className="btn btn-sm btn-primary m-0"
              onClick={onSearch}
              disabled={homeScreenSearch}
            >
              Search
            </button>
          </div>
        ) : props.screen === "Approvals" ? (
          <div className="col-md-2 col-sm-12 searchButton">
            <button
              type="file"
              className="btn btn-sm btn-primary m-0"
              onClick={() => props?.onClickSearch()}
              disabled={searchButton}
            >
              Search
            </button>
          </div>
        ) : props.screen === "Product" ? (
          <div className="col-md-2 col-sm-12 searchButton">
            <button
              type="file"
              className="btn btn-sm btn-primary m-0"
              onClick={() => {
                props?.onClickSearch();
              }}
              disabled={searchButton}
            >
              Search
            </button>
          </div>
        ) : props.screen === "PreOpen" ? (
          <div className="col-md-2 col-sm-12 searchButton">
            <button
              type="file"
              className="btn btn-sm btn-primary m-0"
              onClick={() => {
                props?.onClickSearch();
              }}
              disabled={searchButton}
            >
              Search
            </button>
          </div>
        ) : (
          <div className="col-md-2 col-sm-12 searchButton">
            <button
              type="file"
              className="btn btn-sm btn-primary m-0"
              onClick={onSearch}
              disabled={searchButton}
            >
              Search
            </button>
          </div>
        )}
      </div>
      {tamperBag !== null &&
        tamperBag !== "" &&
        displayBoxDropdown &&
        !searchButton && (
          <div
            id="results"
            className="list-group"
            style={{ position: "absolute" }}
          >
            {options.map((item, key) => {
              return (
                <div
                  onClick={(e) => {
                    inputRef.current.value =
                      props.screen === "ViewStorageLocation" ||
                      props.screen === "Approvals"
                        ? item
                        : item.bag;
                    selectTamperBag(
                      props.screen === "ViewStorageLocation" ||
                        props.screen === "Approvals"
                        ? item
                        : item.bag
                    );
                  }}
                  key={key}
                >
                  {(() => {
                    if (
                      props.screen === "ViewStorageLocation" ||
                      props.screen === "Approvals"
                    ) {
                      return (
                        <button
                          type="button"
                          className="list-group-item list-group-item-action"
                        >
                          {item}
                        </button>
                      );
                    } else {
                      return (
                        <button
                          type="button"
                          className="list-group-item list-group-item-action"
                        >
                          {item.bag} &nbsp;{item.name ? item.name : null}&nbsp;
                          {item.branch ? item.branch : null}
                        </button>
                      );
                    }
                  })()}
                </div>
              );
            })}
          </div>
        )}
    </>
  );
}
export default Search;
