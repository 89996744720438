import { MDBCard, MDBCardBody } from "mdbreact";
import { Link } from "react-router-dom";
import "../style/singlecheckin.css";
import React, { useState, useEffect } from "react";
import { WebcamCapture } from "../components/webcamComponent";
import { getActionList } from "../api/ActionService";
import { useNavigate } from "react-router-dom";
import DocumentHorizontal from "../components/DocumentHorizontal";
import CustomHeader from "../components/CustomHeader";
import Swal from "sweetalert2";
import SearchPanel from "../components/SearchPanel";
import { getStorageListOnsearch } from "../api/StorageListService";
import { getSubStorageListOnsearch } from "../api/SubStorageListService";
import { getTamperEvidentBags } from "../api/CheckInServices";
import { saveSingleCheckin } from "../api/CheckInServices";

import { getEmployeesOnsearch } from "../api/EmployeeService";
import { CustomAlert } from "../components/customAlert";
import PerformedBy from "../components/PerformedBy";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ConsoleError } from "../utils/ErrorUtils";
import { fetchStorageOptions } from "../utils/StorageManagement";
import uuid from "react-uuid";
import ConfigData from "../config";

import {
  CHECKIN_TEB_LIMIT,
  SAVE_CHECKIN_PAYLOAD,
} from "../constants/CheckInConstants";
import { SearchSelectedListTable } from "../components/SearchSelectedListTable";
import { getSuggestions, getStorageLocationRecords } from "../api/StorageLocationServices";
import { getAuthorizationDataModule } from "../api/AuthorizationConfigurationService";
import { MODULES } from "../constants/Constants";
import Select from 'react-select';
import { useDebounce } from "../utils/useDebounce";
function SingleCheckIn() {
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    tamper_evident_bag: "",
    storage_location: "",
    storage_unit: "",
    safe_deposit_box_number: "",
    storage_location_id: "",
    storage_type: "",
    tamperevidentbag_id: "",
    checkin_notes: "",
    safeDepositId: "",
    employee_name: "",
    employee_designation: "",
    employee_id: "",
  });
  const [pictureList, setPictureList] = useState([]);
  const [storageTypeList, setStorageTypeList] = useState([]);
  const [storageType, setStorageType] = useState("");
  const [storageList, setStorageList] = useState([]);
  const [storageLocation, setStorageLocation] = useState([]);

  const [isShowBranch, setIsShowBranch] = useState(false);
  const [branchOptionList, setBranchOptionList] = useState([]);
  const [branchList, setBranchList] = useState([]);

  const [unitOptionList, setUnitOptionList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [showUnitOptions, setShowUnitOptions] = useState(true);
  const [unitSearchString, setUnitSearchString] = useState("");
  const [isUnitNameAssigned, setUnitNameAssigned] = useState(false);
  const [unitId, setUnitId] = useState("");

  const [aisleOptionList, setAisleOptionList] = useState([]);
  const [aisleList, setAisleList] = useState([]);
  const [showAisleOptions, setShowAisleOptions] = useState(true);
  const [aisleSearchString, setAisleSearchString] = useState("");
  const [isAisleNameAssigned, setAisleNameAssigned] = useState(false);
  const [aisleId, setAisleId] = useState("");

  const [bayOptionList, setBayOptionList] = useState([]);
  const [bayList, setBayList] = useState([]);
  const [showBayOptions, setShowBayOptions] = useState(true);
  const [baySearchString, setBaySearchString] = useState("");
  const [isBayNameAssigned, setBayNameAssigned] = useState(false);
  const [bayId, setBayId] = useState("");

  const [shelfOptionList, setShelfOptionList] = useState([]);
  const [shelfList, setShelfList] = useState([]);
  const [showShelfOptions, setShowShelfOptions] = useState(true);
  const [shelfSearchString, setShelfSearchString] = useState("");
  const [isShelfNameAssigned, setShelfNameAssigned] = useState(false);
  const [shelfId, setShelfId] = useState("");

  const [toteOptionList, setToteOptionList] = useState([]);
  const [toteList, setToteList] = useState([]);
  const [showToteOptions, setShowToteOptions] = useState(true);
  const [toteSearchString, setToteSearchString] = useState("");
  const [isToteNameAssigned, setToteNameAssigned] = useState(false);
  const [toteId, setToteId] = useState("");

  const [tebOptionList, setTebOptionList] = useState([]);
  const [tebList, setTebList] = useState([]);
  const [showTebOptions, setShowTebOptions] = useState(true);
  const [tebSearchString, setTebSearchString] = useState("");
  const [isTebNameAssigned, setTebNameAssigned] = useState(false);

  const [isShowVault, setIsShowVault] = useState(false);
  const [isShowWarehouse, setIsWarehouse] = useState(false);
  const [searchPanelLabel, setSearchPanelLabel] = useState("");
  const [searchPanelPlaceholder, setSearchPanelPlaceholder] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const activityType = "Ship Content";
  //eslint-disable-next-line
  const [safeDepositBox, setSafeDepositBox] = useState("");
  //eslint-disable-next-line
  const [safeDepositBoxId, setSafeDepositBoxId] = useState("");
  const [isModelEnabled, setIsModelEnabled] = useState(false);
  //eslint-disable-next-line
  const [storageLocId, setStorageLocId] = useState("");
  const [storageLocationType, setStorageLocationType] = useState("");
  const [checkinNotes, setCheckinNotes] = useState("");
  const [vaultId, setVaultId] = useState("");
  const [warehouseId, setWarehouseId] = useState("");
  //eslint-disable-next-line
  const [showOptions, setShowOptions] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [isStorageNameAssigned, setStorageNameAssigned] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [options, setOptions] = useState([]);

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const [employeeOptionList, setEmployeeOptionList] = useState([]);

  const [employeeList, setEmployeeList] = useState([]);

  const [authorizerShowOptions, setAuthorizerShowOptions] = useState(true);
  const [authorizerSearchString, setAuthorizerSearchString] = useState("");
  const [isAuthorizerAssigned, setIsAuthorizerAssigned] = useState(false);
  const [selectedTebList, setSelectedTebList] = useState([]);
  const [selectedAuthorizerList, setSelectedAuthorizerList] = useState([]);
  const [lastTimelineStatus, setLastTimelineStatus] = useState();
  const [warehouseToteDetails, setIsWarehouseToteDetails] = useState([]);

  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsList, setSuggestionsList] = useState([]);
  //eslint-disable-next-line
  const [formData, setFormData] = useState({
    step3: {
      Documentrows: [],
      filename: "",
      comments: "",
    },
  });

  const [isAuthorization, setIsAuthorization] = useState(false);
  const [page, setPage] = useState(1);

  let navigate = useNavigate();
  const onClickDeleteTEB = (item, index) => {
    const newSelectedTebList = [...selectedTebList]; // make a copy of the original array
    newSelectedTebList.splice(index, 1); // remove the element at the specified index
    setSelectedTebList(newSelectedTebList);
    setIsWarehouseToteDetails((prevData) => {
      return prevData.filter((value) => {
        return value.teb_id !== item?.tamper_evident_bag_id;
      });
    });
  };

  const onClickDeleteAuthorizer = (item, index) => {
    const newSelectedAuthorizerList = [...selectedAuthorizerList]; // make a copy of the original array
    newSelectedAuthorizerList.splice(index, 1); // remove the element at the specified index
    setSelectedAuthorizerList(newSelectedAuthorizerList);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    formValues[name] = value;
    setShowOptions(false);
    if (formErrors[name] !== undefined && formErrors[name].length > 0) {
      delete formErrors[name];
    }
    if (name === "checkin_notes") {
      setCheckinNotes(value);
    } else if (name === "storage_type") {
      delete formErrors["storage_location_id"];
      setSearchString("");
      setBranchDid("");
      //TO-DO see how to decouple with storage type id
      if (e.target.value === "81e44f48-b167-49a3-8890-bdb758506e10") {
        setIsShowBranch(true);
        setIsShowVault(false);
        setIsWarehouse(false);
        setStorageLocationType("branch");
        setSearchPanelLabel("Branch Name");
        setSearchPanelPlaceholder("Search by Branch Name or DID");
      } else if (e.target.value === "892763eb-b360-43fb-b020-5cc3c80baebd") {
        setIsShowBranch(false);
        setIsShowVault(false);
        setIsWarehouse(true);
        setStorageLocationType("warehouse");
        setSearchPanelLabel("Warehouse Name");
        setSearchPanelPlaceholder("Search by Warehouse Name");
      } else if (e.target.value === "23137f9a-6bcb-4796-b456-d90394cd251a") {
        setIsShowBranch(false);
        setIsShowVault(true);
        setIsWarehouse(false);
        setStorageLocationType("vault");
        setSearchPanelLabel("Vault Name");
        setSearchPanelPlaceholder("Search by Vault Name");
      }
    }
  };
  function resetErrors() {
    setFormErrors({});
  }

  const validate = (values) => {
    resetErrors();
    const errors = {};
    if (selectedTebList.length < 1) {
      errors.teb_id = "TEB is required";
    }
    if (selectedAuthorizerList.length < 1) {
      errors.authorizer = "Authorizer is required";
    }

    if(storageType ==""){
      errors.storage_type="Storage Type is required";
    }
    if (values.storage_type !== "") {
      if (values.storage_type === "branch") {
        if (!isStorageNameAssigned) {
          errors.storage_location_id = "Branch Name is required";
        }
      } else if (values.storage_type === "warehouse") {
        if (!isStorageNameAssigned) {
          errors.storage_location_id = "Warehouse Name is required";
        } else {
            warehouseToteDetails?.forEach((tote) => {
              const tebErrors = {}; // Specific errors for the current TEB
            
              // Tote validations
              if (!tote?.tote_id || tote?.tote_id === "") {
                tebErrors.tote_id = "Tote is required";
              }
              if (!tote?.tote_name || tote?.tote_name === "") {
                tebErrors.tote_name = "Tote Name is required";
              }
              if (tote?.tote_name && !tote?.tote_id) {
                tebErrors.tote_name = "Tote does not exist";
              }
            
              // Aisle validations
              if (!tote?.aisle_id || tote?.aisle_id === "") {
                tebErrors.aisle_id = "Aisle is required";
              }
              if (!tote?.aisle_name || tote?.aisle_name === "") {
                tebErrors.aisle_name = "Aisle Name is required";
              }
              if (tote?.aisle_name && !tote?.aisle_id) {
                tebErrors.aisle_name = "Aisle does not exist";
              }
            
              // Bay validations
              if (!tote?.bay_id || tote?.bay_id === "") {
                tebErrors.bay_id = "Bay is required";
              }
              if (!tote?.bay_name || tote?.bay_name === "") {
                tebErrors.bay_name = "Bay Name is required";
              }
              if (tote?.bay_name && !tote?.bay_id) {
                tebErrors.bay_name = "Bay does not exist";
              }
            
              // Shelf validations
              if (!tote?.shelf_id || tote?.shelf_id === "") {
                tebErrors.shelf_id = "Shelf is required";
              }
              if (!tote?.shelf_name || tote?.shelf_name === "") {
                tebErrors.shelf_name = "Shelf Name is required";
              }
              if (tote?.shelf_name && !tote?.shelf_id) {
                tebErrors.shelf_name = "Shelf does not exist";
              }
            
              // Unit validations
              if (!tote?.unit_id || tote?.unit_id === "") {
                tebErrors.unit_id = "Unit is required";
              }
              if (!tote?.unit_name || tote?.unit_name === "") {
                tebErrors.unit_name = "Unit Name is required";
              }
              if (tote?.unit_name && !tote?.unit_id) {
                tebErrors.unit_name = "Unit does not exist";
              }
            
              // Add errors for this TEB only if there are validation issues
              if (Object.keys(tebErrors).length > 0) {
                errors[tote.teb_id] = tebErrors;
              }
            });
        }
      } else if (values.storage_type === "vault") {
        if (!isStorageNameAssigned) {
          errors.storage_location_id = "Vault Name is required";
        }
      }
    } else {
      errors.storage_type = "Storage Type is required";
    }

    return errors;
  };

  const setBranchDid = (branch_did) => {
    setFormValues({ ...formValues, branch_did: branch_did });
  };

  const onCapturePicture = (picture, itemId) => {
    let addedImageList = [...pictureList, { src: picture, typeId: itemId }];
    setPictureList(addedImageList);
    setIsModelEnabled(false);
  };

  const OnDeletePicture = (index, itemId) => {
    let shallowCopyImageList = Array.from(pictureList);
    if (index > -1) {
      shallowCopyImageList.splice(index, 1);
    }
    setPictureList(shallowCopyImageList);
  };

  const getAuthorizationFlag = async () => {
    try {
      const response = await getAuthorizationDataModule(
        MODULES.CHECK_IN,
        currentUser?.jwtToken
      );
      if (response?.status === 200) {
        setIsAuthorization(response?.data?.authorization_flag);
        if (!response?.data?.authorization_flag) {
          setSelectedAuthorizerList([
            { employee_id: response?.data?.authorization?.employee_id },
          ]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getActionList("StorageType", currentUser?.jwtToken)
      .then((response) => {
        setStorageTypeList(
          response?.map(function (storageTypeRow) {
            return storageTypeRow;
          })
        );
      })
      .catch((error) => {
        ConsoleError(error);
      });
    getAuthorizationFlag();
  }, [currentUser?.jwtToken]);

  const saveAlert = (text, type, input) => {
    let title = "Saved";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      target
    ).then((result) => {
      if (result.value) {
        navigate("/viewAllCheckIn");
        if (input === "tote") {
        }
      }
    });
  };
  const failAlert = (text, type) => {
    let title = "Failed";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      target
    ).then((result) => {
      if (result.value) {
      }
    });
  };

  const onClose = (e) => {
    e.preventDefault();
    navigate("/viewAllCheckIn");
  };

  function save(payload) {
    saveSingleCheckin(payload, currentUser?.employee_id, currentUser?.jwtToken)
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((err) => {
              console.error(err);
            });
        } else if (
          response !== undefined &&
          (response.status === 201 || response.status === 200)
        ) {
          Swal.close();
          let type = "success";
          let text = "Checkin Created Successfully";

          saveAlert(text, type, "branch");
        } else {
          let type = "fail";
          let text = "Failed to save single checkin";
          failAlert(text, type);
        }
      })
      .catch((err) => {
        Swal.close();
        console.error(err);
        let type = "fail";
        let text = "failed to Add Bags into Units";
        failAlert(text, type);
      });
  }

  function getSubStorageList(type, id, searchStr) {
    getSubStorageListOnsearch(id, type, searchStr, currentUser?.jwtToken)
      .then((response) => {
        // TO-DO handle no data and update variable names as generic
        switch (type) {
          case "warehouse":
            setUnitList(response);
            setUnitOptionList(
              response?.map(function (row) {
                return row?.unit_name;
              })
            );
            break;

          case "unit":
            setAisleList(response);
            setAisleOptionList(
              response?.map(function (row) {
                return row?.aisle_number;
              })
            );
            break;
          case "aisle":
            setBayList(response);
            setBayOptionList(
              response?.map(function (row) {
                return row?.bay_number;
              })
            );
            break;
          case "bay":
            setShelfList(response);
            setShelfOptionList(
              response?.map(function (row) {
                return row?.shelf_number;
              })
            );
            break;
          case "shelf":
            setToteList(response);
            setToteOptionList(
              response?.map(function (row) {
                return row?.tote_number;
              })
            );
            break;
          case "tote":
            setToteList(response);
            response?.map(function (row) {
              return row?.tote_number;
            });
            break;
          default:
            return null;
        }
      })
      .catch((err) => {
        ConsoleError(err);
      });
  }

  function getStorageList() {
    const storage_search_type = isShowBranch
      ? "branch"
      : isShowVault
      ? "vault"
      : "warehouse";
    getStorageListOnsearch(
      storage_search_type,
      searchString,
      currentUser?.jwtToken
    )
      .then((response) => {
        // TO-DO handle no data and update variable names as generic
        setBranchList(response);
        setBranchOptionList(
          response?.map(function (branchRow) {
            return (
              (branchRow.name ? branchRow.name : "") +
              (branchRow.branch_did ? " - " + branchRow.branch_did : "")
            );
          })
        );
      })
      .catch((err) => {
        ConsoleError(err);
      });
  }
  const debouncedTEBSearchQuery = useDebounce(tebSearchString, 500);


  function getTebList() {
    getTamperEvidentBags(
      activityType,
      debouncedTEBSearchQuery,
      1,
      CHECKIN_TEB_LIMIT,
      currentUser?.jwtToken,
      storageLocation?.value,
      storageLocation?.storageType,
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((err) => {
              console.error(err);
            });
        } else if (response?.status === 200) {
          setTebList(response?.data);
          setTebOptionList(
            response?.data
              ?.filter(
                (row) =>
                  !selectedTebList.some(
                    (selectedTeb) =>
                      selectedTeb.tamper_evident_bag_id ===
                      row.tamper_evident_bag_id
                  )
              )
              .map(function (teb) {
                return teb?.tamper_evident_bag_number;
              })
          );
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  }
    const debouncedEmployeeSearchQuery = useDebounce(authorizerSearchString, 500);

  const getEmployeesBySearch = () => {
    const approvalAccessFlag = true;
    const authorizerAcessFlag = null;
    const auditAccessFlag = null;
    getEmployeesOnsearch(
      approvalAccessFlag,
      authorizerAcessFlag,
      auditAccessFlag,
      debouncedEmployeeSearchQuery,
      currentUser?.jwtToken
    )
      .then((response) => {
        response = response?.data;
        setEmployeeList(response);
        setEmployeeOptionList(
          response
            .filter(
              (employee) => employee.employee_id !== currentUser?.employee_id
            )
            .filter(
              (row) =>
                !selectedAuthorizerList.some(
                  (authorizer) => authorizer.employee_id === row.employee_id
                )
            )
            .map(function (emp) {
              return (
                (emp.employee_name ? emp.employee_name : "") +
                (emp.employee_email ? " - " + emp.employee_email : "") +
                (emp.employee_org_id ? " - " + emp.employee_org_id : "")
              );
            })
        );
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };
  useEffect(() => {
    const latest_record = selectedTebList[0]?.activity_list
      ?.filter((activity) => activity.event === "shipment") // Get all check_in activities
      ?.sort(
        (a, b) => new Date(b.create_datetime) - new Date(a.create_datetime)
      )?.[0]?.information?.to_location; // Sort them by create_datetime in descending order
    setLastTimelineStatus(latest_record);
    setStorageNameAssigned(true);
    setWarehouseId(latest_record?.warehouse_id);
    setVaultId(latest_record?.vault_id);
    if (latest_record?.storage_type === "Branch") {
      setStorageLocationType("branch");
      setIsShowBranch(true);
    } else if (latest_record?.storage_type === "Warehouse") {
      setStorageLocationType("warehouse");
      setIsWarehouse(true);
    } else if (latest_record?.storage_type === "Vault") {
      setStorageLocationType("vault");
      setIsShowVault(true);
    }
  }, [selectedTebList]);

  useEffect(() => {
    if (!isUnitNameAssigned && unitSearchString.length > 0) {
      setStorageLocationType("warehouse");
      getSubStorageList("warehouse", warehouseId, unitSearchString);
    }
    //eslint-disable-next-line
  }, [unitSearchString]);
  useEffect(() => {
    if (!isAisleNameAssigned && aisleSearchString.length > 0) {
      getSubStorageList("unit", unitId, aisleSearchString);
    }
    //eslint-disable-next-line
  }, [aisleSearchString]);
  useEffect(() => {
    if (!isBayNameAssigned && baySearchString.length > 0) {
      getSubStorageList("aisle", aisleId, baySearchString);
    }

    //eslint-disable-next-line
  }, [baySearchString]);
  useEffect(() => {
    if (!isShelfNameAssigned && shelfSearchString.length > 0) {
      getSubStorageList("bay", bayId, shelfSearchString);
    }

    //eslint-disable-next-line
  }, [shelfSearchString]);
  useEffect(() => {
    if (!isToteNameAssigned && toteSearchString.length > 0) {
      getSubStorageList("shelf", shelfId, toteSearchString);
    }
    //eslint-disable-next-line
  }, [toteSearchString]);

  useEffect(() => {
    if (!isToteNameAssigned && toteSearchString.length > 0) {
      getSubStorageList("tote", toteId);
    }
    //eslint-disable-next-line
  }, [toteSearchString]);

  useEffect(() => {
    if ( debouncedTEBSearchQuery.length > 0) {
      getTebList();
    }
    //eslint-disable-next-line
  }, [debouncedTEBSearchQuery]);

  useEffect(() => {
    if ( debouncedEmployeeSearchQuery.length > 0) {
      getEmployeesBySearch();
    }
    //eslint-disable-next-line
  }, [debouncedEmployeeSearchQuery]);

  function getBranchId() {
    let branchSelected = {};
    branchSelected = branchList.find((item) => {
      return item.branch_did === formValues.branch_did;
    });
    return branchSelected?.branch_id;
  }

  function showProcessing() {
    Swal.fire({
      title: "Processing, please wait",
      html: "loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
  }

  function populateSaveCheckInPayload() {
    let payload = SAVE_CHECKIN_PAYLOAD;
    payload.safe_deposit_box_id = safeDepositBoxId;
    payload.storage_type = storageLocationType;
    payload.storage_location_id = "";
    payload.notes = checkinNotes;
    payload.pictures = pictureList;
    payload.documents = documentList;
    payload.performed_by_id = currentUser?.employee_id;
    payload.temp_tote = "";
    payload.temp_location = "";
    payload.activity_type = activityType;
    payload.tamper_evident_bags = selectedTebList.map(
      (teb) => teb.tamper_evident_bag_id
    );
    payload.authorizer_id = selectedAuthorizerList.map(
      (authorizer) => authorizer.employee_id
    );
    if (storageLocationType === "warehouse") {
      payload.storage_location_id = warehouseId;
      payload.tote_details = warehouseToteDetails?.map((tote) => {
        return { tote};
      });
    } else if (storageLocationType === "branch") {
      payload.storage_location_id = lastTimelineStatus.branch_id;
    } else if (storageLocationType === "vault") {
      payload.storage_location_id = vaultId;
    }
    return payload;
  }
  const onSubmit = () => {
    let payload = populateSaveCheckInPayload();
    let errors = validate(payload);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      showProcessing();
      save(payload);
    } else {
      return false;
    }
  };

  /**
   * Function to handle updates the comments made to the document
   */
  const handleUpdateDocumentComment = (documentId, value) => {
    let shallowCopyDocumentList = Array.from(documentList);
    const docIndex = shallowCopyDocumentList.findIndex(
      (doc) => doc.documentId === documentId
    );
    shallowCopyDocumentList[docIndex].comments = value;
    setDocumentList(shallowCopyDocumentList);
  };

  /**
   * Function to read files & set to props
   */
  const handleAddDocument = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        const docSrcArray = reader.result.split(";")[0];
        let val = document.getElementById("input_document");
        let file = val.files.item(0).name;
        const documentItem = {
          documentId: uuid(),
          comments: "",
          src: reader.result,
          docType: docSrcArray,
          fileName: file,
        };
        handleNewDocument(documentItem);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  /**
   * Function to handle new documents
   */
  const handleNewDocument = (document) => {
    let addedDocumentList = [...documentList, document];
    setDocumentList(addedDocumentList);
  };
  /**
   * Function to handle delete documents
   */
  const handleDeleteDocument = (documentId) => {
    let shallowCopyDocList = Array.from(documentList);
    const docIndex = shallowCopyDocList.findIndex(
      (doc) => doc.documentId === documentId
    );

    if (docIndex > -1) {
      shallowCopyDocList.splice(docIndex, 1);
    }
    setDocumentList(shallowCopyDocList);
  };

  const handleInputChange = async (parentId, unitType, searchText) => {
    getSuggestions(parentId, unitType, searchText, currentUser?.jwtToken).then(
      (response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          if (response?.data !== undefined && response?.status === 200) {
            if (unitType === "Unit") {
              setSuggestions(response?.data?.units);
              setSuggestionsList(
                response?.data?.units?.map((item) => {
                  return item?.unit_name;
                })
              );
            } else if (unitType === "Aisle") {
              setSuggestions(response?.data?.aisles);
              setSuggestionsList(
                response?.data?.aisles?.map((item) => {
                  return item?.aisle_number;
                })
              );
            } else if (unitType === "Bay") {
              setSuggestions(response?.data?.bays);
              setSuggestionsList(
                response?.data?.bays?.map((item) => {
                  return item?.bay_number;
                })
              );
            } else if (unitType === "Shelf") {
              setSuggestions(response?.data?.shelves);
              setSuggestionsList(
                response?.data?.shelves?.map((item) => {
                  return item?.shelf_number;
                })
              );
            } else if (unitType === "Tote") {
              setSuggestions(response?.data?.totes);
              setSuggestionsList(
                response?.data?.totes?.map((item) => {
                  return item?.tote_number;
                })
              );
            }
          } else {
            setSuggestions([]);
          }
        }
      }
    );
  };

  const selectWarehouseItem = (item, key, tebId) => {
    let data_name_key = key + "_name";
    let item_specific_key;
    if (key === "unit") {
      item_specific_key = key + "_name";
    } else {
      item_specific_key = key + "_number";
    }
    let item_id_key = key + "_id";
    let itemDetails;
    if (key === "unit") {
      itemDetails = suggestions?.find((value) => {
        return value?.unit_name === item;
      });
    } else if (key === "aisle") {
      itemDetails = suggestions?.find((value) => {
        return value?.aisle_number === item;
      });
    } else if (key === "bay") {
      itemDetails = suggestions?.find((value) => {
        return value?.bay_number === item;
      });
    } else if (key === "shelf") {
      itemDetails = suggestions?.find((value) => {
        return value?.shelf_number === item;
      });
    } else if (key === "tote") {
      itemDetails = suggestions?.find((value) => {
        return value?.tote_number === item;
      });
    }
    setIsWarehouseToteDetails((prevData) => {
      return prevData.map((value) => {
        if (value.teb_id === tebId) {
          return {
            ...value,
            [item_id_key]: itemDetails[item_id_key],
            [data_name_key]: itemDetails[item_specific_key],
          };
        }
        return value;
      });
    });
    setSuggestions([]);
    setSuggestionsList([]);
  };

  const handleItemInputChange = (key, teb_id, newValue) => {
    setFormErrors((prevErrors) => {
      // Create a copy of the current errors
      const updatedErrors = { ...prevErrors };
  
      // Delete the current field's error
      if (updatedErrors[teb_id]?.[key]) {
        delete updatedErrors[teb_id][key];
      }
  
      // Define the hierarchy order
      const hierarchy = [
        "unit_name",
        "aisle_name",
        "bay_name",
        "shelf_name",
        "tote_name",
      ];
  
      // Find the index of the current key in the hierarchy
      const keyIndex = hierarchy.indexOf(key);
  
      if (keyIndex !== -1) {
        // Remove errors for all lower-level fields in the hierarchy
        for (let i = keyIndex + 1; i < hierarchy.length; i++) {
          if (updatedErrors[teb_id]?.[hierarchy[i]]) {
            delete updatedErrors[teb_id][hierarchy[i]];
          }
        }
      }
  
      return updatedErrors;
    });
  
    // Update the warehouse tote details state
    setIsWarehouseToteDetails((prevData) => {
      return prevData.map((value) => {
        if (value.teb_id === teb_id) {
          return {
            ...value,
            [key]: newValue,
          };
        }
        return value;
      });
    });
  };
  

  const clearData = (key, teb_id) => {
    const hierarchy = ["unit_id", "aisle_id", "bay_id", "shelf_id", "tote_id"];
    const nameHierarchy = ["unit_name", "aisle_name", "bay_name", "shelf_name", "tote_name"];
    
    setIsWarehouseToteDetails((prevData) => {
      return prevData.map((value) => {
        if (value.teb_id === teb_id) {
          // Find the index of the key in the hierarchy
          const index = nameHierarchy.indexOf(key);
          if (index !== -1) {
            // Clear the current and all lower-level fields in the hierarchy
            const clearedFields = hierarchy.slice(index).reduce((acc, field, idx) => {
              acc[field] = ""; // Clear ID field
              acc[nameHierarchy[index + idx]] = ""; // Clear Name field
              return acc;
            }, {});
            return {
              ...value,
              ...clearedFields,
            };
          }
        }
        return value;
      });
    });
  };


  const handleTebSearch = (searchValue) => {
    const hasMatchingOption = storageList.some(option =>
      option?.name.toLowerCase().includes(searchValue?.toLowerCase())
    );

    if (!hasMatchingOption && searchValue?.trim() !== '') {      
      fetchStorageoptionsOnScroll(searchValue);
    }
  };

  
 

 const removeTebBags = (selectedOption) =>{
  let text = "Selected TamperEvidentBags will be cleared!";
  let confirmButtonText = "OK";
  let cancelButtonText = "Cancel";
  let showCancelButton = true;
  let title = "";
  let type = "warning";
  let target = document.getElementById("swal-div")
  if(storageLocation && selectedTebList.length>0){

    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      cancelButtonText,
      target
    ).then((result) => {
      if (result.value) {
        setSelectedTebList([]);
        setStorageLocation(selectedOption)
      }
    });
  }
  else{
    setStorageLocation(selectedOption)
  }

 }
 const fetchStorageoptionsOnScroll = async(searchStorageName) => {
    
    
    const response = await fetchStorageOptions(searchStorageName, page, storageList, storageType, currentUser?.jwtToken, storageType);
      
    setStorageList((prevList) => {
      const existingIds = new Set(prevList.map((item) => item?.identifier));
      const filteredLocations = response["locations"].filter(
        (location) => !existingIds.has(location?.identifier)
      );
    
      return [...prevList, ...filteredLocations];
    });
      // setStorageList((prevList) => [...prevList, ...response["locations"]]);
      
      setPage(response["UpdatedpageNo"]);
      // identifier
      
 }


  useEffect(() => {
    if(storageType){

      fetchStorageoptionsOnScroll("")
    }
  }, [storageType]);
  

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 body" >
        <div className="row flex p-4">
          <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden">
            <div className="row pt-1 pb-2 mt-5">
              <div className="d-flex justify-content-end">
                <div className="p-0">
                  {currentUser?.check_in_save_bulk_upload_access_flag && (
                    <Link to="/customBulkCheckin">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        id="bulk-checkin-button"
                      >
                        CUSTOM BULK CHECK-IN
                      </button>
                    </Link>
                  )}
                </div>
                <div className="p-0">
                  <Link to="/viewAllCheckIn">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      id="checkin-records-button"
                    >
                      CHECK-IN RECORDS
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <MDBCard className=" p-0 m-0 overflow-hidden" id="swal-div">
              <CustomHeader id="single" label={"SINGLE CHECK-IN"} />
              <MDBCardBody id="single-scroll">
                <div className="row col-lg-12 justify-content-center">
                  CHECK-IN DETAILS
                </div>
                <div className="row pb-2 card-border">
                <form className="d-flex flex-row justify-content-between align-items-center">
                    <div className="form-group" style={{width:"30%"}}>
                      <label htmlFor="storageType" className="form-label">
                        Select Storage Type
                      </label>
                      <select
                        id="storageType"
                        className="form-select"
                        onChange={(e) => {
                          setStorageType(e.target.value);
                          setStorageList([]); // Clear storage list on type change
                          setStorageLocation({});
                          setFormErrors({ ...formErrors, storage_type: "" });
                        }}
                      >
                        <option value="">Select Storage Type</option>
                        {storageTypeList?.map((opt) => (
                          <option key={opt?.action_id} value={opt?.action_id}>
                            {opt?.action}
                          </option>
                        ))}
                      </select>
                      <div className="error-message-validation">
                        {formErrors.storage_type}
                      </div>
                    </div>

                    <div className="form-group" style={{width:"30%"}} >
                      <label htmlFor="storageLocation" className="form-label">
                        Select Storage Name
                      </label>
                      
                      <Select
                        isDisabled={storageList.length === 0}
                        id="storageLocation"
                        onInputChange={(e)=>{handleTebSearch(e)}}
                        value={storageLocation}
                        onChange={(selectedOption) => {removeTebBags(selectedOption);}}
                        options={storageList.map((record) => ({
                          value:
                            record?.branch_id || record?.vault_id || record?.warehouse_id, // Unique identifier
                            label: record?.name, // Display field
                            storageType: record?.storage_type,
                            branchDid: record?.branch_did || null,
                        }))}
                        isClearable
                        onMenuScrollToBottom={() => {fetchStorageoptionsOnScroll("")}} // Trigger fetch when scrolled to bottom
                        menuPlacement="auto"
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            // maxHeight: "100px", // Set max height for scrollable dropdown
                            overflowY: "auto", // Enable scrolling
                          }),
                        }}
                        placeholder="Select Storage Location"
                      />
                    </div>
                    
                    <div className="form-group" style={{ width: "30%" }}>
                      {storageLocation?.branchDid !=  null && (
                        <>
                          <label htmlFor="branch_did" className="form-label">
                            Branch DID
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="branch_did"
                            name="branch_did"
                            disabled
                            autoComplete="off"
                            value={storageLocation?.branchDid}
                            placeholder="No Branch DID available"
                          />
                        </>
                      )}
                    </div>

                  </form>
                  <div className="form-row" >
                    <div className="col-md-6 col-lg-6 col-sm-12">
                      <SearchPanel
                        label={t("labels.teb")}
                        placeholder="Search by TEB Number"
                        options={tebOptionList}
                        setOptionList={setTebOptionList}
                        setShowOptions={setShowTebOptions}
                        name="teb_id"
                        id="teb_id"
                        showOptions={showTebOptions}
                        onFocusSearch={(e) => {
                          setTebSearchString("");
                        }}
                        handleChange={(e) => {
                          setTebOptionList([])
                          if(storageLocation?.value){
                            let errors = {teb_id: "" };

                            setFormErrors((prevErrors) => ({
                              ...prevErrors,
                              ...errors, // Add/overwrite errors
                            }));

                            if (e.target.value.length > 0) {
                              setShowTebOptions(true);
                              if (isTebNameAssigned) {
                                setTebSearchString(
                                  e.target.value.charAt(e.length - 1)
                                );
                                setTebNameAssigned(false);
                                setShowTebOptions(true);
                              } else {
                                setTebSearchString(e.target.value);
                              }
                            } else {
                              setTebSearchString(
                                e.target.value.charAt(e.length - 1)
                              );
                              setShowTebOptions(false);
                            }
                          }else{
                            let errors = {teb_id: "Please select the storage name." };
                            setFormErrors((prevErrors) => ({
                              ...prevErrors,
                              ...errors, // Add/overwrite errors
                            }));

                          }
                        }}
                        searchString={tebSearchString}
                        onSelectValue={(selectedTeb) => {
                          setTebNameAssigned(true);
                          setShowTebOptions(false);
                          const teb = tebList.filter(
                            (row) =>
                              row?.tamper_evident_bag_number === selectedTeb
                          )[0];
                          setTebSearchString("");
                          setSelectedTebList([teb, ...selectedTebList]);
                          setIsWarehouseToteDetails([
                            ...warehouseToteDetails,
                            {
                              teb_id: teb?.tamper_evident_bag_id,
                              unit_id: "",
                              unit_name: "",
                              aisle_id: "",
                              aisle_name: "",
                              bay_id: "",
                              bay_name: "",
                              shelf_id: "",
                              shelf_name: "",
                              tote_id: "",
                              tote_name: "",
                            },
                          ]);
                          if (
                            formErrors["teb_id"] !== undefined &&
                            formErrors["teb_id"].length > 0
                          ) {
                            delete formErrors["teb_id"];
                          }
                        }}
                      />
                      <i
                        className="fa fa-barcode float-right pr-2 input-scanner-icon h2"
                        aria-hidden="true"
                        title="Scan Tamper Evident Bag"
                      ></i>

                      <div className="error-message-validation">
                        {formErrors.teb_id}
                      </div>
                    </div>
                    {/* {selectedTebList.length > 0 && (
                      <div className="form-group col-md-6 col-xs-4  col-lg-4 ms-5">
                        <label htmlFor="storage_type">Storage Type123</label>
                        <input
                          className="form-control mr-sm-2"
                          value={
                            lastTimelineStatus
                              ? lastTimelineStatus?.storage_type
                              : null
                          }
                          onChange={onChange}
                          disabled={true}
                        />


                      </div>
                    )} */}

                    {/* {selectedTebList.length > 0 && (
                      <>
                        <div className="form-group col-md-6 col-xs-4  col-lg-4">
                          <label>Storage Name</label>
                          <input
                            className="form-control"
                            value={
                              lastTimelineStatus
                                ? lastTimelineStatus.name
                                : null
                            }
                            disabled={true}
                          />

                          <div className="error-message-validation">
                            {formErrors.storage_location_id}
                          </div>
                        </div>
                      </>
                    )} */}
                    
                  </div>
                  {selectedTebList.length > 0 && (
                    <div
                      className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 mb-5"
                      style={{ maxHeight: "200px" }}
                    >
                      <div
                        className="row flex pt-4 pl-4 pr-4 pb-0 "
                        
                      >
                        <div className="col-lg-12 col-md-12 col-sm-12 a pl-0 pr-0 overflow-hidden">
                          <SearchSelectedListTable
                            label={"TAMPER EVIDENT BAGS"}
                            headers={ConfigData.viewSelectedTEBDetails}
                            recordsList={selectedTebList}
                            responseKeys={ConfigData.viewTEBListResponseKeys}
                            warehouseToteDetails={warehouseToteDetails}
                            warehouseId={warehouseId}
                            handleInputChange={handleInputChange}
                            warehouseItemSuggestions={suggestionsList}
                            setWarehouseItemSuggestions={setSuggestionsList}
                            clearData={clearData}
                            handleItemInputChange={handleItemInputChange}
                            setSuggestions={setSuggestions}
                            screen="CheckIn"
                            selectWarehouseItem={selectWarehouseItem}
                            isShowWarehouse={isShowWarehouse}
                            onClickDeleteItem={(teb, idx) =>
                              onClickDeleteTEB(teb, idx)
                            }
                            errors={formErrors}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {isAuthorization && (
                    <>
                      <div className="form-row mt-5">
                        <div className="col-md-6 col-lg-6 col-sm-12">
                          <SearchPanel
                            label="Authorizer Assigned"
                            placeholder="Search by Employee Name or Email"
                            options={employeeOptionList}
                            setOptionList={setEmployeeOptionList}
                            setShowOptions={setAuthorizerShowOptions}
                            showOptions={authorizerShowOptions}
                            onFocusSearch={(e) => {
                              setAuthorizerSearchString("");
                            }}
                            handleChange={(str) => {
                              setEmployeeOptionList([]); // Clear the options list to reset suggestions
                              const value = str.target.value;
                            
                              if (value.length > 0) {
                                setAuthorizerShowOptions(true); // Show suggestions if input has text
                                setAuthorizerSearchString(value); // Update the search string
                              } else {
                                setAuthorizerSearchString(""); // Clear the search string if input is empty
                                setAuthorizerShowOptions(false); // Hide suggestions
                              }
                            }}
                            
                            searchString={authorizerSearchString}
                            onSelectValue={(selectedAuthorizer) => {
                              setIsAuthorizerAssigned(true);
                              setAuthorizerShowOptions(false);
                              const authorizer = employeeList.filter(
                                (emp) =>
                                  emp.employee_email ===
                                  selectedAuthorizer.split(" - ")[1]
                              )[0];
                              setAuthorizerSearchString("");
                              setSelectedAuthorizerList([
                                authorizer,
                                ...selectedAuthorizerList,
                              ]);
                              if (
                                formErrors["authorizer"] !== undefined &&
                                formErrors["authorizer"].length > 0
                              ) {
                                delete formErrors["authorizer"];
                              }
                            }}
                          />
                          <div className="error-message-validation">
                            {formErrors.authorizer}
                          </div>
                        </div>
                      </div>
                      {selectedAuthorizerList.length > 0 && (
                        <SearchSelectedListTable
                          label={"AUTHORIZERS"}
                          headers={ConfigData.viewSelectedAuthorizerDetails}
                          recordsList={selectedAuthorizerList}
                          responseKeys={
                            ConfigData.viewAuthorizerListResponeKeys
                          }
                          onClickDeleteItem={(authorizer, idx) =>
                            onClickDeleteAuthorizer(authorizer, idx)
                          }
                        />
                      )}
                    </>
                  )}
                  <div className="form-row">
                    <div className="form-group col-md-6 col-xs-12  col-lg-6">
                      <label htmlFor="checkin_notes">Comments</label>
                      <textarea
                        className="form-control"
                        name="checkin_notes"
                        id="checkin_notes"
                        onChange={onChange}
                        value={checkinNotes}
                        placeholder="Enter Check-in Comments"
                        rows="5"
                        style={{ width: "100%" }}
                      ></textarea>
                      <div className="error-message-validation">
                        {formErrors.checkin_notes}
                      </div>
                    </div>
                    <div
                      className="form-group col-md-6 col-xs-4 col-lg-2 px-4 mt-2"
                      id={`document_group_1`}
                    >
                      <input
                        type="file"
                        id={`input_document`}
                        multiple
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleAddDocument(e);
                        }}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                      ></input>

                      <label
                        htmlFor={`input_document`}
                        className="btn btn-primary btn-sm mt-3"
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        <i
                          id={`icon_upload`}
                          className="fa fa-upload fa-1x me-1"
                          aria-hidden="true"
                        ></i>{" "}
                        Document
                      </label>
                    </div>
                    <div className="form-group col-md-6 col-xs-4 col-lg-2 px-4 mt-2">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm mt-3"
                        onClick={() => {
                          setIsModelEnabled(true);
                        }}
                      >
                        <i className="fa fa-camera me-1" aria-hidden="true"></i>
                        Picture
                      </button>
                    </div>
                  </div>
                </div>
                {documentList.length > 0 && (
                  <>
                    <div className="row col-lg-12 justify-content-center">
                      DOCUMENTS
                    </div>
                    <div className="row card-border">
                      <DocumentHorizontal
                        itemId="checkin"
                        id={`documents_horizontal`}
                        documentList={documentList}
                        onDeleteDocument={handleDeleteDocument}
                        onUpdateDocComment={handleUpdateDocumentComment}
                      />
                    </div>
                  </>
                )}
                {pictureList.length > 0 && (
                  <div className="row col-lg-12 justify-content-center">
                    PICTURES
                  </div>
                )}
                <div
                  className={pictureList.length > 0 ? "row card-border" : ""}
                >
                  <div className="form-row align-items-end">
                    <div className="form-group col-12 px-4">
                      <WebcamCapture
                        itemId="checkin"
                        pictureList={pictureList}
                        onCapturePicture={(picture, itemId) =>
                          onCapturePicture(picture, itemId)
                        }
                        onDeletePicture={(index, itemId) =>
                          OnDeletePicture(index, itemId)
                        }
                        isModelEnabled={isModelEnabled}
                        disableModal={() => {
                          setIsModelEnabled(false);
                        }}
                      ></WebcamCapture>
                    </div>
                  </div>
                </div>
                <PerformedBy />
                <div className="fieldArea7 pt-2 pb-2 justify-content-center">
                  <button
                    type="file"
                    className="btn  btn-sm btn-primary"
                    onClick={onClose}
                  >
                    CANCEL
                  </button>
                  <button
                    type="file"
                    className="btn  btn-sm btn-primary"
                    onClick={onSubmit}
                  >
                    SUBMIT
                  </button>
                </div>
              </MDBCardBody>
            </MDBCard>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleCheckIn;
