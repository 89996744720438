import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import { useEffect, useState, useCallback } from "react";
// import Spinner from "react-bootstrap/Spinner";
import { getRecentData, getTimeline } from "../api/HomeServices";
import NewTimeline from "../components/NewTimeline";
import Search from "../components/Search";
import "../style/styles.css";
import ConfigData from "../config";
import { useNavigate } from "react-router-dom";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ConsoleError } from "../utils/ErrorUtils";
import moment from "moment/moment";
import useFetchNotifications from "../utils/NotificationUtils";
import { loaderDisplay } from "../utils/loader";

const Home = (props) => {
  let navigate = useNavigate();
  const [timeLineEvent, setTimeLineEvent] = useState("");
  const [clickid, setClickId] = useState(0);
  const [selectedRow, setSelectedRow] = useState(0);
  const [saveLoader, setSaveLoader] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [timeLineLoader, setTimeLineLoader] = useState(false);
  //eslint-disable-next-line
  const [nextPageNo, setNextPageNo] = useState();
  //eslint-disable-next-line
  const [perviousPageNo, setPerviousPageNo] = useState();
  const [saveEventLoader, setSaveEventLoader] = useState(false);
  //eslint-disable-next-line
  const [rowCount, setRowCount] = useState(10);
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const [isUser, setIsUser] = useState(false);
  //eslint-disable-next-line
  const notificationResponse = useFetchNotifications(
    currentUser?.employee_id,
    currentUser?.jwtToken
  );
  //eslint-disable-next-line
  const { t } = useTranslation();
  const getTimelineData = useCallback(
    (tamperevidentbag_id) => {
      if (tamperevidentbag_id) {
        setTimeLineLoader(true);
        getTimeline(tamperevidentbag_id, currentUser?.jwtToken)
          .then((response) => {
            if (response.status === 401) {
              cognitoSignOut()
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  ConsoleError(error);
                });
            } else if (response.status === 200) {
              setTimeLineLoader(false);
              setSaveLoader(false);
              setSaveEventLoader(false);
              setEventData(response?.data);
              setIsUser(true);
              handle(response?.data?.activity_list[0]);
              setClickId(0);
              setPageNo(response?.data);
            } else if (response?.status === 204) {
              setEventData([]);
              setSaveLoader(false);
              setSaveEventLoader(false);
              setTimeLineEvent("");
              setTimeLineLoader(false);
            }
          })
          .catch((error) => {
            ConsoleError(error);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            setTimeLineLoader(false);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser?.employee_id, currentUser?.jwtToken, navigate]
  );

  const getRecentActivities = useCallback(() => {
    setSaveLoader(true);
    setSaveEventLoader(true);
    getRecentData(
      currentUser?.employee_id,
      1,
      20,
      currentUser?.jwtToken,
      "",
      "",
      "",
      ""
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (
          response.status === 200 &&
          response?.data !== undefined &&
          response?.status === 200
        ) {
          setSaveLoader(false);
          setSaveEventLoader(false);
          setDisplayData(response?.data);
          getTimelineData(response?.data[0]?.tamper_evident_bag_id);
        } else if (
          response.status === 200 &&
          response?.data !== undefined &&
          response?.status === 204
        ) {
          setSaveEventLoader(false);
          setSaveLoader(false);
        } else {
          setSaveEventLoader(false);
          setSaveLoader(false);
        }
      })
      .catch((error) => {
        setSaveLoader(false);
        setSaveEventLoader(false);
      });
    //eslint-disable-next-line
  }, [getTimelineData, navigate]);

  // useEffect(() => {
  //   setSaveLoader(true);
  //   getRecentActivities();
  // }, []);

  const onClickEachTimeline = (item, index) => {
    setClickId(index);
    handle(item);
  };

  const onClickRow = async (item, index) => {
    setTimeLineLoader(true);
    setSaveEventLoader(true);
    setSelectedRow(index);
    await getTimelineData(item?.tamper_evident_bag_id);
  };

  const handle = (event, date) => {
    setTimeLineEvent(event);
  };

  const setPageNo = async (response) => {
    if (isNaN(response.next)) {
      setNextPageNo(response.next ? response.next.slice(-1) : null);
    }
    if (isNaN(response.previous)) {
      setPerviousPageNo(
        response.previous && response.previous.slice(-1).match(/[a-z]/i)
          ? response.previous.slice(-1)
          : null
      );
    }
  };

  const eventHeadingExists = () => {
    let exists = ConfigData.eventHeading.find(
      ({ DB_col }) => DB_col === timeLineEvent?.event
    );
    if (exists !== undefined && exists.DB_col !== undefined) {
      return exists.display_name;
    } else {
      return "NO EVENT";
    }
  };

  const generateOwnerName = () => {
    let user_info = eventData?.owner_info?.[clickid];
    let result = user_info?.first_name + " " + user_info?.last_name;
    if (result.length <= 3) {
      return "Mystery User";
    }
    return result;
  };

  const generateCoOwnerName = () => {
    let user_info = eventData?.co_owner_info?.[clickid];
    let result = user_info?.first_name + " " + user_info?.last_name;
    if (result.length <= 3) {
      return "Mystery User";
    }
    return result;
  };

  const onPageClick = async (operation) => {
    //TO-DO
    // setTimeLineLoader(true);
    // setSaveEventLoader(true);
    // let pageNo;
    // if (operation === "plus") {
    //   pageNo = nextPageNo;
    // } else {
    //   if (perviousPageNo === undefined) {
    //     await getTimelineData(displayData[selectedRow].tamperevidentbag_id);
    //   }
    //   pageNo = perviousPageNo;
    // }
    // if (pageNo !== null && !pageNo.match(/[a-z]/i)) {
    //   getTimelinePagination(
    //     displayData[selectedRow].tamperevidentbag_id,
    //     pageNo,
    //     currentUser?.employee_id,
    //     currentUser?.jwtToken
    //   )
    //     .then((response) => {
    //       if (response.status === 401) {
    //         cognitoSignOut()
    //           .then(() => {
    //             navigate("/");
    //           })
    //           .catch((error) => {
    //             ConsoleError(error);
    //           });
    //       } else if (
    //         response.status === 200 &&
    //         response?.data.results !== undefined &&
    //         response?.data.results.status === 200
    //       ) {
    //         setTimeLineLoader(false);
    //         setEventData(response?.data);
    //         handle(response?.data[0]);
    //         setPageNo(response?.data);
    //         setSaveEventLoader(false);
    //       } else if (response.status === 200 && response?.data.status === 204) {
    //         setTimeLineLoader(false);
    //         setSaveEventLoader(false);
    //       }
    //     })
    //     .catch((error) => {
    //       setTimeLineLoader(false);
    //       ConsoleError(error);
    //     });
    // } else if (pageNo !== null) {
    //   getTimelineData(displayData[selectedRow].tamperevidentbag_id);
    //}
  };

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0">
      <div className="row flex p-4">
        <div className="col-lg-12 pl-0 pr-0 pt-5">
          <div className="col-12 py-2  pl-0 pr-0 search-bar-home">
            <Search
              filterData={(searchData) => {
                if (searchData === undefined || !searchData.filterData) {
                  getRecentActivities();
                  setSelectedRow(0);
                  setClickId(0);
                } else {
                  setSelectedRow(0);
                  setClickId(0);
                  setDisplayData(searchData?.filterData);
                  getTimelineData(
                    searchData?.filterData[0]?.tamper_evident_bag_id
                  );
                }
              }}
              getRecentActivities={getRecentActivities}
              screen="Home"
              pageSize={rowCount}
            />
          </div>
          <MDBCard className="card ">
            <div className="table-card h-md-100">
              <MDBTable className="safeChiefTable">
                <MDBTableHead style={{ position: "sticky", top: 0 }}>
                  <tr style={{ position: "sticky", background: "#6F7D95" }}>
                    {Object.entries(ConfigData.recentActivityDetails).map(
                      ([key, value]) => {
                        return <th className="tableHeading1">{value}</th>;
                      }
                    )}
                  </tr>
                </MDBTableHead>

                <MDBTableBody id="cs-scroll">
                  {saveLoader ? (
                     <tr>
                     <td colSpan="8" className="text-center py-5">
                     {loaderDisplay()}
                     </td>
                   </tr>
                  ) : displayData !== undefined && displayData?.length > 0 ? (
                    displayData.map((item, index) => {
                      return (
                        <tr
                          className={
                            selectedRow === index
                              ? "tableSelected"
                              : "table-row"
                          }
                          key={index}
                          onClick={() => {
                            selectedRow !== undefined &&
                              selectedRow !== index &&
                              onClickRow(item, index);
                          }}
                        >
                          <td className="tableHeading1">
                            {item?.safe_deposit_box?.safe_deposit_box_number}
                          </td>
                          <td className="tableHeading1">
                            {item?.tamper_evident_bag_number}
                          </td>

                          <td className="tableHeading1">
                            {item?.timeline_status === null ? (
                              item?.safe_deposit_box?.branch?.name
                            ) : item?.storage_location ? (
                              item?.storage_location
                            ) : (
                              <span className="pl-4 strong">-</span>
                            )}
                          </td>
                          <td className="tableHeading1">
                            {item?.timeline_status === null
                              ? "Branch"
                              : item?.timeline_status?.action?.action}
                          </td>
                          <td className="tableHeading1">
                            {item?.safe_deposit_box?.owner?.first_name +
                              " " +
                              item?.safe_deposit_box?.owner?.last_name}
                          </td>
                          <td className="tableHeading1">
                            {item?.timeline_status === null
                              ? "Force Open"
                              : item?.timeline_status?.action?.action}
                          </td>

                          <td className="tableHeading1">
                            {item?.approval?.status?.status
                              ? item?.approval?.status?.status
                              : item?.safe_deposit_box?.status?.status}
                          </td>
                          <td className="tableHeading1">
                            {item?.safe_deposit_box?.branch?.name}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>No data Available</td>
                    </tr>
                  )}
                </MDBTableBody>
              </MDBTable>
            </div>
            {/* )} */}
          </MDBCard>
        </div>
        <div className="col-lg-12 col-md-12" id="time-line-home">
          {displayData?.length > 0 && displayData[selectedRow] !== undefined ? (
            <NewTimeline
              handle={handle}
              tebId={displayData[selectedRow]?.tamper_evident_bag_id}
              eventData={eventData}
              timeLineLoader={timeLineLoader}
              clickid={clickid}
              onClickEachTimeline={onClickEachTimeline}
              onPageClick={onPageClick}
            />
          ) : (
            <div
              className="col-ld-12 col-md-12 text-center pr-5 mt-5 mb-5"
              id="right-slide"
            >
              {"No timeline available for selected record"}
            </div>
          )}
        </div>
      </div>

      <div className="row pl-2 pr-2 pt-0">
        <div className="col-lg-6 md-12 sm-12 pb-3">
          <MDBCard className="card">
            <MDBCardHeader className="card text-white justify-content-center form-heading">
              <h6 className="m-0 boxDetailsText">BOX DETAILS</h6>
            </MDBCardHeader>
              <MDBCardBody
                className={
                  displayData === undefined || displayData?.length === 0
                    ? "card px-1 align-items-center justify-content-center"
                    : "px-1 align-items-center justify-content-center"
                }
                style={{ height: "235px" }}
                id="scroll"
                >
                {saveLoader ? (
                  
                  loaderDisplay()
                 
                ) : (<>
                {displayData === undefined || displayData?.length === 0 ? (
                  <form className="text-center pr-5">
                    {"No Box Is Selected"}
                  </form>
                ) : (
                  <form style={{ paddingTop: 20 }}>
                    {/* <div className="form-group row">
                      <div className="col-12 col-sm-4 col-md-6 col-lg-6 col-xl-4 input-label">
                        <label className="fw-bold">
                          Tamper Evident Bag # :
                        </label>
                      </div>
                      <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-8 input-value">
                        {displayData[selectedRow].tamper_evident_bag_number}
                      </div>
                    </div> */}
                    <div className="form-group row">
                      <div className="col-12 col-sm-4 col-md-6 col-lg-6 col-xl-4 col-xl-4 input-label">
                        <label className="fw-bold">
                          Tamper Evident Bag # :
                        </label>
                      </div>
                      <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-8 input-value">
                        {displayData[selectedRow].tamper_evident_bag_number}
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-12 col-sm-4 col-md-6 col-lg-6 col-xl-4 col-xl-4  input-label">
                        <label className="fw-bold"> Storage Location :</label>
                      </div>
                      <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-8 input-value">
                        {displayData[selectedRow]?.timeline_status === null ? (
                          displayData[selectedRow]?.safe_deposit_box?.branch
                            ?.name
                        ) : displayData[selectedRow]?.storage_location ? (
                          displayData[selectedRow]?.storage_location
                        ) : (
                          <span className="pl-4 strong">-</span>
                        )}
                      </div>
                    </div>
                    {isUser && (
                      <div className="form-group row">
                        <div className="col-12 col-sm-4 col-md-6 col-lg-6 col-xl-4   input-label">
                          <label className="fw-bold">Owner’s Name :</label>
                        </div>
                        <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-8 input-value">
                          {generateOwnerName()}
                        </div>
                      </div>
                    )}
                    <div className="form-group row">
                      <div className="col-12 col-sm-4 col-md-6 col-lg-6 col-xl-4  input-label">
                        <label className="fw-bold">Co-Owner’s Name :</label>
                      </div>
                      <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-8 input-value">
                        {generateCoOwnerName()}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-12 col-sm-4 col-md-6 col-lg-6 col-xl-4  input-label">
                        <label className="fw-bold">Origin Branch DID :</label>
                      </div>
                      <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-8 input-value">
                        {
                          displayData[selectedRow]?.safe_deposit_box?.branch
                            ?.branch_did
                        }
                      </div>
                    </div>
                  </form>
                )}
                
                </>)}
              </MDBCardBody>
          </MDBCard>
        </div>
        <div className="col-lg-6 md-12 sm-12 pb-3">
          <MDBCard className="card">
            <MDBCardHeader className="card text-white justify-content-center form-heading">
              <div className="row ">
                <div className="col-lg-6 md-12 sm-12 sub-heading-home">
                  <h6 className="m-0 boxDetailsText">{eventHeadingExists()}</h6>
                </div>
                <div className="col-lg-6 md-12 sm-12 anchor-home">
                  <a
                    onClick={() => {
                      if (eventHeadingExists() === "FORCE OPEN DETAILS") {
                        navigate("/forceOpenDetails", {
                          state: eventData,
                        });
                      } else {
                        if (eventHeadingExists() === "CHECK IN DETAILS") {
                          navigate("/checkinHistory", {
                            state: timeLineEvent,
                          });
                        } else if (
                          eventHeadingExists() === "CHECK OUT DETAILS"
                        ) {
                          navigate("/checkOutDetails", {
                            state: timeLineEvent,
                          });
                        } else if (
                          eventHeadingExists() === "SHIPPING CONTENT"
                        ) {
                          navigate("/shipmentHistory", {
                            state: timeLineEvent,
                          });
                        } else if (eventHeadingExists() === "AUDIT DETAILS") {
                          navigate("/auditHistory", {
                            state: timeLineEvent,
                          });
                        } else if (eventHeadingExists() === "ENQUIRIES") {
                          navigate("/enquiryHistory", {
                            state: timeLineEvent,
                          });
                        }
                      }
                    }}
                  >
                    {/* <button id="customButton"> */}
                    <span>View more</span>
                    <span id="customButtonIcon">➔</span>
                    {/* </button> */}
                  </a>
                </div>
              </div>
            </MDBCardHeader>
              <MDBCardBody
                style={{ height: "235px" }}
                className={
                  timeLineEvent !== undefined && timeLineEvent !== ""
                  ? "px-1 align-items-center justify-content-center"
                  : "card px-1 align-items-center justify-content-center"
                }
                id="scroll"
                >
                {saveEventLoader ? (
                  loaderDisplay()
                ) : (
                <>
                  {timeLineEvent !== undefined && timeLineEvent !== "" ? (
                    <form style={{ paddingTop: "20px" }}>
                      {timeLineEvent &&
                        ConfigData.eventDetails[timeLineEvent.event]?.map(
                          (event_item) => {
                            const keys = event_item["DB_col"].split(".");
                            let value = timeLineEvent.information;
                            for (let key of keys) {
                              value = value[key];
                            }
                            return (
                              <div className="form-group row">
                                <div className="col-12 col-sm-4 col-md-6 col-lg-6 col-xl-4 input-label">
                                  <label className="fw-bold">
                                    {event_item.display_name + " "}:
                                  </label>
                                </div>
                                <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-8 input-value">
                                  {event_item.type === "date" &&
                                  value !== undefined
                                    ? moment(value).format("MM-DD-YYYY")
                                    : value === "True"
                                    ? "Yes"
                                    : value === "False"
                                    ? "No"
                                    : value !== undefined
                                    ? value
                                    : ""}
                                </div>
                              </div>
                            );
                          }
                        )}
                      {timeLineEvent.event === "check_in" &&
                        timeLineEvent.information.warehouse_storage && (
                          <div className="form-group row">
                            <div className="col-12 col-sm-4 col-md-6 col-lg-6 col-xl-4  input-label">
                              <label className="fw-bold">Tote Number</label>
                            </div>
                            <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-8 input-value">
                              {
                                timeLineEvent.information.warehouse_storage
                                  ?.tote?.unit
                              }
                              <strong> / </strong>
                              {
                                timeLineEvent.information.warehouse_storage
                                  ?.tote?.aisle
                              }{" "}
                              {`/ `}
                              {
                                timeLineEvent.information.warehouse_storage
                                  ?.tote?.bay
                              }{" "}
                              {`/ `}
                              {
                                timeLineEvent.information.warehouse_storage
                                  ?.tote?.shelf
                              }{" "}
                              {`/ `}
                              {
                                timeLineEvent.information.warehouse_storage
                                  ?.tote?.tote_number
                              }
                            </div>
                          </div>
                        )}
                      <div id="form-group row" className="d-flex">
                        <div className="col-lg-4 col-md-4 col-sm-4"></div>
                        <div className="col-lg-4 col-md-4 col-sm-4"></div>
                        <div className="col-lg-4 col-md-4 col-sm-4 d-flex justify-content-end">
                          {/* <a
                          onClick={() => {
                            if (eventHeadingExists() === "FORCE OPEN DETAILS") {
                              navigate("/forceOpenDetails", {
                                state: eventData,
                              });
                            } else {
                              if (eventHeadingExists() === "CHECK IN DETAILS") {
                                navigate("/checkinHistory", {
                                  state: timeLineEvent,
                                });
                              } else if (
                                eventHeadingExists() === "CHECK OUT DETAILS"
                              ) {
                                navigate("/checkOutDetails", {
                                  state: timeLineEvent,
                                });
                              } else if (
                                eventHeadingExists() === "SHIPPING CONTENT"
                              ) {
                                navigate("/shipmentHistory", {
                                  state: timeLineEvent,
                                });
                              } else if (
                                eventHeadingExists() === "AUDIT DETAILS"
                              ) {
                                navigate("/auditHistory", {
                                  state: timeLineEvent,
                                });
                              } else if (eventHeadingExists() === "ENQUIRIES") {
                                navigate("/enquiryHistory", {
                                  state: timeLineEvent,
                                });
                              }
                            }
                          }}
                        >
                        
                          <button id="customButton">
                              <span>Load More</span>
                              <span id="customButtonIcon">➔</span>
                          </button>

                        </a> */}
                        </div>
                      </div>
                    </form>
                  ) : (
                    <form className="text-center pr-5">
                      {"No event details available"}
                    </form>
                  )}
                </>
            )}
              </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </div>
  );
};

export default Home;
