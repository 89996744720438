import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getNotifications } from "../api/NotificationsService";
import { format } from "date-fns"; // Import date-fns for formatting
import "../style/notificationModal.css";

function NotificationModal(props) {
  const { IsModalOpened, onCloseModal, isAdmin, dynData } = props;
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);
  const modalRef = useRef(null);
  const navigate = useNavigate();

  const notificationData = useSelector(
    (state) => state.notificationServiceReducer?.value?.notificationData
  );

  // Fetch notifications
  useEffect(() => {
    if (!isAdmin) {
      const fetchNotifications = async () => {
        try {
          const data = notificationData || (await getNotifications());
          setNotifications(data);
        } catch (err) {
          console.error("Error fetching notifications:", err);
          setError("Failed to fetch notifications. Please try again.");
        }
      };
      fetchNotifications();
    }
  }, [isAdmin, notificationData]);

  // Handle click outside the modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onCloseModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCloseModal]);

  const handleNotificationClick = (notification) => {
    try {
      navigate("/approvalDetails", { state: notification });
      onCloseModal();
    } catch (err) {
      console.error("Error navigating to notification details:", err);
    }
  };

  return (
    <Modal
      isOpen={IsModalOpened}
      ariaHideApp={false}
      className="notification-modal"
    >
      <div ref={modalRef}>
        {error && <p className="notification-error-message">{error}</p>}
        <div class="notification-header-main">
          <h1 class="notification-header-title">{dynData}</h1>
          <button className="notification-close-btn" onClick={onCloseModal}>
            &times;
          </button>
        </div>
        <div class="notification-container" aria-live="polite">
          {notifications.length > 0 ? (
            notifications.map((item, index) => (
              <div
                class="notification"
                onClick={() => handleNotificationClick(item.notification)}
              >
                <div class="notification-content">
                  <div class="notification-header">
                    <span class="notification-title">
                      {" "}
                      {item.notification.action}
                    </span>
                    <span class="notification-time">
                      {" "}
                      {format(
                        new Date(item.notification.create_datetime),
                        "MMM dd, yyyy - h:mm a"
                      )}
                    </span>
                  </div>
                  <p class="notification-message">
                    {item.notification.description}{" "}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p className="notification-no-data">No notifications available.</p>
          )}
        </div>
      </div>
    </Modal>
  );
}

NotificationModal.propTypes = {
  IsModalOpened: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  dynData: PropTypes.string.isRequired,
};

NotificationModal.defaultProps = {
  isAdmin: false,
};

export default NotificationModal;
