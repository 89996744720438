import { getNotifications } from "../api/NotificationsService";
import { useDispatch } from "react-redux";
import { notificationService } from "../redux/notificationService";
import { useEffect, useCallback } from "react";
const useFetchNotifications = (employee_id, token) => {
  const dispatch = useDispatch();

  // Function to fetch notifications
  const fetchNotifications = useCallback(() => {
    getNotifications(employee_id, token)
      .then((response) => {
        if (response.status === 200) {
          dispatch(notificationService(response?.data));
        }
      })
      .catch((err) => {
        console.error("Error fetching notifications", err);
      });
  }, [employee_id, token, dispatch]);

  useEffect(() => {
    fetchNotifications(); // Initial fetch when the component mounts
  }, [fetchNotifications]);

  // Return refetch function to trigger notification update after approval
  return { refetch: fetchNotifications };
};

export default useFetchNotifications;