import React, { useState } from "react";
import { Navbar, Dropdown } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import "../style/header.css";
import NotificationModal from "../pages/NotificationModal";
import { cognitoSignOut } from "../api/CognitoServices";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/user";
import { useTranslation } from "react-i18next";
import NotificationBadge from "../components/Badge";
import { ALL_USER, SUPERADMIN_USER } from "../constants/RoleContants";

function Header(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalTitle, setmodalTitle] = useState();
  const [modalData, setmodalData] = useState();
  const [showDropdown, setShowDropdown] = useState(false); 

  const notificationCount = useSelector(
    (reduxData) =>
      reduxData?.notificationServiceReducer?.value?.notificationData.length
  );
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const signOut = () => {
    dispatch(logout());
    cognitoSignOut().catch((err) => {
      console.error(err);
    });
    navigate("/");
  };

  const openNotifications = () => {
    setIsOpen(true);
    setmodalTitle("NOTIFICATIONS");
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const openChangePassword = () => {
    navigate("/change-password");
  };

  return (
    <div className="container-fluid">
      <div className="row flex">
        <Navbar className="header">
          <Nav.Link className="p-0">
            <Navbar.Brand
              className="brand"
              onClick={() =>
                currentUser?.role === "safechief_admin"
                  ? navigate("/all-tenants")
                  : navigate("/home")
              }
            >
              {t("app.title")}
            </Navbar.Brand>
          </Nav.Link>
          <Nav className="me-auto"></Nav>
          <Nav>
            {notificationCount > 0 && ALL_USER.includes(currentUser?.role) && (
              <Nav.Link onClick={() => openNotifications()}>
                <NotificationBadge count={notificationCount}></NotificationBadge>
              </Nav.Link>
            )}

            <Dropdown
              show={showDropdown}
              style={{alignContent:"center"}}
              onToggle={(isOpen) => setShowDropdown(isOpen)}
            >
              <div
                className="d-flex align-items-center"
                style={{ cursor: "pointer",marginRight: "18px",marginTop:"2px" }}
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <i
                  className="fa fa-user-circle pl-3 text-white h4"
                  aria-hidden="true"
                ></i>
              </div>
              <Dropdown.Menu align="end">
                {SUPERADMIN_USER.includes(currentUser?.role) && (
                  <Dropdown.Item onClick={() => navigate("/admin")}>
                    <i className="fa fa-cog text-muted me-2"></i>Product Configuration
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={openChangePassword}>
                  <i className="fa fa-lock text-muted me-2"></i>Change Password
                </Dropdown.Item>
                <Dropdown.Item onClick={signOut}>
                  <i className="fa fa-sign-out text-muted me-2"></i>Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar>

        <NotificationModal
          dynData={modalTitle}
          data={modalData}
          IsModalOpened={modalIsOpen}
          onCloseModal={handleCloseModal}
          isAdmin={props.path}
        />
      </div>
    </div>
  );
}

export default Header;
