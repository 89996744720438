export const getHeader = (token) => {
  return {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Headers": "Content-Type",
    // "Access-Control-Allow-Methods": "OPTIONS,POST,GET,DELETE,PATCH",
    // "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    Authorization: token,
    "x-api-key": process.env.REACT_APP_API_KEY,
    "is-admin": false,
  };
};

export const getAdminHeader = (token) => {
  return {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Headers": "Content-Type",
    // "Access-Control-Allow-Methods": "OPTIONS,POST,GET,DELETE,PATCH",
    // "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    Authorization: token,
    "x-api-key": process.env.REACT_APP_API_KEY,
    "is-admin": true,
  };
};
