import { MDBCard, MDBCardBody } from "mdbreact";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { getViewCheckOutData } from "../api/CheckOutService";
import CustomHeader from "../components/CustomHeader";
import Pagination from "../components/Pagination";
import Search from "../components/Search";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import { ConsoleError } from "../utils/ErrorUtils";
import useFetchNotifications from "../utils/NotificationUtils";

function CheckOutView() {
  const navigate = useNavigate();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const [searchValue, setSearchValue] = useState("");
  const notificationResponse = useFetchNotifications(
    currentUser?.employee_id,
    currentUser?.jwtToken
  );

  // Grouped state for records and pagination
  const [checkoutState, setCheckoutState] = useState({
    checkOutRecords: [],
    currentPage: 1,
    rowCount: 10,
    pages: [],
    searchValue: "",
  });

  const [saveLoader, setSaveLoader] = useState(false);
  const [noRecordsFound, setNoRecordsFound] = useState("");

  // Fetch data function
  const fetchCheckOutData = async (
    page = 1,
    rows = checkoutState.rowCount,
    search = ""
  ) => {
    try {
      setSaveLoader(true);
      const searchString = searchValue || search;
      const response = await getViewCheckOutData(
        searchString,
        page,
        rows,
        currentUser?.employee_id,
        currentUser?.jwtToken
      );

      if (response.status === 401) {
        await cognitoSignOut();
        navigate("/");
        return;
      }

      if (response.status === 200) {
        const { pagination, check_out_list: checkOutRecords } = response.data;

        setCheckoutState((prevState) => ({
          ...prevState,
          checkOutRecords: checkOutRecords || [],
          currentPage: page,
          pages: [...Array(pagination?.pages).keys()].map((i) => i + 1),
        }));

        setNoRecordsFound(
          checkOutRecords?.length ? "" : "No check out records available"
        );
      }
    } catch (error) {
      ConsoleError(error);
      setNoRecordsFound("Failed to fetch data. Please try again.");
    } finally {
      setSaveLoader(false);
    }
  };

  // Handlers
  const handlePageClick = (page) => {
    if (page === checkoutState.currentPage) return;
    fetchCheckOutData(page);
  };

  const handleNextClick = () => {
    const { currentPage } = checkoutState;
    fetchCheckOutData(currentPage + 1);
  };

  const handlePreviousClick = () => {
    const { currentPage } = checkoutState;
    fetchCheckOutData(currentPage - 1);
  };

  const handleRowsPerPageChange = (rows) => {
    setCheckoutState((prevState) => ({ ...prevState, rowCount: rows }));
    fetchCheckOutData(1, rows);
  };

  const handleSearch = (data) => {
    const {
      pagination,
      check_out_list: checkOutRecords,
      searchValue = "",
    } = data;
    if (searchValue.trim().length === 0) {
      setCheckoutState((prevState) => ({
        ...prevState,
        currentPage: 1,
        searchValue: "",
      }));
      fetchCheckOutData(1, checkoutState.rowCount, "");
      return;
    }
    setCheckoutState((prevState) => ({
      ...prevState,
      checkOutRecords: checkOutRecords || [],
      currentPage: pagination?.page || 1,
      pages: [...Array(pagination?.pages).keys()].map((i) => i + 1),
    }));

    setNoRecordsFound(
      checkOutRecords?.length ? "" : "No check out records available"
    );
    // fetchCheckOutData(1, checkoutState.rowCount, "");
  };

  const handleRefresh = () => {
    fetchCheckOutData();
  };

  // Initial fetch
  useEffect(() => {
    fetchCheckOutData();
    // eslint-disable-next-line
  }, []);

  const { checkOutRecords, currentPage, rowCount, pages } = checkoutState;

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 body overflow-hidden">
      <div className="row flex p-4 ">
        <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden pt-5">
          <div className="row pt-2">
            <div className="col-lg-6 col-md-10">
              <Search
                filterData={handleSearch}
                screen="Checkout"
                pageSize={rowCount}
                setSearchValue={setSearchValue}
              />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 ">
              <button
                className="btn btn-primary btn-sm col-lg-6 col-md-6 col-sm-11 m-0 pl-0 pr-0 float-end"
                onClick={handleRefresh}
              >
                Refresh
              </button>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 ">
              {currentUser?.check_out_save_bulk_upload_access_flag && (
                <Link to="/bulkCheckOut">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm col-lg-11 col-md-11 col-sm-11 m-0 float-end"
                    id="bulk-checkin-button"
                  >
                    BULK CHECK-OUT
                  </button>
                </Link>
              )}
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 ">
              {currentUser?.check_out_save_record_access_flag && (
                <Link to="/createcheckout">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm col-lg-11 col-md-11 col-sm-11 m-0 float-end"
                    id="checkin-records-button"
                  >
                    SINGLE CHECK-OUT
                  </button>
                </Link>
              )}
            </div>
          </div>

          <MDBCard className="card overflow-hidden h-md-100 table-box">
            <CustomHeader label={"CHECK-OUT RECORDS"} />
            <MDBCardBody>
              <div className="table-responsive">
                <table className="table safeChiefTable">
                  <thead className="headerArea">
                    <tr>
                      <th scope="col" className="tableHeading1">
                        TAMPER EVIDENT BAG #
                      </th>
                      <th scope="col" className="tableHeading">
                        CHECKOUT TYPE
                      </th>
                      <th scope="col" className="tableHeading">
                        ENTITY NAME
                      </th>
                      <th scope="col" className="tableHeading">
                        PERFORMED BY
                      </th>
                      <th scope="col" className="tableHeading">
                        STATUS
                      </th>
                    </tr>
                  </thead>
                  <tbody id="cs-scroll">
                    {saveLoader ? (
                      <div className="text-center py-5 alignself-center">
                        <Spinner animation="border" />
                      </div>
                    ) : checkOutRecords.length ? (
                      checkOutRecords.map((item) => (
                        <tr
                          className="table-row"
                          key={item.checkout_id}
                          onClick={() =>
                            navigate("/checkOutDetails", {
                              state:
                                item?.tamper_evident_bag?.tamper_evident_bag_id,
                            })
                          }
                        >
                          <td className="tableDetails1">
                            {item.tamper_evident_bag?.tamper_evident_bag_number}
                          </td>
                          <td className="tableDetails">
                            {item?.action?.action}
                          </td>
                          <td className="tableDetails shipmentBody5">
                            {item?.entity[0]?.entity_name}
                          </td>
                          <td className="tableDetails shipmentBody5">
                            {item?.performed_by?.employee_name}
                          </td>
                          <td className="tableDetails shipmentBody5">
                            {item?.status.status}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div className="text-center py-5 alignself-center">
                        {noRecordsFound}
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </MDBCardBody>
            <Pagination
              pages={pages}
              onNextClick={handleNextClick}
              onPreviousClick={handlePreviousClick}
              onPageClick={handlePageClick}
              initial={0}
              setRowsPerPage={handleRowsPerPageChange}
              currentPage={currentPage}
            />
          </MDBCard>
        </div>
      </div>
    </div>
  );
}

export default CheckOutView;
