import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Navigate, Route, Routes } from "react-router-dom";
import Preview from "../src/components/ForceOpenComponents/Preview";
import GenerateMockData from "../src/components/GenerateMockData";
import BulkCheckIn from "../src/pages/BulkCheckIn";
import BulkShipment from "../src/pages/BulkShipment";
import CheckInHistory from "../src/pages/CheckinHistory";
import CreateEnquriy from "../src/pages/CreateEnquiry";
import CreateShipment from "../src/pages/CreateShipment";
import CreateStorageLocation from "../src/pages/CreateStorageLocation";
import CustomBulkCheckin from "../src/pages/CustomBulkCheckin";
import EnquiryHistory from "../src/pages/EnquiryHistory";
import ViewAllEnquiry from "./pages/ViewAllEnquiry";
import ForceOpenStepper from "../src/pages/ForceOpen";
import Home from "../src/pages/Home";
import Inbox from "../src/pages/Inbox";
import CreateAudit from "./pages/CreateAudit";
import ViewAllShipContents from "./pages/ViewAllShipContents";
import ShipmentHistory from "../src/pages/ShipmentHistory";
import BulkCheckout from "./pages/BulkCheckout";
import CreateCheckin from "./pages/CreateCheckin";
import ViewAllAudit from "../src/pages/ViewAllAudit";
import ViewAllCheckIn from "../src/pages/ViewAllCheckIn";
import ViewStorageLocation from "../src/pages/ViewStorageLocation";
import ViewAllApprovals from "./pages/ViewAllApprovals";
import AuditHistory from "./pages/AuditHistory";
import ViewAllCheckout from "./pages/ViewAllCheckout";
import CreateCheckOut from "./pages/CreateCheckOut";
import SignIn from "./pages/SignIn";
import UserVerification from "./pages/UserVerification";
import ViewReports from "./pages/ViewReports";
import RequireAuth from "./components/RequireAuth";
import "../src/style/styles.css";
import Header from "../src/components/Header";
import SideMenu from "../src/components/SideMenu";
import { useLocation } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { cognitoSignOut } from ".././src/api/CognitoServices";
import config from "./config";
import { CustomAlert } from "../src/components/customAlert";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConsoleError } from "./utils/ErrorUtils";
import ProductSetUp from "./pages/ProductSetUp";
import VerifyProductSetUp from "./pages/VerifyProductSetUp";
import ForceOpenDetails from "../src/pages/ForceOpenDetails";
import Welcome from "./pages/Welcome";
import CustomBagUploadFsi from "../src/pages/CustomBagUploadFsi";
import CheckOutDetails from "./pages/CheckOutDetails";
import {
  ADMIN_USER,
  ALL_USER,
  SUPERADMIN_USER,
  WRITE_USER,
  AUDIT_USER,
  SAFECHIEF_ADMIN,
} from "./constants/RoleContants";
import PageNotFound from "./pages/PageNotFound";
import ProductConfiguration from "./pages/ProductConfiguration";
import ViewIRPTEBHistory from "./components/ProductConfiguration/ViewIRPTEBHistory";
import ApprovalDetails from "./pages/ApprovalDetails";
import ViewAllPreOpen from "./pages/ViewAllPreOpen";
import CreatePreOpen from "./pages/CreatePreOpen";
import EditPreOpen from "./pages/EditPreOpen";
import YTPAdminAddSubscription from "./pages/YTPAdminAddSubscription";
import YTPAdminSignIn from "./pages/YTPAdminSignIn";
import YTPAdminHome from "../src/pages/YTPAdminHome";
import YTPAdminAddTenant from "../src/pages/YTPAdminAddTenant";
import YTPAdminSubscription from "../src/pages/YTPAdminSubscription";
import AdminVerification from "./pages/adminVerification";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import { awsconfig } from "./aws-exports";
import {validateToken} from "../src/utils/SessionValidator"
import { useDispatch } from "react-redux";
import { login } from "./redux/user";

Auth.configure(awsconfig);
function Navigation() {
  const path = useLocation();
  const timeout = config.screenTimeOut;
  const [isRemaining, setRemaining] = useState(timeout);
  const [isIdle, setIsIdle] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  let sessionPopup  = false
  // const [sessionPopup, setSessionPopup] = useState(false);
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const handleOnIdle = () => setIsIdle(true);
  let navigate = useNavigate();
  const { getRemainingTime } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });


  


  useEffect(() => {
    
    if (path?.pathname == "/" && currentUser?.employee_id !== undefined) {
      if (currentUser?.role === "safechief_admin") {
        navigate("/all-tenants");
      } else {
        navigate("/home");
      }
    }
  }, [path]);

  // useEffect(() => {
  //   setRemaining(getRemainingTime());
  //   setInterval(() => {
  //     setRemaining(getRemainingTime());
  //   }, 1000);
  // }, []);
  const dispatch = useDispatch();
    
    const authenticateUser = async () => {
      try {
        const user = await Auth?.currentAuthenticatedUser();
        const updatedUserData = {
                        ...currentUser,  // Assuming authenticateUser returns user data including the token
                        jwtToken: user?.signInUserSession?.idToken?.jwtToken,  // Set the new token
                      };   
        dispatch(login(updatedUserData));
      } catch (error) {
        console.error("Error authenticating user:", error);
      }
    };
  
    const sessionAlert = async () => {
     
      if (sessionPopup) return false; // Prevent multiple alerts

      sessionPopup = true
      return new Promise((resolve) => {
        let text = "Do you want to continue the session?";
        let confirmButtonText = "OK";
        let cancelButtonText = "Cancel";
        let showCancelButton = true;
        let title = "Session Expired";
        let type = "warning";
        let target = document.getElementById("swal-div");
  
        CustomAlert(
          title,
          text,
          type,
          confirmButtonText,
          showCancelButton,
          cancelButtonText,
          target
        )
          .then((result) => {
            if (result?.value) {
              authenticateUser();
              resolve(true);
              sessionPopup = false

            } else {
              resolve(false);
              sessionPopup = false
            }
          })
          .catch((error) => {
            console.error("Error showing custom alert:", error);
            sessionPopup = false
            resolve(false);
          });
      });
    };

  const  callMethodAtInterval =  async() => {
    const persistedData = localStorage.getItem("persist:counter");
    const parsedData = JSON.parse(persistedData);
    const user_data = JSON.parse(parsedData?.userReducer);

    if (user_data?.value?.userDetails?.jwtToken) {
      const isValid = await validateToken(user_data?.value?.userDetails?.jwtToken);
      if(!isValid){
        const userWantsToContinue = await sessionAlert();
      }
    } else {
      const highestIntervalId = setInterval(() => {}, 0); // Get the highest interval ID
      for (let i = 0; i <= highestIntervalId; i++) {
        clearInterval(i); // Clear all intervals up to the highest ID
        setIntervalId(null);
      }
      console.log("All intervals cleared.");
    }
  };

  useEffect(() => {
    let  interval_Id
    if(path?.pathname !="/"){
      if(intervalId == null){
        interval_Id = setInterval(callMethodAtInterval, 10000);
        setIntervalId(interval_Id);
      }
    }
    else{
      clearInterval(intervalId);
    }

  }, [path]); 


  const logoutUser = () => {
    cognitoSignOut()
      .then(() => {
        return (
          <Routes>
            <Route path="/admin-login" element={<SignIn />} />
          </Routes>
        );
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };

  const idleAlert = () => {
    setIsIdle(false);
    let title = "You Have Been Idle!";
    let text = "You Will Get Timed Out. You want to stay?";
    let type = "info";
    let confirmButtonText = "YES";
    let showCancelButton = true;
    let cancelButtonText = "NO";
    let target = document.getElementById("swal-div");
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      cancelButtonText,
      target
    ).then((result) => {
      if (result.value === true) {
        setRemaining(getRemainingTime());
      } else {
        logoutUser();
      }
    });
  };

  return (
    <>
      {path.pathname !== "/" &&
        path.pathname !== "/verifyuser" &&
        path.pathname !== "/verifyadmin" &&
        // path.pathname !== "/admin-login" &&
        path.pathname !== "/forgot-password" &&
        // path.pathname !== "/change-password" &&
        path.pathname !== "/preview" &&
        isIdle &&
        idleAlert()}
      <div className="h-md-100">
        {path.pathname !== "/" &&
          path.pathname !== "/verifyuser" &&
          path.pathname !== "/verifyadmin" &&
          path.pathname !== "/forgot-password" &&
          // path.pathname !== "/change-password" &&
          // path.pathname !== "/admin-login" &&
          path.pathname !== "/preview" && (
            <Header path={path.pathname === "/verifyadmin" ? true : false} />
          )}
        <div className="d-md-flex h-md-100 align-items-center">
          {path.pathname !== "/" &&
            path.pathname !== "/verifyuser" &&
            path.pathname !== "/verifyadmin" &&
            path.pathname !== "/forgot-password" &&
            // path.pathname !== "/change-password" &&
            // path.pathname !== "/admin-login" &&
            path.pathname !== "/preview" && (
              <div className="col-lg-2 col-md-2 col-sm-2 p-0">
                
                <SideMenu />
              </div>
            )}
          <div
            className={
              path.pathname === "/" ||
              path.pathname === "/verifyuser" ||
              // path.pathname !== "/verifyadmin" ||
              path.pathname === "/forgot-password"
                ? // path.pathname === "/change-password" ||
                  // path.pathname === "/admin-login"
                  "col-lg-12 col-md-12 col-sm-12 h-md-100 main-div p-0 sigin-div"
                : "col-lg-10 col-md-10 col-sm-10 h-md-100 main-div"
            }
          >
            <Routes>
              {
                // path.pathname === "/admin-login" ? (
                //   <Route path="/admin-login" element={<YTPAdminSignIn />} />
                // ) :
                path.pathname === "/" &&
                  currentUser?.employee_id === undefined && (
                    <>
                      <Route exact path="/" element={<SignIn />} />
                    </>
                  )
              }
              <Route path="/">
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/safechief-setup" element={<ProductSetUp />} />
                <Route path="/page-not-found" element={<PageNotFound />} />
                <Route
                  path="/verify-safechief-setup"
                  element={<VerifyProductSetUp />}
                />
                <Route path="/verifyuser" element={<UserVerification />} />
                <Route
                  element={
                    <RequireAuth
                      allowedUser={[...ADMIN_USER, ...SUPERADMIN_USER]}
                    />
                  }
                >
                  <Route
                    path="/viewAllApprovals"
                    element={<ViewAllApprovals />}
                  />
                  <Route
                    path="/approvalDetails"
                    element={<ApprovalDetails />}
                  />
                  <Route
                    path="/createstoragelocation"
                    element={<CreateStorageLocation />}
                  />
                </Route>
                <Route element={<RequireAuth allowedUser={SUPERADMIN_USER} />}>
                  <Route path="/admin" element={<ProductConfiguration />} />
                  <Route
                    path="/adminViewIRPTEBHistory"
                    element={<ViewIRPTEBHistory />}
                  />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedUser={[
                        ...WRITE_USER,
                        ...ADMIN_USER,
                        ...SUPERADMIN_USER,
                        ...AUDIT_USER,
                      ]}
                    />
                  }
                >
                  
                  <Route path="/singleCheckIn" element={<CreateCheckin />} />
                  <Route path="/bulkCheckIn" element={<BulkCheckIn />} />
                  <Route
                    path="/customBulkCheckin"
                    element={<CustomBulkCheckin />}
                  />
                  <Route path="/bulkShipment" element={<BulkShipment />} />
                  <Route path="/createShipment" element={<CreateShipment />} />
                  <Route path="/createAudit" element={<CreateAudit />} />
                  <Route path="/createEnquiry" element={<CreateEnquriy />} />
                  <Route
                    path="/storagelocation"
                    element={<ViewStorageLocation />}
                  />
                  <Route path="/createcheckout" element={<CreateCheckOut />} />
                  <Route
                    path="/uploadbagsfsi"
                    element={<CustomBagUploadFsi />}
                  />
                </Route>
                <Route element={<RequireAuth allowedUser={ALL_USER} />}>
                  <Route path="/home" element={<Home />} />
                  <Route path="/viewAllCheckIn" element={<ViewAllCheckIn />} />
                  <Route path="/forceOpen" element={<ForceOpenStepper />} />
                  <Route path="/checkinHistory" element={<CheckInHistory />} />
                  <Route path="/enquiryview" element={<ViewAllEnquiry />} />
                  <Route
                    path="/viewAllShipment"
                    element={<ViewAllShipContents />}
                  />
                  <Route path="/inbox" element={<Inbox />} />
                  <Route path="/reports" element={<ViewReports />} />
                  <Route
                    path="/generatemockdata"
                    element={<GenerateMockData />}
                  />
                  <Route path="/auditView" element={<ViewAllAudit />} />
                  <Route
                    path="/shipmentHistory"
                    element={<ShipmentHistory />}
                  />
                  <Route
                    path="/viewAllCheckout"
                    element={<ViewAllCheckout />}
                  />
                  <Route
                    path="/checkOutDetails"
                    element={<CheckOutDetails />}
                  />
                  <Route path="/enquiryHistory" element={<EnquiryHistory />} />
                  <Route path="/preview" element={<Preview />} />
                  <Route path="/auditHistory" element={<AuditHistory />} />
                  <Route path="/bulkCheckOut" element={<BulkCheckout />} />
                  <Route
                    path="/forceOpenDetails"
                    element={<ForceOpenDetails />}
                  />
                  <Route path="/viewAllPreOpen" element={<ViewAllPreOpen />} />
                  <Route path="/preOpen" element={<CreatePreOpen />} />
                  <Route path="/updatePreOpen" element={<EditPreOpen />} />
                  <Route
                    path="*"
                    element={<Navigate replace to="/page-not-found" />}
                  />
                </Route>
              </Route>
              <Route path="/verifyadmin" element={<AdminVerification />} />
              <Route element={<RequireAuth allowedUser={SAFECHIEF_ADMIN} />}>
                <Route path="/all-tenants" element={<YTPAdminHome />} />
                <Route path="/add-tenant" element={<YTPAdminAddTenant />} />
                <Route
                  path="/subscriptions"
                  element={<YTPAdminSubscription />}
                />
                <Route
                  path="/add-subscription"
                  element={<YTPAdminAddSubscription />}
                />
              </Route>
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navigation;
