

  const decodeToken = (token) => {
    try {
      const parts = token.split(".");
      if (parts.length === 3) {
        const decodedPayload = atob(parts[1]); // Decode the base64 payload
        const parsedPayload = JSON.parse(decodedPayload);
        return parsedPayload;
      } else {
        console.error("Invalid JWT token");
        return null;
      }
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  };

export const validateToken = async (token) => {
  try {
    const decodedToken = decodeToken(token);
    const currentTime = Date.now() / 1000; // Current time in seconds
    const expirationTime = decodedToken?.exp; // Expiration time in seconds

    if (!decodedToken || !expirationTime) {
      console.error("Invalid token format.");
      return false;
    }
  if (currentTime > expirationTime) {
        
      return false;
    
    }

    return true; // Token is valid
  } catch (error) {
    console.error("Error validating token:", error);
    return false;
  }
};

